import React from "react";
import { widgets } from "../../../../data/widgets";
import useInvite from "../../../../hooks/useInvite";
import classes from "./inviteTaskPreview.module.css";

const InviteTaskPreview = () => {
   const { shareToSocialMedia } = useInvite();

   return (
      <div className={classes.container}>
         <h2>Invite a friend to use the App</h2>
         <p>No one left behind</p>
         <div className={classes.widgetContainer}>
            <p>Share via :</p>
            <ul>
               {widgets.map((data) => (
                  <li
                     className={classes.google}
                     onClick={() => shareToSocialMedia(data.name.toUpperCase())}
                     key={data.id}
                  >
                     <img src={data.imgUrl} alt="" />
                  </li>
               ))}
            </ul>
         </div>
      </div>
   );
};

export default InviteTaskPreview;
