import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { iconImages } from "../../../../assets";
import { tasksAction } from "../../../../redux/tasksSlice";
import CountUp from "react-countup";
import {
   INVITE,
   INVITE_LOGIC,
   INVITE_QUESTION,
   PLAY_AREA,
} from "../../../../routes/Path";
import classes from "./invitethanks.module.css";

const inviteThanks = () => {
   const history = useHistory();
   const dispatch = useDispatch();

   const {
      invite: {
         inviteData: { count },
      },
   } = useSelector((state) => state);

   const backToApp = () => {
      dispatch(
         tasksAction.setInviteData({
            invitee_name: "",
            job_status: "",
            inviter_name: "",
         })
      );
      history.push(`/${PLAY_AREA}`);
   };

   const shareCount = count ? 1000 + count : 1000;

   const shareAgain = () => {
      dispatch(
         tasksAction.setInviteData({
            invitee_name: "",
            job_status: "",
            inviter_name: "",
         })
      );
      history.push(`/${INVITE}/${INVITE_LOGIC}/${INVITE_QUESTION}`);
   };
   return (
      <div style={{ width: "100vw" }}>
         <div className={classes.inappThanks}>
            <div className={classes.thanks}>
               <img src={iconImages.thankYou} alt="" />
            </div>
            <div className={classes.inappWatch}>
               <div>
                  <p>Share count</p>
                  <h3>
                     <CountUp end={shareCount} />+
                  </h3>
               </div>
               <div>
                  <p>Share again</p>
                  <button id="inapp-sharebtn" onClick={shareAgain}>
                     <i className="fas fa-share-alt"></i>
                  </button>
               </div>
            </div>
            <p>
               You’ve joined over 1000+ people who have shared Lara with their
               friends and families in new cities today.
            </p>
         </div>
         <div className={classes.inappGotoLara} onClick={backToApp}>
            <div>
               <i className="fas fa-angle-double-right"></i>
            </div>

            <p>Back to lara</p>
         </div>
      </div>
   );
};

export default inviteThanks;
