import { iconImages, socials, testDir } from "../../../assets";

export const helpGetImages = (name, city) => {
   switch (name) {
      case "feedback":
      case "add street data":
         return iconImages.sendFeedbackImg;
      case "download":
         return iconImages.emeraldCheckIconImg;

      case "invite friends":
         return iconImages.confirmImageBG;
      case "fun place near you":
         return iconImages.partyPropperImg;
      case "direction":
      case "alternative direction":
      case "try interstate direction":
      case "help lara test direction":
      case "filter direction":
      case "feedback on direction":
         return testDir.makeSense;
      case "Feedback on app":
         return iconImages.onboardingVerifiedIconImg;
      case "requests a feature":
         return iconImages.blueSendIcon;
      case "follow on social media":
         if (city === "Abuja") {
            return socials.followAbuja;
         }
         if (city === "Anambra") {
            return socials.followAnambra;
         }
         if (city === "Benin") {
            return socials.followBenin;
         }
         if (city === "Enugu") {
            return socials.followEnugu;
         }
         if (city === "Ibadan") {
            return socials.followIbadan;
         }
         if (city === "Ogun") {
            return socials.followOgun;
         }
         if (city === "PortHarcourt") {
            return socials.followPortHarcourt;
         }
         if (city === "Port Harcourt") {
            return socials.followPortHarcourt;
         }
         return testDir.makeSense;
      case "save trip":
         return iconImages.emeraldCheckIconImg;

      case "share announcement":
         return iconImages.shareLarabg;
      default:
         return testDir.makeSense;
   }
};
