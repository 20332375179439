import PropTypes from "prop-types";
import React from "react";
import classes from "./TermsandCondition.module.css";

const TermsandCondition = (props) => {
   const { text } = props;

   return (
      <p className={classes.terms}>
         {text}, you’re agreeing to our{" "}
         <a
            href={`/assets/End User Beta Testing Agreement.docx.pdf`}
            target="_blank"
            rel="noreferrer"
         >
            End-User Beta Tesing Agreement
         </a>{" "}
         ,{" "}
         <a
            href={`/assets/lara.ng cookie policy.pdf`}
            target="_blank"
            rel="noreferrer"
         >
            Terms
         </a>{" "}
         and{" "}
         <a
            href={`/assets/Lara.ng Privacy Policy 170521.docx.pdf`}
            target="_blank"
            rel="noreferrer"
         >
            Policy
         </a>
         . Thanks!
      </p>
   );
};

TermsandCondition.propTypes = {
   text: PropTypes.string,
};

export default TermsandCondition;
