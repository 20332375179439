import React from "react";
import { useSelector } from "react-redux";
import classes from "./DownloadLaraTask.module.css";
import useDownload from "../../../hooks/useDownload";
import useTask from "../../../hooks/useTask";

const DownloadLaraTask = () => {
   const {
      utilities: {
         device: { icon, downloadImg, name },
      },
   } = useSelector((state) => state);
   const { slideCallback } = useDownload();

   const promptEvent = window.deferredPrompt;
   const { callPrompt } = useDownload();
   const { submitTask } = useTask();

   const downloadLara = () => {
      callPrompt(promptEvent);
      submitTask();
   };

   return (
      <>
         <div className={classes.container}>
            <div className={classes.header}>
               <div className={classes.headerText}>
                  <h2>Lara at the tip of your finger</h2>
                  <p>
                     For easy access to all of your daily commmute needs &
                     tasks, download Lara now.
                  </p>
               </div>
               <div className={classes.phoneContainer}>
                  <img src={icon} />
                  <p>{name}</p>
               </div>
            </div>
            <button
               className={classes.btn}
               onClick={name === "IOS" ? slideCallback : downloadLara}
            >
               <img src={downloadImg} />
            </button>
         </div>
      </>
   );
};

export default DownloadLaraTask;
