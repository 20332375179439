export const ONBOARDING = "onboarding";
export const USER = "user";
export const LOGIN = "login";
export const REGISTER = "register";
export const HOWITWORKS = "howitworks";
export const ABOUT = "about";
export const INTERESTS = "interests";
export const ENTER_PASSWORD = "enter-password";
export const CITY = "city";
export const COMMUTE = "commute";
export const PREFERENCES = "preferences";
export const OCCUPATION = "occupation";
export const NEWCITY = "newcity";
export const HELP = "help";
export const END = "end";
export const WHERE = "where";
export const THANKS = "thanks";
export const FORGET_PASSWORD = "forget_password";
export const SOCIAL_LOGIN = "social";
export const DASHBOARD = "dashboard";
export const TASK = "task";
export const PLAY_AREA = "direction";
export const NOTIFICATION = "notification";
export const EDIT = "edit";
export const PROFILE = "profile";
export const LARAMAPS = "laramap";
export const REWARDS = "rewards";
export const REDEEMREWARD = "rewards/redeem_reward";
export const REWARDS_LIST = "rewards/reward_options";
export const POINTSREDEEMED = "rewards/points_redeemed";
export const USERINFO = "rewards/user_info";
export const USERCONTACT = "rewards/user_contact";
export const UPDATE_PASSWORD = "update_password";
export const CONTINUE_TO_EMAIL = "continue_to_email";
export const VERIFY_EMAIL = "email_verification";
export const HOW_IT_WORKS = "how_it_works";
export const DIRECTIONS_API = "directions";
export const INVITE = "invite";
export const INVITE_HOME = "home";
export const INVITE_LOGIC = "logic";
export const INVITE_THANKS = "thanks";
export const INVITE_QUESTION = "question";
export const INVITE_ANSWER = "answer";
export const FLAGDIRECTION = "fdirection";
