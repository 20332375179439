import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { iconImages } from "../../../assets";
import classes from "./IosDownloadFlow.module.css";
import PropTypes from "prop-types";

const IosDownloadFlow = ({ onCloseModal }) => {
   const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
   };

   return (
      <div className={classes.container}>
         <button onClick={() => onCloseModal(false)}>X</button>
         <Slider
            {...sliderSettings}
            className={classes.slider}
            dotsClass={`slick-dots ${classes.dots}`}
         >
            <div className={classes.slide}>
               <p>Open in Safari Browser</p>
               <img src={iconImages.safari} className={classes.safari} />
            </div>
            <div className={classes.slide}>
               <p>Click on Share Icon</p>
               <img src={iconImages.shareIcon} />
            </div>
            <div className={classes.slide}>
               <p>Scroll down & click</p>
               <img
                  src={iconImages.homeScreenIcon}
                  className={classes.homeScreen}
               />
            </div>
            <div className={classes.slide}>
               <p className={classes.done}>
                  Done!
                  <img
                     src={iconImages.emeraldCheckIconImg}
                     className={classes.check}
                  />
               </p>
               <img src={iconImages.laraLogo} className={classes.lara} />
               <p className={classes.last}>Open up Lara on iOS device.</p>
            </div>
         </Slider>
      </div>
   );
};

IosDownloadFlow.propTypes = {
   onCloseModal: PropTypes.func,
};

export default IosDownloadFlow;
