import React from "react";
import GiveTripName from "../../pages/dashboard/Task/GiveTripName/GiveTripName";
import SaveTrip from "../../pages/dashboard/Task/SaveTrip/SaveTrip";
import SaveTripsList from "../../pages/dashboard/Task/SaveTripsList/SaveTripsList";
import SelectTrip from "../../pages/dashboard/Task/SelectTrip/SelectTrip";
import TripSaved from "../../pages/dashboard/Task/TripSaved/TripSaved";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const SaveTripContainer = () => {
   const {
      saveTrip: {
         saveTripOptions: {
            showSaveTrip,
            showTripSelection,
            giveTripName,
            tripSaved,
            showSaveTripsList,
         },
      },
   } = useSelector((state) => state);
   const handleShow = () => {
      switch (true) {
         case showSaveTrip:
            return <SaveTrip />;
         case showTripSelection:
            return <SelectTrip />;
         case giveTripName:
            return <GiveTripName />;
         case tripSaved:
            return <TripSaved />;
         case showSaveTripsList:
            return <SaveTripsList />;

         default:
            return <SaveTrip />;
      }
   };
   return <div>{handleShow()}</div>;
};

SaveTripContainer.propTypes = {
   option: PropTypes.string,
};

export default SaveTripContainer;
