/**
 * This function is used to share the directions to social media.
 * @returns The function that returns the object that contains the functions that are being used.
 */
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socialMediaLinks } from "../data/widgets";
import { directionActions } from "../redux/directionsSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import useHttps from "./useHttps";
import useTask from "./useTask";

const shareLara = `Help them avoid any 'I just lost my way' adventures`;
const locationName = window.location.origin;

const useShare = () => {
   const {
      directions: { directionsData },
   } = useSelector((state) => state);
   const [clipboardText, setClipboardText] = useState("Copy to clipboard");
   const dispatch = useDispatch();
   const { submitTask } = useTask();

   const [url, setUrl] = useState(locationName);
   const [shareLaraText, setShareLaraText] = useState(shareLara);
   const { sendRequest } = useHttps();

   const shareToSocialMedia = useCallback(
      (identifier) => {
         const urlObject = {
            WHATSAPP: `whatsapp://send?text=${shareLaraText}: ${url}`,
            FACEBOOK: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
            TWITTER: `https://twitter.com/intent/tweet?text=${shareLaraText}&url=${url}`,
            GOOGLE: `mailto:?subject=Directions from Lara&body=${shareLaraText} ${url}`,
         };

         window.open(urlObject[identifier], "_blank");
         submitTask();
      },

      [shareLaraText, url]
   );

   const followOnSocialMedia = (id) => {
      const media = socialMediaLinks.find((data) => data.id === id);

      window.open(media.link, "_blank");
      submitTask();
   };

   const copyToClipBoard = useCallback(async () => {
      const text = `${url}`;
      submitTask();

      if (!navigator.clipboard) {
         return;
      }

      try {
         await navigator.clipboard.writeText(text);
         setClipboardText("Copied");
      } catch (err) {
         setClipboardText("Failed to copy");
      }
   }, [shareLaraText, url]);

   const loadingFunc = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   const successCallback = ({ data }) => {
      const tripRouteFrom = directionsData?.directions_from;
      const tripRouteTo = directionsData?.directions_to;

      const triprouteInUrl = `${tripRouteFrom?.split(" ")[0]}to${
         tripRouteTo?.split(" ")[0]
      }`.trim();

      const text = `Hi there :wave: A friend just shared directions from ${tripRouteFrom} to ${tripRouteTo} with you, to make sure you don’t miss your way.  See directions`;

      const shareSearchId = data.search_id;
      const shareId = data.share_id;

      const shareUrl =
         `${locationName}/direction?d=${triprouteInUrl}$search_id=${shareSearchId}$share_id=${shareId}`.trim();

      setUrl(shareUrl);
      setShareLaraText(text);
   };

   const getDirectionInformation = useCallback(() => {
      let searchId;
      if (directionsData.path) {
         searchId = directionsData?.path[0]?.search_id;
      }
      if (searchId) {
         const shareObject = {
            search_id: searchId,
            uid: "jsjdsYu1",
         };

         const config = {
            url: "trip/sharing",
            body: shareObject,

            method: "post",
         };

         sendRequest(config, successCallback, loadingFunc, null);
      }
   }, [directionsData]);

   const getShareIdFromParams = (params) => {
      const args = params?.split("$");
      let search_id = args[1]?.split("=")[1];
      let share_id = args[2]?.split("=")[1];

      if (share_id?.indexOf("&") > -1) {
         share_id = share_id?.split("&")[0];
      }
      getShareInformation(search_id, share_id);
   };

   const getShareInformation = (search_id, share_id) => {
      const config = {
         url: `trip/sharing?search_id=${search_id}&share_id=${share_id}`,
      };

      sendRequest(config, setShareDirectionToDirectionArea, loadingFunc, null);
   };

   const setShareDirectionToDirectionArea = ({ data }) => {
      const { search_from, search_to, direction } = data;
      const path = [];
      const createdData = {};
      createdData.directions_from = search_from;
      createdData.directions_to = search_to;
      const splittedDirection = direction.split(">>");
      splittedDirection.forEach((element) => {
         const parsedData = JSON.parse(element);
         parsedData.state = parsedData.result[0].state;
         path.push(parsedData);
      });
      createdData.path = path;
      createdData.share = "hello";
      dispatch(directionActions.setDirectionsData(createdData));
   };

   const closeShare = useCallback(() => {
      dispatch(directionActions.setShareDirection(false));
   }, []);

   return {
      getDirectionInformation,
      shareToSocialMedia,
      copyToClipBoard,
      clipboardText,
      closeShare,
      getShareIdFromParams,
      followOnSocialMedia,
   };
};

export default useShare;
