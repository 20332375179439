import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { iconImages } from "../../../../assets";
import { tasksAction } from "../../../../redux/tasksSlice";
import { userNotificationActions } from "../../../../redux/userNotificationSlice";
import { TASK, USER } from "../../../../routes/Path";
import classes from "../Notificationtab.module.css";

const Notificationaction = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const {
      notification: { backlogTasks, activeMissedTask, clearedNotifications },
   } = useSelector((state) => state);
   const closeNotfication = () => {
      dispatch(userNotificationActions.setShowNotification(false));
      dispatch(userNotificationActions.setShowRemindmeLater(false));
   };

   const goToTask = () => {
      dispatch(
         tasksAction.setSingleTaskInformation(backlogTasks[activeMissedTask])
      );
      history.push(`/${USER}/${TASK}`);
      closeNotfication();
   };

   const openReminder = () => {
      dispatch(userNotificationActions.setShowRemindmeLater(true));
   };

   const clearNotification = () => {
      const newSet = [...clearedNotifications];
      newSet.push(activeMissedTask);
      dispatch(userNotificationActions.setClearedNotifications(newSet));

      closeNotfication();
   };

   return (
      <ul className={classes.innerContainer}>
         <li onClick={goToTask}>
            <img src={iconImages.takeTaskNowImg} alt="" />
            <span>Take task now</span>
         </li>
         <li onClick={openReminder}>
            <img src={iconImages.remindMeLaterIconImg} alt="" />
            <span>Remind me of this task later</span>
         </li>
         <li onClick={clearNotification}>
            <img src={iconImages.clearNotificationImg} alt="" />
            <span>Clear this notification</span>
         </li>
      </ul>
   );
};

export default Notificationaction;
