import React from "react";
import { iconImages } from "../../../assets";
import useTask from "../../../hooks/useTask";
import DirectionsContainerWrapper from "../Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./AutoGeneratedFeedbackThanks.module.css";

const AutoGeneratedFeedbackThanks = () => {
   const { choseAppropriateTask } = useTask();

   return (
      <DirectionsContainerWrapper>
         <div className={classes.container}>
            <p>Thanks for the feedback </p>
            <h2>Awesome Job!</h2>
            <img src={iconImages.colouredRatingFive} alt="Happy" />
            <button onClick={() => choseAppropriateTask(1)}>
               Get Directions
            </button>
         </div>
      </DirectionsContainerWrapper>
   );
};

export default AutoGeneratedFeedbackThanks;
