import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { directionActions } from "../redux/directionsSlice";
import { feedbackActions } from "../redux/feedbackSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import useHttps from "./useHttps";
import useTask from "./useTask";

const useFeedback = () => {
   const {
      feedback: { feedbackInfo, loading },
      directions: { directionsData },
      utilities: { showFeedbackThanks },
   } = useSelector((state) => state);
   const { addToast } = useToasts();
   const checkIsDirectionsData =
      directionsData?.path && directionsData?.path.length > 0;

   const [toggleOtherFeedback, setToggleOtherFeedback] = useState(
      checkIsDirectionsData
   );

   const dispatch = useDispatch();

   const { submitTask } = useTask();

   const { sendRequest } = useHttps();
   const onFirstTrip = () => setToggleOtherFeedback(false);

   const onOtherFeedback = () => {
      dispatch(feedbackActions.clearFeedbackInfo());
      setToggleOtherFeedback(true);
   };

   const feedbackSuccess = () => {
      dispatch(utilitiesActions.setShowFeedbackThanks(true));
      dispatch(feedbackActions.clearFeedbackInfo());
      submitTask();
   };

   const loadingFunc = (value) => {
      dispatch(directionActions.setLoading(value));
   };

   const onFeedbackOnApp = (tag) => {
      const normalFeedbackBody = {
         tag: tag ?? "Feedback",
         comment: feedbackInfo.typed_text,
      };

      const config = {
         url: "feedback/save",
         method: "post",
         body: normalFeedbackBody,
      };

      sendRequest(config, feedbackSuccess, loadingFunc, null);
   };

   const onFeedbackSubmit = () => {
      const searchId = directionsData?.path
         ? directionsData?.path[0]?.search_id
         : 0;

      if (!searchId) {
         return addToast("You need to get directions first", {
            appearance: "error",
            autoDismiss: true,
         });
      }

      const ratingBody = {
         search_id: searchId,
         rating: feedbackInfo.id,
         feedback: feedbackInfo.typed_text
            ? feedbackInfo.typed_text
            : feedbackInfo.text,
      };

      const config = {
         url: "search/result/rating",
         method: "post",
         body: ratingBody,
      };

      sendRequest(config, feedbackSuccess, loadingFunc, null);
   };

   return {
      onFeedbackSubmit,
      onOtherFeedback,
      checkIsDirectionsData,
      loading,
      showFeedbackThanks,
      onFirstTrip,
      toggleOtherFeedback,
      onFeedbackOnApp,
   };
};

export default useFeedback;
