import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { utilitiesActions } from "../redux/utilitiesSlice";
import useTask from "./useTask";

const useDownload = () => {
   const dispatch = useDispatch();

   const { submitTask } = useTask();

   const slideCallback = () => {
      dispatch(utilitiesActions.setDownloadDisplay(true));
   };

   const downloadLara = useCallback(() => {
      const promptEvent = window.deferredPrompt;
      submitTask();
      if (!promptEvent) {
         return;
      }
   }, []);

   const callPrompt = (promptEvent) => {
      promptEvent.prompt();
      promptEvent.userChoice.then((result) => {
         if (result.outcome === "accepted") {
            window.deferredPrompt = null;
         }
      });
   };
   return {
      slideCallback,
      downloadLara,
      callPrompt,
   };
};

export default useDownload;
