import React from "react";
import { fullSocialMediaLinks } from "../../../data/widgets";
import useShare from "../../../hooks/useShare";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./FollowOnSocialsTask.module.css";

export const FollowOnSocialsTask = () => {
   const { followOnSocialMedia } = useShare();
   return (
      <>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <h2>Follow who Know road.</h2>
               <p>
                  Maintain your points streak by following Lara on all social
                  media channels.
               </p>

               <ul className={classes.socialMediaContainer}>
                  {fullSocialMediaLinks.map((data) => (
                     <li
                        key={data.id}
                        onClick={() => followOnSocialMedia(data.id)}
                     >
                        <img src={data.imgUrl} alt="" />
                        <span>{data.name}</span>
                     </li>
                  ))}
               </ul>
            </div>
         </DirectionsContainerWrapper>

         <GenericButton ButtonText="Skip to Directions" />
      </>
   );
};
