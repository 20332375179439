import abujaanimation from "./Abujavideoanimation.mp4";
import Anambravideoanimation from "./Anambravideoanimation.mp4";
import beninvideoanimation from "./beninvideoanimation.mp4";
import enuguvideoanimation from "./enuguvideoanimation.mp4";
import ibadanvideoanimation from "./ibadanvideoanimation.mp4";
import ogunvideoanimation from "./ogunvideoanimation.mp4";
import PortHarcourtvideoanimation from "./Port Harcourtvideoanimation.mp4";
import bacgroundAnimation from "../bacgroundAnimation.mp4";

export const onboardingVideoAnimation = {
   abujaanimation,
   Anambravideoanimation,
   beninvideoanimation,
   enuguvideoanimation,
   ibadanvideoanimation,
   ogunvideoanimation,
   PortHarcourtvideoanimation,
   bacgroundAnimation,
};
