import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { iconImages } from "../../../../../assets";
import useOnboardingVerification from "../../../../../hooks/useOnboardingVerification";
import { DASHBOARD, USER } from "../../../../../routes/Path";
import Container from "../../../../components/Wrappers/container/Container";
import classes from "./VerifyEmail.module.css";

const VerifyEmail = () => {
   const { verificationStatus, checkVerificationStatus } =
      useOnboardingVerification();

   useEffect(() => {
      const idenifier = setTimeout(() => checkVerificationStatus(), 1);

      return () => {
         clearTimeout(idenifier);
      };
   }, []);

   return (
      <Container className={classes.container}>
         <>
            <div className={classes.header}></div>
            <img src={iconImages.emailVerificationLogo} alt="" />
            {verificationStatus ? (
               <>
                  <h4>Your e-mail address has been verified</h4>
                  <div className={classes.plan}>
                     <p>Thank you and welcome on-board.</p>
                     <p>Now you can continue using Lara to;</p>
                  </div>
               </>
            ) : (
               <>
                  <h4>Invalid link provided</h4>
                  <div className={classes.plan}>
                     <p>Kindly check your mail</p>
                  </div>
               </>
            )}
            <div className={classes.commute}>
               <h4>Plan. Commute. Connect</h4>
               <p>better on your trips</p>
            </div>
            {verificationStatus ? (
               <Link className={classes.continue} to={`/${USER}/${DASHBOARD}`}>
                  CONTINUE
               </Link>
            ) : (
               <Link className={classes.continue} to={`/`}>
                  GO TO SIGN UP
               </Link>
            )}
         </>
      </Container>
   );
};

export default VerifyEmail;
