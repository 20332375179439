/**
 * This function renders the DirectionCard component and the LaraWidgets component.
 * @returns A container that contains a direction card and a widget.
 */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import DirectionCard from "../DirectionCard/DirectionCard";
import LaraWidgets from "../LaraWidgets/LaraWidgets";
import Container from "../Wrappers/container/Container";
import classes from "./DirectionCardContainer.module.css";

const DirectionCardContainer = () => {
   const {
      directions: { directionsData },
      utilities: { isLoggedIn },
   } = useSelector((state) => state);
   const containerRef = useRef();

   return (
      <Container className={classes.container}>
         <>
            <div className={classes.house} ref={containerRef}>
               <div className={classes.directionContainer}>
                  <DirectionCard data={directionsData} />
               </div>
            </div>

            {isLoggedIn && <LaraWidgets />}
         </>
      </Container>
   );
};

export default DirectionCardContainer;
