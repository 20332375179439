import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { iconImages } from "../../../../../assets";
import useInput from "../../../../../hooks/useInput";
import useOnboardingInformation from "../../../../../hooks/useOnboardingInformation";
import { tasksAction } from "../../../../../redux/tasksSlice";
import {
   INVITE,
   INVITE_ANSWER,
   INVITE_LOGIC,
} from "../../../../../routes/Path";
import validators from "../../../../../Utilities/inputValidator";
import classes from "./invitequestion.module.css";
import gsap from "gsap";

const Invitequestion = () => {
   const {
      tasks: {
         invite: { invitee_name },
      },
      utilities: { isNormalLoading },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const history = useHistory();
   const [showOther, setShowOther] = useState(false);
   const radioDivEl = useRef();

   const radioOnchange = (e) => {
      dispatch(tasksAction.setInviteData({ job_status: e.target.value }));
      history.push(`/${INVITE}/${INVITE_LOGIC}/${INVITE_ANSWER}`);
   };

   const { data, getJobStatus } = useOnboardingInformation();

   useEffect(() => {
      getJobStatus(true);
   }, []);

   const {
      value: invitee_name_value,
      valueInputChange: inviterValueInput,
      valueIsValid: inviterNameIsValid,
   } = useInput(validators.checkValueIsEmpty, invitee_name);

   const onSetShowOther = (value) => setShowOther(value);

   const {
      value: otherOccupation,
      valueInputChange: iotherOccupationValueInput,
      valueIsValid: otherOccupationIsValid,
   } = useInput(validators.checkValueIsEmpty, "");

   const setOtherJobInformation = () => {
      dispatch(tasksAction.setInviteData({ job_status: otherOccupation }));
      history.push(`/${INVITE}/${INVITE_LOGIC}/${INVITE_ANSWER}`);
   };

   const setInviteName = () => {
      animateRadioDiv();
      dispatch(tasksAction.setInviteData({ invitee_name: invitee_name_value }));
   };

   const animateRadioDiv = () => {
      gsap
         .timeline()
         .fromTo(
            radioDivEl.current,
            0.5,
            { y: 30, opacity: 0.4 },
            { y: 0, opacity: 1 }
         );
   };

   return (
      <div className={classes.inappInvitation}>
         <div className={classes.inappMessages}>
            <label htmlFor=""> What’s your friend’s name? </label>
            <input
               type="text"
               onChange={inviterValueInput}
               value={invitee_name_value}
               placeholder="What’s your friend’s name?"
            />
            {inviterNameIsValid && (
               <button onClick={setInviteName}>
                  <img src={iconImages.blueSendIcon} alt="" />
               </button>
            )}
         </div>
         <div
            className={`${classes.radio} ${invitee_name ? classes.active : ""}`}
            ref={radioDivEl}
         >
            <p>{invitee_name ?? "Your friend"} is a: </p>
            <div onChange={radioOnchange}>
               {isNormalLoading ? (
                  "Please wait..."
               ) : (
                  <>
                     {data.length > 0 &&
                        data
                           .filter(
                              (job_status) =>
                                 job_status.name.toUpperCase() !== "OTHERS"
                           )
                           .map((job) => (
                              <div key={job.id}>
                                 <input
                                    type="radio"
                                    name="invitees"
                                    value={job.name}
                                 />
                                 <label htmlFor="">{job.name}</label>
                              </div>
                           ))}
                     <button onClick={() => onSetShowOther(true)}>Other</button>
                  </>
               )}
            </div>
         </div>

         {showOther && (
            <div className={classes.otherOccupation}>
               <button
                  className={classes.cancel}
                  onClick={() => onSetShowOther(false)}
               >
                  <img src={iconImages.blackCloseIcon} alt="" />
               </button>
               <input
                  type="text"
                  value={otherOccupation}
                  onChange={iotherOccupationValueInput}
               />
               {otherOccupationIsValid && (
                  <button
                     className={classes.send}
                     onClick={setOtherJobInformation}
                  >
                     <img src={iconImages.blueSendIcon} alt="" />
                  </button>
               )}
            </div>
         )}
      </div>
   );
};

export default Invitequestion;
