import { useState } from "react";

/**
 * It returns a set of values that are used to control the state of the input.
 * @param undefined - The validate parameter is optional. If it is not provided, the input will not
be validated.
 * @param undefined - The validate function.
 * @returns The value of the input, the error state, 
 */
const useInput = (validate = null, initialValue = "") => {
   const [value, setValue] = useState(initialValue);
   const [isTouched, setIsTouched] = useState(false);
   const [isChecked, setIsChecked] = useState(false);
   const [showPassword, setShowPassword] = useState(false);

   const valueIsValid = validate && validate(value);
   const hasError = !valueIsValid && isTouched;

   const valueInputChange = (event) => {
      setValue(event.target.value);
   };

   const inputBlurHandler = () => {
      setIsTouched(true);
   };

   const setIsCheckedHandler = (event) => {
      setIsChecked(event.target.checked);
   };

   const onSetShowPassword = () => setShowPassword((prevData) => !prevData);

   const clearInputValue = () => setValue("");

   return {
      value,
      hasError,
      valueInputChange,
      valueIsValid,
      inputBlurHandler,
      isChecked,
      setIsCheckedHandler,
      clearInputValue,
      onSetShowPassword,
      showPassword,
   };
};
export default useInput;
