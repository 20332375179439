import nyscVideoAnimation from "./nyscvideoanimation.mp4";
import recentgraduateanimation from "./recentgraduateanimation.mp4";
import studentvideoanimation from "./studentvideoanimation.mp4";
import unlockedanimation from "./unlockedanimation.mp4";
import workingproffessionalannimation from "./workingproffessionalannimation.mp4";
import otherCategory from "../../images/Other Category-small.mp4";

export const onboardingVideoAssets = {
   nyscVideoAnimation,
   recentgraduateanimation,
   studentvideoanimation,
   unlockedanimation,
   workingproffessionalannimation,
   otherCategory,
};
