import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   autoGeneratedFeedback: {},
   autoGeneratedLgas: [],
   selectedLgas: [],
   autoGeneratedModePreCheck: {},
   autoGeneratedDirectionInformation: {},
   autoGeneratedFeedbackDirectionData: {},
   badDirection: [],
   isAutoGeneratedFeedback: {
      showThanks: false,
      showFeedbackTextArea: false,
      showFeedbackMode: false,
   },
};

const autoGeneratedFeedbackSlice = createSlice({
   name: "autoGeneratedFeedback",
   initialState,
   reducers: {
      setAutoGeneratedFeedback(state, action) {
         state.autoGeneratedFeedback = action.payload;
      },
      setAutoGeneratedLgas(state, action) {
         state.autoGeneratedLgas = [...action.payload];
      },
      setSelectedLgas(state, action) {
         state.selectedLgas = [...action.payload];
      },
      setAutogeneratedModePrecheck(state, action) {
         state.autoGeneratedModePreCheck = action.payload;
      },
      setAutogeneratedFeedbackInformation(state, action) {
         state.autoGeneratedDirectionInformation = action.payload;
      },
      setAutoGeneratedFeedbacData(state, action) {
         state.autoGeneratedFeedbackDirectionData = action.payload;
      },
      setShowThanks(state, action) {
         state.showThanks = action.payload;
      },
      setBadDirection(state, action) {
         state.badDirection = action.payload;
      },
      setShowFeedbackArea(state, action) {
         state.showFeedbackTextArea = action.payload;
      },
      setShowFeedbackMode(state, action) {
         state.showFeedbackMode = action.payload;
      },

      setIsAutogeneratedFeedback(state, action) {
         for (let key in state.isAutoGeneratedFeedback) {
            state.isAutoGeneratedFeedback[key] = false;
         }
         state.isAutoGeneratedFeedback[action.payload] = true;
      },
      clearFeedbackInfo() {
         return initialState;
      },
   },
});

export const autoGeneratedFeedbackActions = autoGeneratedFeedbackSlice.actions;
export const autoGeneratedFeedbackReducer = autoGeneratedFeedbackSlice.reducer;

export default autoGeneratedFeedbackSlice;
