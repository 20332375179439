import React from "react";
import PropTypes from "prop-types";
import styles from "./PopUpTerms.module.css";
import useDownload from "../../../hooks/useDownload";
import useTask from "../../../hooks/useTask";

const PopUpDownload = (props) => {
   const { closeFunction } = props;
   const promptEvent = window.deferredPrompt;
   const { callPrompt } = useDownload();
   const { submitTask } = useTask();

   const onButtonClick = () => {
      callPrompt(promptEvent);
      closeFunction(false);
      submitTask();
   };

   return (
      <div className={styles.popupContainer}>
         <button onClick={onButtonClick}>Click to Download</button>
      </div>
   );
};

PopUpDownload.propTypes = {
   closeFunction: PropTypes.func.isRequired,
};
export default PopUpDownload;
