import React from "react";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import InviteTaskPreview from "../taskpreviews/invite/inviteTaskPreview";

const InviteTask = () => {
   return (
      <>
         <DirectionsContainerWrapper>
            <InviteTaskPreview />
         </DirectionsContainerWrapper>
         <GenericButton ButtonText="Skip to direction" />
      </>
   );
};

export default InviteTask;
