import React from 'react';
import classes from './ContinueWith.module.css';
import PropTypes from 'prop-types';

const ContinueWith = (props) => {
	return (
		<div className={`${classes.continue} ${props.className}`}>
			<div></div>
			<p>or continue with</p>
			<div></div>
		</div>
	);
};

ContinueWith.propTypes = {
	className: PropTypes.string,
};

export default ContinueWith;
