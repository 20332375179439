import { motion } from "framer-motion";
import React from "react";
import { iconImages } from "../../../../assets";
import ModalContainer from "../../../components/Wrappers/ModalContainer/ModalContainer";
import { modalVariant } from "../../../components/Wrappers/ModalContainer/modalContainerVariants";
import classes from "./UpdateServiceWorkerNotification.module.css";

const UpdateServiceWorkerNotification = () => {
   const downloadUpdate = () => window.location.reload();

   const cancelUpdate = () => {
      const updateDiv = document.querySelector("update");
      if (updateDiv) {
         updateDiv.innerHTML = "";
      }
   };

   return (
      <ModalContainer>
         <motion.div variants={modalVariant} className={classes.container}>
            <div className={classes.modalHeader}>
               <div>
                  <p className={classes.modalText}>Hi {"there"}!</p>
               </div>

               <img
                  className={classes.checkImg}
                  src={iconImages.notificationIconImg}
                  alt=""
               />
            </div>
            <div className={classes.modalBody}>
               <p>An Update is available, would you like to download </p>

               <div className={classes.buttonContainer}>
                  <button onClick={cancelUpdate}>Later</button>
                  <button
                     onClick={() => downloadUpdate()}
                     className={classes.action}
                  >
                     Update
                  </button>
               </div>
            </div>
         </motion.div>
      </ModalContainer>
   );
};

export default UpdateServiceWorkerNotification;
