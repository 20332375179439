import React from 'react';
import PropTypes from 'prop-types';

const OnboardingWrapperContainer = (props) => {
	return <div className='onboarding-container'>{props.children}</div>;
};

OnboardingWrapperContainer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.element,
};

export default OnboardingWrapperContainer;
