/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState } from "react";
import PropTypes from "prop-types";
import SearchInputFullScreen from "./SearchInputFullScreen/SearchInputFullScreen";

const SearchInput = (props) => {
   const {
      className,
      placeholder,
      onSubmitFunction,
      propValue,
      type,
      onFocus,
   } = props;
   const [showDropdownContainer, setShowDropdownContainer] = useState(false);
   const onFocusHandler = () => {
      onFocus && onFocus();
      setShowDropdownContainer(true);
   };

   return (
      <>
         {!showDropdownContainer ? (
            <input
               type="text"
               placeholder={placeholder}
               className={`${className}`}
               onFocus={onFocusHandler}
               defaultValue={propValue ? propValue : ""}
            />
         ) : (
            <SearchInputFullScreen
               showDropdownContainer={showDropdownContainer}
               setShowDropdownContainer={setShowDropdownContainer}
               onSubmitFunction={onSubmitFunction}
               type={type}
            />
         )}
      </>
   );
};

SearchInput.propTypes = {
   onSubmitFunction: PropTypes.func.isRequired,
   className: PropTypes.string,
   placeholder: PropTypes.string,
   propValue: PropTypes.string,
   type: PropTypes.string,
   onFocus: PropTypes.func,
};

export default SearchInput;
