import PropTypes from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import useTask from "../../../hooks/useTask";
import { directionActions } from "../../../redux/directionsSlice";
import classes from "./Preferences.module.css";

const PoiData = ({ text, poiData }) => {
   const dispatch = useDispatch();

   const { onMaximize } = useTask();

   const getRouteFromPoi = (poi) => {
      const { lat, lng, name } = poi;
      dispatch(
         directionActions.updateRouteInformation({
            toText: name,
            toLat: lat,
            toLong: lng,
         })
      );

      onMaximize();
   };

   return (
      <>
         {poiData?.length > 0 && (
            <>
               <p>
                  {text ??
                     "Here are some suggestions, based off your interests"}{" "}
               </p>
               <div className={classes.preferenceContainer}>
                  <ul>
                     {poiData.map((data, index) => (
                        <li
                           key={index}
                           className={`${classes.preference} ${classes.li}`}
                           onClick={() => getRouteFromPoi(data)}
                        >
                           {data.name}
                        </li>
                     ))}
                  </ul>
               </div>{" "}
            </>
         )}
      </>
   );
};

PoiData.propTypes = {
   className: PropTypes.string,
   children: PropTypes.element,
   text: PropTypes.string,
   poiData: PropTypes.array,
};

export default PoiData;
