import React from "react";
import classes from "./LaraMessageContainer.module.css";
import PropTypes from "prop-types";

const LaraMessageContainer = ({ className, children }) => {
   return <div className={`${classes.container} ${className}`}>{children}</div>;
};

LaraMessageContainer.propTypes = {
   children: PropTypes.element,
   className: PropTypes.string,
};

export default LaraMessageContainer;
