import React, { memo } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	iconImages,
	levelBadgesImages,
	plateBadgesImages,
} from '../../../../assets';
import { utilitiesActions } from '../../../../redux/utilitiesSlice';
import { REWARDS } from '../../../../routes/Path';
import classes from './NewbadgeNotification.module.css';

const NewbadgeNotification = () => {
	const {
		utilities: {
			levelUpdate: { level, state },
		},
	} = useSelector((state) => state);

	const badgeImage = plateBadgesImages.find(
		(data) => data.state.toUpperCase() === state.toUpperCase()
	)[`level${level}`];

	const dispatch = useDispatch();
	const history = useHistory();

	const levelImage = levelBadgesImages[`level${level}BadgeImg`];

	const claimReward = useCallback(() => {
		dispatch(utilitiesActions.setShowModal(false));
		dispatch(
			utilitiesActions.setLevelUpdate({
				level,
				state,
				islevelChanged: false,
			})
		);
		history.push(`/${REWARDS}`);
	}, [dispatch, history]);

	return (
		<>
			<div className={classes.modalHeader}>
				<p className={classes.modalText}>
					Congrats! New Badge Unlocked
				</p>
				<img
					className={classes.checkImg}
					src={iconImages.partyPropperImg}
					alt=''
				/>
			</div>
			<div className={classes.modalBody}>
				<p>Level {level}</p>
				<div className={classes.badgeContainer}>
					<img src={badgeImage} alt='' />
					<img src={levelImage} alt='' />
				</div>
				<p>30XP GAINED</p>
				<button onClick={claimReward} className={classes.claimReward}>
					CLAIM YOUR REWARD
				</button>
			</div>
		</>
	);
};

export default memo(NewbadgeNotification);
