import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import { directionActions } from "../redux/directionsSlice";
import { feedbackActions } from "../redux/feedbackSlice";
import { userSliceActions } from "../redux/userSlice";
import { PLAY_AREA } from "../routes/Path";
import {
   ADD_WORK,
   FROM_DIRECTION,
   MISSING_POI,
   TO_DIRECTION,
   ADD_HOME,
} from "../Utilities/mapTags";
import useHttps from "./useHttps";
import useLoader from "./useLoader";
import useTask from "./useTask";

const useMaps = () => {
   const {
      directions: {
         mapInformation: { text, fromLat, fromLong },
         currentLocationOnMap,
         routeInformation: { toText },
         mapTag,
      },
      feedback: { missingPoiFeedback, showMissingFeedbackForm },
   } = useSelector((state) => state);
   const { addToast } = useToasts();
   const [inputValue, setInputValude] = useState(
      text ?? missingPoiFeedback.name
   );

   const { submitTask } = useTask();

   const history = useHistory();
   const { normalLoadingCallback } = useLoader();

   const [mapPoints, setMapPoints] = useState([]);

   const dispatch = useDispatch();
   const { sendRequest } = useHttps();

   const [isEdit, setIsEdit] = useState(false);
   const successCallback = (data) => {
      setMapPoints(data?.data?.locations);
   };

   const submitMissingPoiCallback = () => {
      addToast("Your response has been taken", {
         appearance: "success",
         autoDismiss: true,
      });
      history.push(`/${PLAY_AREA}`);
      submitTask();
   };

   const onInputChange = (event) => {
      setInputValude(event.target.value);
   };

   const getPinPoint = useCallback(
      (marker) => {
         const object = {
            lat: marker.latLng.lat(),
            lng: marker.latLng.lng(),
         };

         getMapPoints(object.lat, object.lng);

         dispatch(feedbackActions.setMissingPoiFeedback(object));
      },
      [dispatch]
   );

   const onSetIsEdit = () => {
      setIsEdit((state) => !state);
   };

   const submitMissingPoiFeedback = () => {
      const body = { ...missingPoiFeedback, lga: missingPoiFeedback.lga.lga };

      const config = {
         url: `save/user/suggested/location`,
         method: "post",
         body,
      };
      sendRequest(
         config,
         submitMissingPoiCallback,
         normalLoadingCallback,
         null
      );
   };
   const getMapPoints = (lat, lng) => {
      const config = {
         url: `locations/closest?lat=${lat}&lng=${lng}`,
         method: `get`,
      };

      sendRequest(config, successCallback, normalLoadingCallback, null, null);
   };

   const backToMissingPoiForm = () => {
      history.push(`/${PLAY_AREA}`);
   };

   const addDestinationInformationToData = () => {
      dispatch(
         directionActions.updateRouteInformation({
            toText: inputValue,
            toLat: fromLat,
            toLong: fromLong,
         })
      );
      history.push(`/${PLAY_AREA}`);
   };

   const addLocationInformationToData = () => {
      dispatch(
         directionActions.updateRouteInformation({
            fromText: inputValue,
            fromLat: fromLat,
            fromLong: fromLong,
         })
      );

      history.push(`/${PLAY_AREA}`);
   };

   const addHomeInformationToUserProfile = () => {
      dispatch(
         userSliceActions.setEditedHomeInfo({
            stop_name: inputValue,
            lat: fromLat,
            lng: fromLong,
         })
      );
      history.goBack();
   };

   const addWorkInformationToUserProfile = () => {
      dispatch(
         userSliceActions.setEditedWorkInfo({
            stop_name: inputValue,
            lat: fromLat,
            lng: fromLong,
         })
      );
      history.goBack();
   };

   const selectMapTag = () => {
      switch (mapTag) {
         case ADD_HOME:
            return addHomeInformationToUserProfile();

         case ADD_WORK:
            return addWorkInformationToUserProfile();

         case FROM_DIRECTION:
            return addLocationInformationToData();

         case TO_DIRECTION:
            return addDestinationInformationToData();

         case MISSING_POI:
            return submitMissingPoiCallback();
         default:
            return addDestinationInformationToData();
      }
   };

   const addInformationToData = () => {
      if (!toText) {
         dispatch(
            directionActions.updateRouteInformation({
               toText: inputValue,
               toLat: fromLat,
               toLong: fromLong,
            })
         );
      } else {
         dispatch(
            directionActions.updateRouteInformation({
               fromText: inputValue,
               fromLat: fromLat,
               fromLong: fromLong,
            })
         );
      }

      history.push(`/${PLAY_AREA}`);
   };

   const setMapTag = (tag) => {
      dispatch(directionActions.setMapTags(tag));
   };

   return {
      currentLocationOnMap,
      inputValue,
      isEdit,
      onInputChange,
      getPinPoint,
      onSetIsEdit,
      getMapPoints,
      text,
      mapPoints,
      submitMissingPoiFeedback,
      missingPoiFeedback,
      showMissingFeedbackForm,
      backToMissingPoiForm,
      addInformationToData,
      selectMapTag,
      setMapTag,
   };
};

export default useMaps;
