import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { iconImages } from "../../../../assets";
import useHttps from "../../../../hooks/useHttps";
import useLocation from "../../../../hooks/useLocation";
import { directionActions } from "../../../../redux/directionsSlice";
import { LARAMAPS, USER } from "../../../../routes/Path";
import classes from "./SearchInputFullScreen.module.css";

const SearchInputFullScreen = (props) => {
   const { showDropdownContainer, onSubmitFunction, setShowDropdownContainer } =
      props;
   const searchRef = createRef();
   const history = useHistory();
   const [dropdownData, setDropdownData] = useState([]);
   const [value, setValue] = useState("");
   const dispatch = useDispatch();
   const { locator } = useLocation();

   const onChangeHandler = (event) => setValue(event.target.value);

   const onCancelHandler = () => setValue("");
   const onBackHandler = () => setShowDropdownContainer(false);
   const checkDropdownDataLength = dropdownData.length > 0;
   const { sendRequest } = useHttps();
   const isValid = value !== "" && value.length > 2;

   useEffect(() => {
      const identifier = setTimeout(() => searchFromServer(), 200);

      showDropdownContainer && searchRef.current.focus();

      return () => {
         clearTimeout(identifier);
      };
   }, [value, showDropdownContainer]);

   const config = {
      url: `search/route/similar?uid=aytgday6&search=${value.trim()}`,
      method: "get",
      isSearch: true,
   };

   const getDataFromDropdown = (data) => {
      const { data: dropdowndata } = data;
      dropdowndata.length && setDropdownData(dropdowndata);
   };

   const searchFromServer = () => {
      isValid
         ? sendRequest(config, getDataFromDropdown, null, null)
         : setDropdownData([]);
   };

   const showMap = () => {
      dispatch(directionActions.setMapInformation({ text: value, type: "" }));
      const successCallback = (data) => {
         dispatch(directionActions.setMapInformation(data));
         history.push(`/${USER}/${LARAMAPS}`);
      };
      locator(successCallback, null, "SEARCH");
   };

   const selectFromDropDown = (params) => {
      const selectedList = dropdownData.find(
         (data) => data.stop_name.trim() === params.trim()
      );
      onSubmitFunction(selectedList);
      setShowDropdownContainer(false);
   };

   const dropDownElement = dropdownData.map((data) => (
      <li key={data.lat} onClick={() => selectFromDropDown(data.stop_name)}>
         <p>{`${data.full_address} ${data.stop_name} ${data.city}`}</p>{" "}
         <img src={iconImages.locationSuggestionIconImg} alt="" />
      </li>
   ));

   return (
      <AnimatePresence exitBeforeEnter>
         {showDropdownContainer && (
            <motion.div className={classes.container}>
               <div className={classes.header}>
                  <input
                     type="text"
                     className={classes.headerText}
                     onChange={onChangeHandler}
                     value={value}
                     ref={searchRef}
                  />
                  <button className={classes.back} onClick={onBackHandler}>
                     <img src={iconImages.whiteBackArrowImg} alt="" />
                  </button>
                  <button className={classes.cancel} onClick={onCancelHandler}>
                     <img
                        src={iconImages.clearInputLineImg}
                        height="30px"
                        alt=""
                     />
                  </button>
               </div>

               <div className={classes.dropdown}>
                  {checkDropdownDataLength ? (
                     <>
                        {dropDownElement}
                        <br />
                        {isValid && (
                           <>
                              <p>-Or</p>
                              <button
                                 onClick={showMap}
                                 className={classes.setLocationOnMap}
                              >
                                 <img
                                    src={iconImages.setLocationOnMapImg}
                                    alt=""
                                 />
                                 <p>Set location on map</p>
                              </button>
                           </>
                        )}
                     </>
                  ) : (
                     <div className={classes.nodropdown}>
                        <div className={classes.info}>
                           <p>Try using bus stops or popular landmarks</p>
                        </div>
                        <div className={classes.firstInfo}>
                           <img
                              src={iconImages.locationSuggestionIconImg}
                              alt=""
                           />
                           <p>i.e. Jones street, Area 2 bus stop, Ring road</p>
                        </div>
                        {isValid && (
                           <>
                              <p>-Or</p>
                              <button
                                 onClick={showMap}
                                 className={classes.setLocationOnMap}
                              >
                                 <img
                                    src={iconImages.setLocationOnMapImg}
                                    alt=""
                                 />
                                 <p>Set location on map</p>
                              </button>
                           </>
                        )}
                     </div>
                  )}
               </div>
            </motion.div>
         )}
      </AnimatePresence>
   );
};

SearchInputFullScreen.propTypes = {
   showDropdownContainer: PropTypes.bool.isRequired,
   onSubmitFunction: PropTypes.func.isRequired,
   setShowDropdownContainer: PropTypes.func.isRequired,
   type: PropTypes.string,
};

export default SearchInputFullScreen;
