/**
 * Cannot generate summary
 * @returns A function that takes in user data and returns a function that takes in a callback
function.
 */
import { useSelector } from "react-redux";
import useAuth from "./useAuth";
import useHttps from "./useHttps";

const useSocialSignUp = () => {
   const { loginSuccess, LoadingStateFunction } = useAuth();
   const { sendRequest } = useHttps();
   const {
      onboarding: { userData },
   } = useSelector((state) => state);

   const getSocialLoginData = (userdata) => {
      const body = { ...userdata };

      userData.referral_token &&
         (body.referral_token = userData.referral_token);

      const config = {
         url: `auth/social/login`,
         body,
         method: "post",
      };

      sendRequest(config, loginSuccess, LoadingStateFunction, null);
   };
   return getSocialLoginData;
};

export default useSocialSignUp;
