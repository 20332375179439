import React from "react";
import Container from "../Wrappers/container/Container";
import classes from "./DirectionLoader.module.css";

const DirectionLoader = () => {
   return (
      <Container className={classes.container}>
         <lottie-player
            src="https://assets6.lottiefiles.com/packages/lf20_redhiqdp.json"
            background="transparent"
            speed="2"
            style={{ width: "300px", height: "220px" }}
            loop
            autoplay
         ></lottie-player>
      </Container>
   );
};

export default DirectionLoader;
