import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { inviteSliceActions } from "../redux/inviteSlice";
import { INVITE, INVITE_QUESTION, THANKS } from "../routes/Path";
import useHttps from "./useHttps";
import useLoader from "./useLoader";
import useTask from "./useTask";

const useInvite = () => {
   const { normalLoadingCallback } = useLoader();
   const { sendRequest } = useHttps();
   const dispatch = useDispatch();
   const [inviteData, setInviteData] = useState({
      display_text:
         "Hey! check out this cool app. Get directions to fun places or anywhere, complete task + rewards:",
      link: window.location.origin,
      isFromShare: false,
   });
   const history = useHistory();
   const {
      user: {
         userInformation: { firstName, lastName },
      },
      tasks: { invite },
   } = useSelector((state) => state);

   const { submitTask } = useTask();

   const shareToSocialMedia = useCallback(
      (identifier) => {
         const { display_text, link, isFromShare } = inviteData;
         const urlObject = {
            WHATSAPP: `whatsapp://send?text=${display_text}: ${link}`,
            FACEBOOK: `https://www.facebook.com/sharer/sharer.php?u=${link}`,
            TWITTER: `https://twitter.com/intent/tweet?text=${display_text}&url=${link}`,
            GOOGLE: `mailto:?subject=Directions from Lara&body=${display_text} ${link}`,
            LINKEDIN: `https://www.linkedin.com/sharing/share-offsite/?url=${link}`,
         };
         window.open(urlObject[identifier], "_blank");

         submitTask();

         isFromShare && history.push(`/${INVITE}/${THANKS}`);
      },
      [inviteData]
   );

   const inviteUserSuccess = (data) => {
      const newData = { ...data.data, isFromShare: true };
      dispatch(inviteSliceActions.setInviteData(data.data));
      setInviteData(newData);
   };

   const inviteNewUser = useCallback(() => {
      if (!invite.invitee_name) {
         return history.push(`/${INVITE}/${INVITE_QUESTION}`);
      }

      const inviter_name = `${firstName} ${lastName}` ?? "Somebody";

      const url = `lara/beta/invite?invitee_name=${invite.invitee_name}&job_status=${invite.job_status}&inviter_name=${inviter_name}`;

      const config = {
         url,
         method: "get",
      };

      sendRequest(config, inviteUserSuccess, normalLoadingCallback, null);
   }, [invite, firstName, lastName]);

   return {
      shareToSocialMedia,
      inviteNewUser,
      inviteData,
   };
};

export default useInvite;
