import React, { lazy, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import useDevice from "../hooks/useDevice";
import useSetRouteLogic from "../hooks/useSetRouteLogic";
import { utilitiesActions } from "../redux/utilitiesSlice";
import Error from "../views/components/Error/Error";
import IosDownloadFlow from "../views/components/IosDownloadFlow/IosDownloadFlow";
import LaraLoader from "../views/components/LaraLoader/LaraLoader";
import Notificationtab from "../views/components/notificationtab/Notificationtab";
import PlayArea from "../views/pages/dashboard/PlayArea/PlayArea";
import FlagDirection from "../views/pages/dashboard/Task/flagDirection/FlagDirection";
import Invite from "../views/pages/invite/Invite";
import ShareLaraTask from "../views/tasks/ShareLaraTask/ShareLaraTask";
import NotificationModal from "../views/utilities/NotificationModals/NotificationModal";
import PopUpDownload from "../views/utilities/PopUpTerms/PopUpDownload";
import OnboardingRoutes from "./OnboardingRoutes";
import { FLAGDIRECTION, INVITE, PLAY_AREA, USER } from "./Path";

const UserRoutes = lazy(() => import("./UserRoutes"));

export const Routes = () => {
   const {
      utilities: {
         error: { isError },
         isNormalLoading,
         showModal,
         device: { name },
         downloadDisplay,
      },
      notification: { showNotification },
      directions: { shareDirection },
   } = useSelector((state) => state);
   useDevice();
   const dispatch = useDispatch();

   const { getParams } = useSetRouteLogic();
   const setDisplay = (value) => {
      dispatch(utilitiesActions.setDownloadDisplay(value));
   };

   useEffect(() => {
      getParams();
   }, []);

   return (
      <>
         {isNormalLoading && <LaraLoader />}
         {isError && <Error />}
         {showModal && <NotificationModal />}

         {showNotification && <Notificationtab />}
         {shareDirection && <ShareLaraTask />}
         {name === "IOS" && downloadDisplay && (
            <IosDownloadFlow onCloseModal={setDisplay} />
         )}
         {name !== "IOS" && downloadDisplay && (
            <PopUpDownload closeFunction={setDisplay} />
         )}

         <Switch>
            <Route path={`/${USER}`}>
               <Suspense fallback={<LaraLoader />}>
                  <UserRoutes />
               </Suspense>
            </Route>
            <Route path={`/${PLAY_AREA}`} component={PlayArea} exact />
            <Route path={`/${FLAGDIRECTION}`} component={FlagDirection} exact />
            <Route path={`/${INVITE}`} component={Invite} />

            <Route path={`/`} component={OnboardingRoutes} />

            <Route path="*">
               <Redirect to={`/`} />
            </Route>
         </Switch>
      </>
   );
};
