import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { utilitiesActions } from '../redux/utilitiesSlice';
import devicesInfo from '../Utilities/devicesInfo';

const useDevice = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		const deviceInfo = checkUserAgent();
		dispatch(utilitiesActions.setDeviceInfo(deviceInfo));
	}, [window.serviceWorkerUpdate]);

	const checkUserAgent = () => {
		let device = {};
		if (navigator.userAgent.match(/iPhone|iPad/i)) {
			device = devicesInfo[0];
		} else if (navigator.userAgent.match(/Android/i)) {
			device = devicesInfo[2];
		} else if (navigator.userAgent.match(/Windows/i)) {
			device = devicesInfo[1];
		} else if (navigator.userAgent.match(/Mac OS/i)) {
			device = devicesInfo[3];
		} else {
			device = {};
		}
		return device;
	};
	return;
};

export default useDevice;
