import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { showAppopriateTask } from "../data/task";
import { tasksAction } from "../redux/tasksSlice";
import { userNotificationActions } from "../redux/userNotificationSlice";
import { userSliceActions } from "../redux/userSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import { PLAY_AREA } from "../routes/Path";
import { dayNames, getDateInformation } from "../Utilities/helper";
import useHttps from "./useHttps";

const useTask = () => {
   const {
      user: { userInformation },
      tasks: { subTaskId, singleTask },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const history = useHistory();
   const { sendRequest } = useHttps();

   const getShownTasks = useCallback(() => {
      const { todayDate, tomorrowDate, overmorrowDate } = getDate();
      let todayDateTask = findTaskByDate(
         splitDateFromFrontend(todayDate.toISOString())
      );

      let tommorrowTask = findTaskByDate(
         splitDateFromFrontend(tomorrowDate.toISOString())
      );

      let overmorrowTask = findTaskByDate(
         splitDateFromFrontend(overmorrowDate.toISOString())
      );

      const todayDay = getDateInformation(todayDate);
      const tomorrowDay = getDateInformation(tomorrowDate);
      const overmorrowDay = getDateInformation(overmorrowDate);

      todayDateTask = {
         ...todayDateTask,
         isLocked: false,
         day: {
            ...todayDay,
            dayName: dayNames[0],
         },
      };

      tommorrowTask = {
         ...tommorrowTask,
         isLocked: true,
         day: {
            ...tomorrowDay,
            dayName: dayNames[1],
         },
      };

      overmorrowTask = {
         ...overmorrowTask,
         isLocked: true,
         day: {
            ...overmorrowDay,
            dayName: dayNames[2],
         },
      };

      return [todayDateTask, tommorrowTask, overmorrowTask].filter(
         (data) => data.task_id
      );
   }, [userInformation]);

   const findTaskByDate = (date) =>
      userInformation.user_tasks &&
      userInformation.user_tasks?.find(
         (task) => date === splitDateFromBackend(task.start_date)
      );

   const getDate = () => {
      const todayDate = new Date();
      const tomorrowDate = new Date(todayDate);
      tomorrowDate.setDate(todayDate.getDate() + 1);
      const overmorrowDate = new Date(todayDate);
      overmorrowDate.setDate(todayDate.getDate() + 2);

      return {
         todayDate,
         tomorrowDate,
         overmorrowDate,
      };
   };

   const setTaskId = (task_id) => {
      dispatch(tasksAction.setTask_id(task_id));
   };

   const choseAppropriateTask = (identifier) => {
      utilitiesActions.setFromWidget(false);
      const taskIndentifier = showAppopriateTask[identifier];
      dispatch(tasksAction.setAppropriateTask(taskIndentifier));
   };

   const onMaximize = () => {
      history.push(`/${PLAY_AREA}`);
   };

   const splitDateFromFrontend = (date) => date?.split("T")[0];

   const splitDateFromBackend = (date) => date?.split(" ")[0];

   const submitTask = () => {
      const sub_task_id = subTaskId;
      const task_id = singleTask.task_id;
      const user_task_id = singleTask.user_task_id;
      if (!singleTask.user_sub_task) {
         return;
      }

      const sub_task = singleTask.user_sub_task.find(
         (task) => task.sub_task_id === subTaskId
      );
      const iscompleted = sub_task?.is_done;

      if (!iscompleted && sub_task_id && task_id && user_task_id) {
         const config = {
            url: `user/tasks`,
            method: "patch",
            body: {
               sub_task_id,
               user_task_id,
               task_id,
            },
         };

         const success = (data) => {
            const {
               data: { updated_user_task },
            } = data;

            const [points, taskInfo, userInfo] = updated_user_task;
            const newUserInformation = { ...userInformation };
            const user_tasks = [...newUserInformation.user_tasks];

            const updatedTaskIndex = user_tasks.findIndex(
               (task) => task.task_id === taskInfo.task_id
            );

            const isTaskCompleted = taskInfo.completed;
            const selectedTask = { ...singleTask, ...taskInfo };

            user_tasks[updatedTaskIndex] = selectedTask;
            newUserInformation.user_tasks = user_tasks;
            const userPoints = points.total_points;

            const user = { ...newUserInformation, ...userInfo, userPoints };

            dispatch(userSliceActions.setUserInformation(user));
            dispatch(utilitiesActions.setShowModal(true));
            dispatch(tasksAction.setSingleTaskInformation(selectedTask));
            dispatch(
               utilitiesActions.setTaskCompletion({
                  isCompleted: true,
                  points: points.total_points,
                  headerText: isTaskCompleted
                     ? `You are done for today!`
                     : `Nicely done`,
                  subText: `Unto the next one`,
               })
            );
         };

         sendRequest(config, success);
      }
   };

   const receiveTasks = useCallback((value) => {
      dispatch(userNotificationActions.setBacklogTask(value.data.backlog));
   }, []);

   const getBacklogTasks = () => {
      sendRequest(
         {
            url: "user/backlog/tasks",
            method: "get",
         },
         receiveTasks
      );
   };

   return {
      getShownTasks,
      getDate,
      choseAppropriateTask,
      setTaskId,
      onMaximize,
      submitTask,
      getBacklogTasks,
   };
};

export default useTask;
