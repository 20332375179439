import askLaraNow from "./Ask Lara Now.svg";
import checkMark from "./Checkmark.svg";
import completeTask from "./Complete   task.svg";
import fortuneCookieOne from "./Fortune cookie-1.svg";
import fortuneCookieTwo from "./Fortune cookie-2.svg";
import jewelClaim from "./Jewel claim reward.svg";
import nairaHusle from "./Naira Hustle.svg";
import planATrip from "./Plan a trip.svg";
import takeMeThere from "./Take me there.svg";
import tellAFriend from "./Tell a friend.svg";

export const finalStageAssetsFiles = {
   askLaraNow,
   checkMark,
   completeTask,
   fortuneCookieOne,
   fortuneCookieTwo,
   jewelClaim,
   nairaHusle,
   planATrip,
   takeMeThere,
   tellAFriend,
};
