import React from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../../assets";
import usePushNotifcation from "../../../../hooks/usePushNotifcation";
import DirectionLoader from "../../../components/DirectionLoader/DirectionLoader";
import classes from "./SubscribeToPush.module.css";

const SubscribeToPush = () => {
   const {
      user: {
         userInformation: { firstName },
      },
   } = useSelector((state) => state);

   const { toggleModal, subscribeToPush, loading } = usePushNotifcation();
   return (
      <>
         <div className={classes.modalHeader}>
            <div>
               <p className={classes.modalText}>
                  Hi {firstName ? firstName : "there"}!
               </p>
            </div>

            <img
               className={classes.checkImg}
               src={iconImages.notificationIconImg}
               alt=""
            />
         </div>
         <div className={classes.modalBody}>
            <p>
               For better user experience; Would you like to turn on
               notifications?{" "}
            </p>

            <div className={classes.buttonContainer}>
               <button disabled={loading} onClick={() => toggleModal(false)}>
                  Later
               </button>
               <button
                  onClick={() => subscribeToPush(true)}
                  className={classes.action}
                  disabled={loading}
               >
                  Turn on Notification
               </button>
            </div>
            {loading && <DirectionLoader />}
         </div>
      </>
   );
};

export default SubscribeToPush;
