import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   loading: false,
   routeInformation: {},
   showAskUserLocation: false,
   showModal: false,
   directionsData: {},
   isFoundLocation: false,
   availableModesData: {},
   currentLocationData: {},
   mapInformation: {},
   currentLocationOnMap: {},
   showFullDirection: true,
   poiData: [],
   stopByLga: [],
   stopByLgaMeta: {},
   shareDirection: false,
   mapTag: "",
};

const directionSlice = createSlice({
   name: "getDirection",
   initialState,
   reducers: {
      setLoading(state, action) {
         state.loading = action.payload;
      },

      updateRouteInformation(state, action) {
         state.routeInformation = {
            ...state.routeInformation,
            ...action.payload,
         };
      },
      setShowAskUserLocation(state, action) {
         state.showAskUserLocation = action.payload;
      },
      setShowModal(state, action) {
         state.showModal = action.payload;
      },
      setDirectionsData(state, action) {
         state.directionsData = { ...action.payload };
      },
      setIsFoundLocation(state, action) {
         state.isFoundLocation = action.payload;
      },
      setAvailableModesData(state, action) {
         state.availableModesData = { ...action.payload };
      },
      clearRouteInformation(state) {
         state.routeInformation = {};
      },
      setMapInformation(state, action) {
         state.mapInformation = { ...state.mapInformation, ...action.payload };
      },
      setCurrentLocationData(state, action) {
         state.currentLocationData = {
            ...state.currentLocationData,
            ...action.payload,
         };
      },
      setLocationOnMap(state, action) {
         state.currentLocationOnMap = action.payload;
      },
      setShowFullDirection(state, action) {
         state.showFullDirection = action.payload;
      },
      setPoiData(state, action) {
         state.poiData = action.payload;
      },

      setStopsByLga(state, action) {
         state.stopByLga = action.payload;
      },
      setMetaStopsByLga(state, action) {
         state.stopByLgaMeta = action.payload;
      },
      setShareDirection(state, action) {
         state.shareDirection = action.payload;
      },
      setMapTags(state, action) {
         state.mapTag = action.payload;
      },
      reset: () => initialState,
   },
});

export const directionActions = directionSlice.actions;
export const directionReducer = directionSlice.reducer;

export default directionSlice;
