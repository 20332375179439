/* eslint-disable react/react-in-jsx-scope */
import { appleAuthHelpers, useScript } from "react-apple-signin-auth";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "./App.css";
import { interceptors } from "./interceptors/interceptors";
import { Routes } from "./routes/Routes";

interceptors.errorInterceptor();

function App() {
   useScript(appleAuthHelpers.APPLE_SCRIPT_SRC);

   return (
      <BrowserRouter>
         <ToastProvider>
            <Routes />
         </ToastProvider>
      </BrowserRouter>
   );
}

export default App;
