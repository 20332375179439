import React from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../../../assets";
import Container from "../../../../components/Wrappers/container/Container";
import classes from "./ContinuetoEmail.module.css";

const ContinuetoEmail = () => {
   const {
      onboarding: {
         userData: { firstName },
      },
   } = useSelector((state) => state);

   return (
      <Container className={classes.container}>
         <>
            <div className={classes.header}></div>
            <div className={classes.imgContainer}>
               <img src={iconImages.confirmImageBG} alt="" />
            </div>

            <div className={classes.allSet}>
               <h4>
                  Okay {firstName}! <span>&#128077;</span>{" "}
               </h4>
               <h4>You’re all set</h4>
               <p>Continue to email to verify account</p>
            </div>
         </>
      </Container>
   );
};

export default ContinuetoEmail;
