import React, { useEffect } from "react";
import classes from "./GetDirectionTask.module.css";
import PoiData from "../../utilities/Preferences/PoiData";
import SearchInput from "../../components/SearchInput/SearchInput";
import { directionActions } from "../../../redux/directionsSlice";
import { useDispatch, useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import PropTypes from "prop-types";
import { ADD_TO_DATA } from "../../../Utilities/types";
import { getit } from "../../../Utilities/helper";
import usePoi from "../../../hooks/usePoi";
import useMaps from "../../../hooks/useMaps";
import { TO_DIRECTION } from "../../../Utilities/mapTags";

const GetDirectionTask = (props) => {
   const dispatch = useDispatch();
   const {
      tasks: { maximize },
      directions: {
         routeInformation: { toText },
         poiData,
      },
   } = useSelector((state) => state);
   const { getPoiData } = usePoi();
   const { setMapTag } = useMaps();

   const token = getit("lara_token");

   const onSubmitFunction = (selectedList) => {
      const { lat, lng, stop_name } = selectedList;
      dispatch(
         directionActions.updateRouteInformation({
            toText: stop_name,
            toLat: lat,
            toLong: lng,
         })
      );
   };

   useEffect(() => {
      const identifier = setTimeout(() => {
         token && poiData.length === 0 && getPoiData();
      }, 1);

      return () => {
         clearTimeout(identifier);
      };
   }, []);

   return (
      <div className={`${classes.container} ${props.className}`}>
         <h2>
            Where do you want to go?{" "}
            <img height="20px" src={iconImages.destinationIconImg} alt="" />
         </h2>
         {!toText && (
            <PoiData
               text="Here are some suggestions, based off your interests"
               poiData={poiData}
            />
         )}
         {maximize && (
            <SearchInput
               className={classes.input}
               placeholder={"Try your own searches"}
               onSubmitFunction={onSubmitFunction}
               type={ADD_TO_DATA}
               onFocus={() => setMapTag(TO_DIRECTION)}
            />
         )}
      </div>
   );
};

GetDirectionTask.propTypes = {
   className: PropTypes.string,
};

export default GetDirectionTask;
