import { onboardingVideoAnimation } from "../assets/videos/Onboarding";

const cityInfo = [
   {
      name: "Abuja",
      imgUrl: "/assets/images/cityassets/Abuja/Cab.svg",
      videoUrl: onboardingVideoAnimation.abujaanimation,
   },
   {
      name: "Anambra",
      imgUrl: "/assets/images/cityassets/Anambra/Minibus.svg",
      videoUrl: onboardingVideoAnimation.Anambravideoanimation,
   },
   {
      name: "Benin",
      imgUrl: "/assets/images/cityassets/Benin/Bus.svg",
      videoUrl: onboardingVideoAnimation.beninvideoanimation,
   },
   {
      name: "Enugu",
      imgUrl: "/assets/images/cityassets/Enugu/EnuguBus.svg",
      videoUrl: onboardingVideoAnimation.enuguvideoanimation,
   },
   {
      name: "Ibadan",
      imgUrl: "/assets/images/cityassets/Ibadan/MicraCab.svg",
      videoUrl: onboardingVideoAnimation.ibadanvideoanimation,
   },
   {
      name: "Ogun",
      imgUrl: "/assets/images/cityassets/Ogun/Cab.svg",
      videoUrl: onboardingVideoAnimation.ogunvideoanimation,
   },
   {
      name: "Port Harcourt",
      imgUrl: "/assets/images/cityassets/Port Harcourt/Cab.svg",
      videoUrl: onboardingVideoAnimation.PortHarcourtvideoanimation,
   },
];

export default cityInfo;
