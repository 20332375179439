import React from "react";
import ModalContainer from "../Wrappers/ModalContainer/ModalContainer";
import classes from "./Error.module.css";
import { useSelector, useDispatch } from "react-redux";
import { utilitiesActions } from "../../../redux/utilitiesSlice";
import { motion } from "framer-motion";
import { modalVariant } from "../Wrappers/ModalContainer/modalContainerVariants";
import { directionActions } from "../../../redux/directionsSlice";
import usePoi from "../../../hooks/usePoi";

const Error = () => {
   const {
      utilities: {
         error: { errorText, errorFunctionType, errorButtonText },
      },
   } = useSelector((state) => state);

   const { getPoiData } = usePoi();

   const locatorErrorAction = () => {
      closeErrorModal();
   };

   const getDirectionConnectionError = () => {
      getPoiData();
      dispatch(directionActions.reset());
      closeErrorModal();
   };

   const searchDropDownError = () => {
      closeErrorModal();
   };

   const feedbackError = () => {
      closeErrorModal();
   };

   const LoginErrorAction = () => {
      closeErrorModal();
   };

   const errorsActionObject = {
      LOCATOR: locatorErrorAction,
      MODAL: getDirectionConnectionError,
      SEARCH: searchDropDownError,
      LOGIN: LoginErrorAction,
      FEEDBACK: feedbackError,
   };

   const errorAction = errorsActionObject[errorFunctionType];

   const dispatch = useDispatch();
   const closeErrorModal = () => {
      dispatch(utilitiesActions.setError({ isError: false }));
   };
   return (
      <ModalContainer className={classes.container}>
         <motion.div variants={modalVariant}>
            <div className={classes.upperContainer}>
               <p>{errorText}</p>
            </div>
            {errorAction && (
               <div className={classes.lowerContainer}>
                  <button className={classes.action} onClick={errorAction}>
                     {errorButtonText}
                  </button>
               </div>
            )}
         </motion.div>
      </ModalContainer>
   );
};

export default Error;
