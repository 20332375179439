import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import useSetRouteLogic from "../../../hooks/useSetRouteLogic";
import { INVITE_HOME, INVITE_LOGIC, INVITE_THANKS } from "../../../routes/Path";
import InviteContainer from "./invitecontainer/InviteContainer";
import Invitehome from "./inviteHome/invitehome";
import Invitelogic from "./inviteLogic/invitelogic";
import inviteThanks from "./inviteThanks/inviteThanks";

const Invite = () => {
   const { path } = useRouteMatch();

   const { redirectToLogin } = useSetRouteLogic();

   useEffect(() => {
      redirectToLogin();
   }, []);
   return (
      <InviteContainer>
         <Switch>
            <Route
               path={`${path}/${INVITE_HOME}`}
               component={Invitehome}
               exact
            />
            <Route path={`${path}/${INVITE_LOGIC}`} component={Invitelogic} />
            <Route
               path={`${path}/${INVITE_THANKS}`}
               component={inviteThanks}
               exact
            />
            <Route path="*">
               <Redirect to={`${path}/${INVITE_HOME}`} />
            </Route>
         </Switch>
      </InviteContainer>
   );
};

export default Invite;
