import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { iconImages } from "../../../assets";
import useInput from "../../../hooks/useInput";
import { onboardingActions } from "../../../redux/onboardingSlice";
import { ABOUT } from "../../../routes/Path";
import validators from "../../../Utilities/inputValidator";
import TermsandCondition from "../../utilities/TermsandCondition/TermsandCondition";
import classes from "./WhatsYourName.module.css";

const WhatsYourName = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const {
      onboarding: {
         userData: { firstName: stateFirstName, lastName: stateLastname },
      },
   } = useSelector((state) => state);
   const {
      inputBlurHandler: firstNameBlurHandler,
      value: firstName,
      valueInputChange: firstNameInputChange,
      valueIsValid: firstNameIsValid,
   } = useInput(
      validators.checkValueIsEmpty,
      stateFirstName ? stateFirstName : ""
   );
   const {
      inputBlurHandler: lastNameBlurHandler,
      value: lastName,
      valueInputChange: lastNameInputChange,
   } = useInput(
      validators.checkValueIsEmpty,
      stateLastname ? stateLastname : ""
   );

   const formIsvalid = firstNameIsValid;

   const submitForm = (event) => {
      event.preventDefault();
      const { addToUserData, setInviteeData } = onboardingActions;
      const name = `${firstName} ${lastName}`;
      const obj = { firstName, lastName, name };
      dispatch(addToUserData(obj));
      dispatch(setInviteeData({}));
      history.push(`/${ABOUT}`);
   };
   return (
      <form onSubmit={submitForm}>
         <h3>What’s your name?</h3>
         <div className={classes.inputContainer}>
            <input
               type="text"
               value={firstName}
               onBlur={firstNameBlurHandler}
               onChange={firstNameInputChange}
               placeholder="First name"
            />
            <input
               value={lastName}
               onBlur={lastNameBlurHandler}
               onChange={lastNameInputChange}
               type="text"
               placeholder="Last name"
            />
         </div>
         <TermsandCondition text={"By entering your name"} />

         <div className={classes.btnContainer}>
            <AnimatePresence exitBeforeEnter>
               {formIsvalid && (
                  <motion.button>
                     <img src={iconImages.betaSendImg} alt="" />
                  </motion.button>
               )}
            </AnimatePresence>
         </div>
      </form>
   );
};

export default WhatsYourName;
