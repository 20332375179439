import laraLogo from "./images/logo.svg";
import danfo from "./images/Danfo3d.svg";
import androidIconImg from "./images/icons/Android Icon.svg";
import androidPwaImg from "./images/icons/Android PWA.svg";
import appleIosIconImg from "./images/icons/Apple iOS Icon.svg";
import cancelIconImg from "./images/icons/Cancel.svg";
import chatIconImg from "./images/icons/Chat Icon.svg";
import dailyRewardTaskImg from "./images/icons/Claim Reward Daily Task Area.svg";
import clearNotificationImg from "./images/icons/Clear notification Icon.svg";
import clearInputLineImg from "./images/icons/Clear type input line.svg";
import completeTaskImg from "./images/icons/Complete Task.svg";
import confirmLocationPinImg from "./images/icons/Confirm Location Pin.svg";
import dailyTaskCheckIconImg from "./images/icons/Daily Task Check Icon.svg";
import destinationIconImg from "./images/icons/Destination Icon.svg";
import emeraldCheckIconImg from "./images/icons/Emerald Check Icon.svg";
import exitButtonImg from "./images/icons/Exit Button.svg";
import expandCardDashboardImg from "./images/icons/Expand Card Dashboard to Directions Layer.svg";
import expandCardDirectionsImg from "./images/icons/Expand Card Directions.svg";
import femaleSliderIconImg from "./images/icons/Female Slider Icon.svg";
import femaleIconImg from "./images/icons/Female.png";
import filterDropdownIconImg from "./images/icons/Filter Dropdown Icon.svg";
import fullscreenExitToDashboardImg from "./images/icons/Fullscreen Exit to Dashboard.svg";
import goodEveningImg from "./images/icons/Good Evening.svg";
import goodMorningImg from "./images/icons/Good Morning.svg";
import foundYourLocationImg from "./images/icons/I found your location.svg";
import infoIconImg from "./images/icons/Info Icon.svg";
import iosPwaImg from "./images/icons/iOS PWA.svg";
import loadingCirclesImg from "./images/icons/Loading circles.svg";
import locationSuggestionIconImg from "./images/icons/Location Suggestion Icon.svg";
import lockedPadLockImg from "./images/icons/Locked Padlock.svg";
import maleSliderImg from "./images/icons/Male Slider.svg";
import MaleImg from "./images/icons/Male.png";
import minimizeCardWithinDirectionImg from "./images/icons/Minimize Card Within Directions layer.svg";
import nigeriaLocationIconImg from "./images/icons/Nigeria Location Icon.svg";
import noentryIconImg from "./images/icons/no-entry_26d4.png";
import notificationIconImg from "./images/icons/Notification Icon.svg";
import partyPropperImg from "./images/icons/party-popper_1f389.png";
import powerValueIconImg from "./images/icons/Power Value Icon.svg";
import rainyWeatherIconImg from "./images/icons/Rainy Weather Icon.svg";
import raiseingHandsIconImg from "./images/icons/raising-hands_medium-skin-tone_1f64c-1f3fd_1f3fd.png";
import remindMeLaterIconImg from "./images/icons/Remind me later icon.svg";
import returnFromMapButtonImg from "./images/icons/Return from Map Button.svg";
import returnIconfromDashboardImg from "./images/icons/Return Icon- Dashboard.svg";
import saveRewardForLaterImg from "./images/icons/Save reward for later.svg";
import sendFeedbackImg from "./images/icons/Send Feedback.svg";
import setLocationOnMapImg from "./images/icons/Set Location on Map.svg";
import settingsIconImg from "./images/icons/setting.svg";
import takeTaskNowImg from "./images/icons/Take Task Now Icon.svg";
import taskCompleteActionImg from "./images/icons/Task Complete Icon.svg";
import thanksforFeedbackImg from "./images/icons/Thanks for the Feedback.svg";
import typeInALocationImg from "./images/icons/Type in a location Icon.svg";
import unlockedIconImg from "./images/icons/Unlocked Icon.svg";
import verifiedIconImg from "./images/icons/Verified Icon.svg";
import wavingHandMediumImg from "./images/icons/waving-hand_medium-light-skin-tone_1f44b-1f3fc_1f3fc.png";
import whiteBackArrowImg from "./images/icons/White Back Arrow.svg";
import windowsIonImg from "./images/icons/Windows Icon.svg";
import windowsPwaImg from "./images/icons/Windows PWA.svg";
import level1BadgeImg from "./images/LevelBadges/Level 1 Badge.svg";
import level2BadgeImg from "./images/LevelBadges/Level 2 Badge.svg";
import level3BadgeImg from "./images/LevelBadges/Level 3 Badge.svg";
import level4BadgeImg from "./images/LevelBadges/Level 4 Badge.svg";
// import abujaAnimationVideo from './videos/Onboarding/abujaanimation.mp4';
import l1WadaadAbujaImg from "./images/PlateBadges/Abuja/L1- Wadaad of Abuja.svg";
import l2WazirAbujaImg from "./images/PlateBadges/Abuja/L2- Wazir of Abuja.svg";
import l3EmirAbujaImg from "./images/PlateBadges/Abuja/L3- Emir Al Abuja.svg";
import l4SheikhAbujaImg from "./images/PlateBadges/Abuja/L4- Sheikh of Abuja.svg";
import l1NwanneAnambraImg from "./images/PlateBadges/Anambra/L1- Nwanne.svg";
import l2EzeAnambraImg from "./images/PlateBadges/Anambra/L2- Eze Ego.svg";
import l3OdogwuAnambraImg from "./images/PlateBadges/Anambra/L3- Odogwu of Every.svg";
import l4OgbuefiAnambraImg from "./images/PlateBadges/Anambra/L4- OGBUEFI International .svg";
import l1YoungBeninImg from "./images/PlateBadges/Benin/L1- YOUNG OKORO.svg";
import l2OgeivaBeninImg from "./images/PlateBadges/Benin/L2- OGIEVA.svg";
import l3OzuaBeninImg from "./images/PlateBadges/Benin/L3- Ozua Lara Kingdom.svg";
import l4RoyalBeninImg from "./images/PlateBadges/Benin/L4- Royal Emperor.svg";
import l1NwanneEnuguImg from "./images/PlateBadges/Enugu/L1-Nwanne.svg";
import l2EzeEnuguImg from "./images/PlateBadges/Enugu/L2-Eze Ego.svg";
import l3OdogwuEnuguImg from "./images/PlateBadges/Enugu/L3-Odogwu of Every.svg";
import l4OgbuefiEnuguImg from "./images/PlateBadges/Enugu/L4-Ogbuefi Intl.svg";
import l1OmoluabiIbadanImg from "./images/PlateBadges/Ibadan/L1- Omoluabi.svg";
import l2AgbaIbadanImg from "./images/PlateBadges/Ibadan/L2- Agba Oye.svg";
import l3BashorunIbadanImg from "./images/PlateBadges/Ibadan/L3- Bashorun.svg";
import l4AlaafinIbadanImg from "./images/PlateBadges/Ibadan/L4- Alaafin.svg";
import l1OmoEgbaOgunImg from "./images/PlateBadges/Ogun/L1-Omo Egba.svg";
import l2EleniyanOgunImg from "./images/PlateBadges/Ogun/L2- Eleniyan.svg";
import l3BalogunOgunImg from "./images/PlateBadges/Ogun/L3- Balogun.svg";
import l4AlakeOgunImg from "./images/PlateBadges/Ogun/L4- Alake 1.svg";
import l1AbobiPortHarcourtImg from "./images/PlateBadges/Port-Harcourt/L1- Abobi.svg";
import l2EzePortHarcourtImg from "./images/PlateBadges/Port-Harcourt/L2- Eze Ego.svg";
import l3OdogwuPortHarcourtImg from "./images/PlateBadges/Port-Harcourt/L3- Odogwu of Every.svg";
import l4OgbuefiPortHarcourtImg from "./images/PlateBadges/Port-Harcourt/L4- OGBUEFI Intl.svg";
import greyRatingOne from "./images/greyRating/Grey- Rating 1.svg";
import greyRatingTwo from "./images/greyRating/Grey- Rating 2.svg";
import greyRatingThree from "./images/greyRating/Grey- Rating 3.svg";
import greyRatingFour from "./images/greyRating/Grey- Rating 4.svg";
import greyRatingFive from "./images/greyRating/Grey-Rating 5.svg";
import colouredRatingOne from "./images/colouredRating/Rating 1.svg";
import colouredRatingTwo from "./images/colouredRating/Rating 2.svg";
import colouredRatingThree from "./images/colouredRating/Rating 3.svg";
import colouredRatingFour from "./images/colouredRating/Rating 4.svg";
import colouredRatingFive from "./images/colouredRating/Rating 5.svg";
// import homePageAnimation from './videos/bacgroundAnimation.mp4';
import appleSocialLoginImg from "./images/icons/Apple Social  login.svg";
import appleIconImg from "./images/icons/appleicon.svg";
import betaSendImg from "./images/icons/betasend.svg";
import cityGateCombinedShapeImg from "./images/icons/city gate Combined Shape.svg";
import countMeInColouredImg from "./images/icons/Count me in Colored.svg";
import countMeInGreyImg from "./images/icons/Count me in Grey.svg";
import facebookSocialLoginImg from "./images/icons/Facebook Social login.svg";
import facebookIConImg from "./images/icons/facebookicon.svg";
import googleSocialLoginImg from "./images/icons/Google Social login.svg";
import googleIconImg from "./images/icons/googleicon.svg";
import greenLoveImg from "./images/icons/greenlove.svg";
import hollaAtFacebokImg from "./images/icons/Hola at us- Facebook.svg";
import hollaAtInstagramImg from "./images/icons/Hola at us- Instagram.svg";
import hollaAtMailImg from "./images/icons/Hola at us- Mail_Gmail.svg";
import hollaAtTwitterIImg from "./images/icons/Hola at us- Twitter.svg";
import nahIwouldPassColouredImg from "./images/icons/Nah Id Pass Colored.svg";
import nahIwouldPassGreyImg from "./images/icons/Nah Id Pass Grey .svg";
import onboardingVerifiedIconImg from "./images/icons/onboardingVerifiedIcon.svg";
import stage1UfoImg from "./images/icons/Stage1- UFO with Bus.svg";
import stage2UfoImg from "./images/icons/Stage2-UFO with Bus.svg";
import stage3UfoImg from "./images/icons/Stage3- UFO Alone.svg";
import planeMapsImg from "./images/icons/Plain mAP 3X.png";
import goodAfternoonImg from "./images/icons/Good Afternoon.svg";
import netflixIcon from "./images/RewardAssets/RewardAssets/Page1/netflix.svg";
import airtimeIcon from "./images/RewardAssets/RewardAssets/Page1/Airtime or Data Icon.svg";
import lockXP_icon from "./images/RewardAssets/RewardAssets/GeneralIcon/locked XP Icon.svg";
import unlockXP_icon from "./images/RewardAssets/RewardAssets/GeneralIcon/Unlocked XP Icon.svg";
import selectedItem from "./images/RewardAssets/RewardAssets/GeneralIcon/Select Item- Highlighted.svg";
import unselectedItem from "./images/RewardAssets/RewardAssets/GeneralIcon/UnSelected Item.svg";
import rewardsCoin from "./images/RewardAssets/RewardAssets/GeneralIcon/coin.svg";
import rewardsPhoneCall from "./images/RewardAssets/RewardAssets/GeneralIcon/Reach me by- phone-call.svg";
import rewardsEmail from "./images/RewardAssets/RewardAssets/GeneralIcon/Reach me via eMail.svg";
import carouselHighlighted from "./images/RewardAssets/RewardAssets/GeneralIcon/Carousel Highlighted .svg";
import carouselUnhighlighted from "./images/RewardAssets/RewardAssets/GeneralIcon/Carousel Not Highlighted .svg";
import linkedinIcon from "./images/RewardAssets/RewardAssets/Page1/Linkedin Learning.svg";
import amazonCardIcon from "./images/RewardAssets/RewardAssets/Page4/Amazon-Gift-Card.png";
import barterIcon from "./images/RewardAssets/RewardAssets/Page4/Barter logo.svg";
import betNaijaIcon from "./images/RewardAssets/RewardAssets/Page4/bet9ja.png";
import betWayIcon from "./images/RewardAssets/RewardAssets/Page4/Betway Logo.png";
import uberGiftCard from "./images/RewardAssets/RewardAssets/Page6/Uber gift card.png";
import uberLogo from "./images/RewardAssets/RewardAssets/Page6/Asset 1Uber logo.svg";
import dominosLogo from "./images/RewardAssets/RewardAssets/Page6/Domino's logo.png";
import pizza from "./images/RewardAssets/RewardAssets/Page6/Pizza.png";
import dumbell from "./images/RewardAssets/RewardAssets/Page3/Dumbell.svg";
import muscles from "./images/RewardAssets/RewardAssets/Page3/muscles.svg";
import coldstoneImg from "./images/RewardAssets/RewardAssets/Page3/Coldstone.png";
import sharwamaImg from "./images/RewardAssets/RewardAssets/Page3/Domino Sharwama roll.png";
import moneyAfrica from "./images/RewardAssets/RewardAssets/Page3/MoneyAfrica Logo.svg";
import audibleGift from "./images/RewardAssets/RewardAssets/Page5/Audible Gift Card.png";
import chickenRepublic from "./images/RewardAssets/RewardAssets/Page5/ChickenRepublic_Favicon.png";
import jumiaCard from "./images/RewardAssets/RewardAssets/Page5/Jumia Gift Card.png";
import refuelMax from "./images/RewardAssets/RewardAssets/Page5/Refuel Max- Chicken republic.png";
import whogohost from "./images/RewardAssets/RewardAssets/Page5/whogohost-logo.png";
import camera from "./images/RewardAssets/RewardAssets/Page2/Camera.png";
import cinemaCup from "./images/RewardAssets/RewardAssets/Page2/Cinema cup.png";
import cinemaTicket from "./images/RewardAssets/RewardAssets/Page2/Cinema ticket.png";
import diaphragm from "./images/RewardAssets/RewardAssets/Page2/diaphragm.svg";
import exposure from "./images/RewardAssets/RewardAssets/Page2/exposure.svg";
import flash from "./images/RewardAssets/RewardAssets/Page2/flash.svg";
import focus from "./images/RewardAssets/RewardAssets/Page2/Focus.svg";
import iso from "./images/RewardAssets/RewardAssets/Page2/iso.svg";
import spillingPopcorn from "./images/RewardAssets/RewardAssets/Page2/Spiling Popcorn.png";
import phoneIcon from "./images/RewardAssets/RewardAssets/GeneralIcon/phone-icon.svg";
import emailIcon from "./images/RewardAssets/RewardAssets/GeneralIcon/email-icon.svg";

import logo from "./images/Android Lara Logo-152px.png";
import shareLarabg from "./images/icons/Share direction pop up- Lara Beta.svg";
import googleShare from "./images/socialicons/Google.svg";
import facebookShare from "./images/socialicons/facebook.svg";
import twitterShare from "./images/socialicons/twitter.svg";
import whatsappShare from "./images/socialicons/whatsapp.svg";
import clipboardShare from "./images/socialicons/share.svg";
import homescreenPlaceholder from "./images/Lara Beta Homescreen Placeholder.svg";
import laraLogoText from "./images/icons/Lara Text logo.svg";
import rewardGift from "./images/icons/wrapped-gift_1f381.png";
import confirmImageBG from "./images/icons/confirmemailbg.svg";
import emailVerificationLogo from "./images/icons/mail.svg";
import lightCheckMark from "./images/icons/checkmarknone.png";
import mapStopsIcon from "./images/icons/iconwithbus.png";
import mapMarkerIcon from "./images/icons/Location_icon.svg";
import addMissingPlaceIcon from "./images/Add Missing POIs/Add a missing place Icon.svg";
import addMissingRoadIcon from "./images/Add Missing POIs/Add a Missing Road Icon.svg";
import addPOIIcon from "./images/Add Missing POIs/Add icon.svg";
import followAbuja from "./images/Follow on Socials/Abuja Follow socials.svg";
import followAnambra from "./images/Follow on Socials/Anambra-- Socials Follow.svg";
import followBenin from "./images/Follow on Socials/Benin City-Socials Follow.svg";
import followEnugu from "./images/Follow on Socials/Enugu-Socials Follow.svg";
import followIbadan from "./images/Follow on Socials/Ibadan-Follow on Socials.svg";
import facebookWhiteIcon from "./images/Follow on Socials/Facebook White Icon.svg";
import instagramWhiteIcon from "./images/Follow on Socials/Instagram white icon.svg";
import instagramColoredIcon from "./images/Follow on Socials/Instagram Colored Icon.svg";
import linkedinColoredIcon from "./images/Follow on Socials/Linkedin Colored Icon.svg";
import linkedinWhiteIcon from "./images/Follow on Socials/Linkedin White Icon.svg";
import followOgun from "./images/Follow on Socials/Ogun-Follow on Socials.svg";
import twitterColoredIcon from "./images/Follow on Socials/Twitter Colored Icon.svg";
import twitterWhiteIcon from "./images/Follow on Socials/Twitter White Icon.svg";
import followPortHarcourt from "./images/Follow on Socials/Port Harcourt- Follow on Socials.svg";
import makeSense from "./images/Test Directions/Make sense.svg";
import problemDey from "./images/Test Directions/Problem Dey.svg";
import movingcity from "./Moving city 1.m4v";
import countmeInvite from "./images/Count me in Next Arrow.svg";
import blueSendIcon from "./images/Send Blue button.svg";
import blackCloseIcon from "./images/Black Close Icon.svg";
import thankYou from "./images/Thank You 3x.png";
import leftArrowIcon from "./images/icons/left-arrow.svg";
import safari from "../../src/assets/images/icons/safari.png";
import enuguVid from "../../src/assets/images/Enugu Animations Video.m4v";
import ibadanVid from "../../src/assets/images/Ibadan Animations Video.m4v";
import loadingVid from "../../src/assets/images/loader.gif";
import loaderMov from "../../src/assets/images/loaderMovie.mp4";
import homeScreenIcon from "../../src/assets/images/icons/Add to homescreen.svg";
import shareIcon from "../../src/assets/images/icons/ios share icon.svg";

export const levelBadgesImages = {
   level1BadgeImg,
   level2BadgeImg,
   level3BadgeImg,
   level4BadgeImg,
};

export const plateBadgesImages = [
   {
      state: "Abuja",
      level1: l1WadaadAbujaImg,
      level2: l2WazirAbujaImg,
      level3: l3EmirAbujaImg,
      level4: l4SheikhAbujaImg,
   },
   {
      state: "Anambra",
      level1: l1NwanneAnambraImg,
      level2: l2EzeAnambraImg,
      level3: l3OdogwuAnambraImg,
      level4: l4OgbuefiAnambraImg,
   },
   {
      state: "Benin",
      level1: l1YoungBeninImg,
      level2: l2OgeivaBeninImg,
      level3: l3OzuaBeninImg,
      level4: l4RoyalBeninImg,
   },
   {
      state: "Enugu",
      level1: l1NwanneEnuguImg,
      level2: l2EzeEnuguImg,
      level3: l3OdogwuEnuguImg,
      level4: l4OgbuefiEnuguImg,
   },
   {
      state: "Ibadan",
      level1: l1OmoluabiIbadanImg,
      level2: l2AgbaIbadanImg,
      level3: l3BashorunIbadanImg,
      level4: l4AlaafinIbadanImg,
   },
   {
      state: "Ogun",
      level1: l1OmoEgbaOgunImg,
      level2: l2EleniyanOgunImg,
      level3: l3BalogunOgunImg,
      level4: l4AlakeOgunImg,
   },
   {
      state: "Port Harcourt",
      level1: l1AbobiPortHarcourtImg,
      level2: l2EzePortHarcourtImg,
      level3: l3OdogwuPortHarcourtImg,
      level4: l4OgbuefiPortHarcourtImg,
   },
];

export const iconImages = {
   leftArrowIcon,
   powerValueIconImg,
   unlockedIconImg,
   verifiedIconImg,
   windowsIonImg,
   windowsPwaImg,
   wavingHandMediumImg,
   whiteBackArrowImg,
   saveRewardForLaterImg,
   thanksforFeedbackImg,
   typeInALocationImg,
   taskCompleteActionImg,
   setLocationOnMapImg,
   takeTaskNowImg,
   sendFeedbackImg,
   settingsIconImg,
   returnIconfromDashboardImg,
   rainyWeatherIconImg,
   raiseingHandsIconImg,
   remindMeLaterIconImg,
   returnFromMapButtonImg,
   laraLogo,
   androidIconImg,
   androidPwaImg,
   appleIosIconImg,
   cancelIconImg,
   chatIconImg,
   dailyRewardTaskImg,
   clearNotificationImg,
   clearInputLineImg,
   completeTaskImg,
   confirmLocationPinImg,
   dailyTaskCheckIconImg,
   destinationIconImg,
   emeraldCheckIconImg,
   exitButtonImg,
   expandCardDashboardImg,
   expandCardDirectionsImg,
   femaleSliderIconImg,
   femaleIconImg,
   filterDropdownIconImg,
   fullscreenExitToDashboardImg,
   goodEveningImg,
   goodMorningImg,
   foundYourLocationImg,
   infoIconImg,
   iosPwaImg,
   loadingCirclesImg,
   locationSuggestionIconImg,
   lockedPadLockImg,
   maleSliderImg,
   MaleImg,
   minimizeCardWithinDirectionImg,
   nigeriaLocationIconImg,
   noentryIconImg,
   notificationIconImg,
   partyPropperImg,
   greyRatingOne,
   greyRatingFour,
   greyRatingThree,
   greyRatingTwo,
   greyRatingFive,
   colouredRatingOne,
   colouredRatingTwo,
   colouredRatingThree,
   colouredRatingFive,
   colouredRatingFour,
   appleIconImg,
   appleSocialLoginImg,
   betaSendImg,
   cityGateCombinedShapeImg,
   countMeInColouredImg,
   countMeInGreyImg,
   facebookSocialLoginImg,
   facebookIConImg,
   googleSocialLoginImg,
   googleIconImg,
   greenLoveImg,
   hollaAtFacebokImg,
   hollaAtInstagramImg,
   hollaAtMailImg,
   hollaAtTwitterIImg,
   nahIwouldPassColouredImg,
   nahIwouldPassGreyImg,
   onboardingVerifiedIconImg,
   stage1UfoImg,
   stage2UfoImg,
   stage3UfoImg,
   planeMapsImg,
   goodAfternoonImg,
   danfo,
   netflixIcon,
   airtimeIcon,
   lockXP_icon,
   linkedinIcon,
   amazonCardIcon,
   barterIcon,
   betNaijaIcon,
   betWayIcon,
   uberLogo,
   uberGiftCard,
   dominosLogo,
   pizza,
   dumbell,
   muscles,
   coldstoneImg,
   sharwamaImg,
   moneyAfrica,
   audibleGift,
   chickenRepublic,
   jumiaCard,
   refuelMax,
   whogohost,
   camera,
   cinemaCup,
   cinemaTicket,
   diaphragm,
   exposure,
   flash,
   focus,
   iso,
   spillingPopcorn,
   phoneIcon,
   emailIcon,
   logo,
   shareLarabg,
   googleShare,
   whatsappShare,
   twitterShare,
   clipboardShare,
   facebookShare,
   homescreenPlaceholder,
   carouselHighlighted,
   carouselUnhighlighted,
   rewardsCoin,
   rewardsEmail,
   rewardsPhoneCall,
   selectedItem,
   unselectedItem,
   unlockXP_icon,
   laraLogoText,
   rewardGift,
   confirmImageBG,
   emailVerificationLogo,
   lightCheckMark,
   mapStopsIcon,
   mapMarkerIcon,
   movingcity,
   countmeInvite,
   blueSendIcon,
   blackCloseIcon,
   thankYou,
   safari,
   homeScreenIcon,
   shareIcon,
};

export const socials = {
   facebookWhiteIcon,
   instagramColoredIcon,
   instagramWhiteIcon,
   linkedinColoredIcon,
   linkedinWhiteIcon,
   twitterColoredIcon,
   twitterWhiteIcon,
   followAbuja,
   followAnambra,
   followBenin,
   followEnugu,
   followIbadan,
   followOgun,
   followPortHarcourt,
};

export const POIs = {
   addMissingPlaceIcon,
   addMissingRoadIcon,
   addPOIIcon,
};

export const testDir = {
   makeSense,
   problemDey,
};

export const animationVideos = {
   enuguVid,
   ibadanVid,
   loadingVid,
   loaderMov,
};
