import React from "react";
import { iconImages } from "../../../assets";
import useAutoGeneratedFeedback from "../../../hooks/useAutoGeneratedFeedback";
import FeedbackTextArea from "../FeedbackTextArea/FeedbackTextArea";
import DirectionsContainerWrapper from "../Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./FeedbackOnRoute.module.css";

const FeedbackOnRoute = () => {
   const { submitFinalFeedback } = useAutoGeneratedFeedback();

   return (
      <DirectionsContainerWrapper>
         <div className={classes.container}>
            <h2>What was wrong?</h2>
            <p>e.g. Price is now #50</p>
            <p>Micra cabs no longer exists, only keke</p>
            <p>Correct Direction is from A Bus Stop to B Bus Stop</p>
            <FeedbackTextArea />
            <div className={classes.sendFeedbackContainer}>
               <button onClick={submitFinalFeedback}>
                  Send FeedBack <img src={iconImages.sendFeedbackImg} alt="" />
               </button>
            </div>
         </div>
      </DirectionsContainerWrapper>
   );
};

export default FeedbackOnRoute;
