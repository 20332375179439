/**
 * This function is used to redirect the user to the appropriate route based on the current
location.
 * @returns A function that takes in a boolean and returns a function.
 */
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { DASHBOARD, INTERESTS, LOGIN, OCCUPATION, USER } from "../routes/Path";
import { getit } from "../Utilities/helper";
import useDownload from "./useDownload";
import useShare from "./useShare";

const useSetRouteLogic = () => {
   const history = useHistory();
   const token = getit("lara_token");
   const location = useLocation();
   const { getShareIdFromParams } = useShare();
   const { slideCallback } = useDownload();
   const {
      user: {
         userInformation: { city },
      },
   } = useSelector((state) => state);

   const getParams = () => {
      const params = location.search;
      if (params?.indexOf("search_id") > -1 && params?.indexOf("share_id")) {
         return getShareIdFromParams(params);
      }

      if (params?.indexOf("utm=download") > -1) {
         setTimeout(slideCallback, 5000);
      }

      redirectToOnboarding();
   };

   const redirectToLogin = useCallback(() => {
      return !token && history.push(`/${LOGIN}`);
   }, [token, history]);

   const redirectToDashboard = useCallback(() => {
      return token && history.push(`/${USER}/${DASHBOARD}`);
   }, [token, history]);

   const redirectToOnboarding = useCallback(() => {
      return !token && history.push(`/`);
   }, [token, history]);

   const redirectFromInterest = useCallback(() => {
      return city && history.push(`/${USER}/${DASHBOARD}`);
   }, [history]);

   const redirectToInterest = () => {
      return !city && history.push(`/${USER}/${INTERESTS}/${OCCUPATION}`);
   };

   return {
      redirectToLogin,
      redirectToDashboard,
      getParams,
      redirectToOnboarding,
      redirectFromInterest,
      redirectToInterest,
   };
};

export default useSetRouteLogic;
