import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import useHttps from "../../../hooks/useHttps";
import useInput from "../../../hooks/useInput";
import { utilitiesActions } from "../../../redux/utilitiesSlice";
import { REGISTER } from "../../../routes/Path";
import validators from "../../../Utilities/inputValidator";
import Container from "../../components/Wrappers/container/Container";
import OnboardingWrapperContainer from "../../components/Wrappers/OnboardingWrapperContainer/OnboardingWrapperContainer";
import InterestButton from "../../utilities/InterestsButton/InterestButton";
import Logo from "../../utilities/Logo/Logo";
import classes from "./ForgotPassword.module.css";

const ForgotPassword = () => {
   const { value, inputBlurHandler, valueIsValid, valueInputChange, hasError } =
      useInput(validators.checkEmailIsValid);
   const dispatch = useDispatch();

   const { sendRequest } = useHttps();
   const { addToast } = useToasts();

   const config = {
      url: "password/reset",
      method: "post",
      body: {
         email: value,
      },
   };

   const loadingFunction = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   const successCallback = () =>
      addToast("A reset link has been sent to your email", {
         appearance: "success",
         autoDismiss: true,
      });

   const submitResetPassword = (event) => {
      event.preventDefault();
      if (!valueIsValid) {
         return;
      }
      sendRequest(config, successCallback, loadingFunction, null);
   };

   return (
      <OnboardingWrapperContainer>
         <Container className={classes.container}>
            <>
               <div className={classes.imgContainer}>
                  <Logo />
               </div>
               <div className={classes.info}>
                  <h2>Forgot Password?</h2>
                  <p>
                     Enter the email address you used when you joined and we’ll
                     send you instructions to reset your password.
                  </p>
               </div>

               <form onSubmit={submitResetPassword}>
                  <div
                     className={`${classes.formGroup} ${
                        hasError && classes.error
                     }`}
                  >
                     <label htmlFor="">Email Address</label>
                     <input
                        type="email"
                        className={classes.formControl}
                        onChange={valueInputChange}
                        onBlur={inputBlurHandler}
                        value={value}
                     />
                  </div>
                  <InterestButton
                     isValid={valueIsValid}
                     confirmSelection={submitResetPassword}
                     className={classes.btn}
                  >
                     Send Reset Instructions
                  </InterestButton>
               </form>
               <p className={classes.signup}>
                  Not registered? <Link to={`/${REGISTER}`}>Signup now</Link>{" "}
               </p>
            </>
         </Container>
      </OnboardingWrapperContainer>
   );
};

export default ForgotPassword;
