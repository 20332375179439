import React from "react";
import { useSelector } from "react-redux";
import useAutoGeneratedFeedback from "../../../../../../hooks/useAutoGeneratedFeedback";
import DirectionCard from "../directionCard/DirectionCard";
import classes from "./ProblemDey.module.css";

const ProblemDey = () => {
   const {
      autoGeneratedFeedback: { autoGeneratedFeedback },
   } = useSelector((state) => state);

   const { showFeedbackComponent } = useAutoGeneratedFeedback();

   return (
      <>
         <div className={classes.container}>
            <p>Flag the wrong direction?</p>
            <div className={classes.directionCardContainer}>
               {autoGeneratedFeedback &&
                  autoGeneratedFeedback.result.map((data) => (
                     <DirectionCard key={data.coord} result={data} />
                  ))}
            </div>
            <div className={classes.cta}>
               <button onClick={showFeedbackComponent}>Next</button>
            </div>
         </div>
      </>
   );
};

export default ProblemDey;
