import React from "react";
import useAutoGeneratedFeedback from "../../../hooks/useAutoGeneratedFeedback";
import classes from "./AutoGeneratedFeedback.module.css";
import PropTypes from "prop-types";
import useElementRefs from "../../../hooks/useElementRefs";

const AutoGeneratedFeedbackSelect = ({ places, ulClassName }) => {
   const { toggleSelecteedAUtoGeneratedFeedback, isChecked } =
      useAutoGeneratedFeedback();

   const { elementRefs } = useElementRefs({
      places,
   });

   return (
      <div className={classes.preferenceContainer}>
         <ul className={ulClassName}>
            {places.length > 0 &&
               places.map((place, index) => (
                  <li
                     className={`${classes.preference} ${classes.li} ${
                        isChecked(place.stop_id) ? classes.active : ""
                     }`}
                     key={place.stop_id}
                     ref={elementRefs[index]}
                     onClick={() =>
                        toggleSelecteedAUtoGeneratedFeedback(place.stop_id)
                     }
                  >
                     {place.lga}
                  </li>
               ))}
         </ul>
      </div>
   );
};

AutoGeneratedFeedbackSelect.propTypes = {
   places: PropTypes.array.isRequired,
   ulClassName: PropTypes.string,
};

export default AutoGeneratedFeedbackSelect;
