import React from "react";
import { useSelector } from "react-redux";
import useAutoGeneratedFeedback from "../../../hooks/useAutoGeneratedFeedback";
import { capitalize, getAvailableModes } from "../../../Utilities/helper";
import DirectionLoader from "../DirectionLoader/DirectionLoader";
import DirectionsContainerWrapper from "../Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./NewModeDesign.module.css";

const NewModeDesign = () => {
   const {
      autoGeneratedFeedback: {
         autoGeneratedModePreCheck,
         autoGeneratedDirectionInformation,
      },
      directions: { loading },
   } = useSelector((state) => state);

   const { modes, state, directions_from, directions_to } = getAvailableModes(
      autoGeneratedModePreCheck
   );

   const { selectMode, postSelectedLga } = useAutoGeneratedFeedback();

   return (
      <>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <p>You’re helping Lara test</p>
               <h2>
                  Directions from {directions_from} to {directions_to}{" "}
               </h2>
               <span>Please select a vehicle mode to continue.</span>

               <div className={classes.modalContainer}>
                  {modes?.length > 0 &&
                     modes.map((mode, index) => (
                        <div
                           key={index}
                           className={`${classes.mode}`}
                           onClick={() => selectMode(mode[0], state)}
                        >
                           <img
                              src={`/assets/images/modes/${capitalize(
                                 state
                              )}/${mode[0].toUpperCase()}.svg`}
                              alt={`${mode[0]} icon`}
                           />
                           <p
                              className={
                                 autoGeneratedDirectionInformation?.mode ===
                                 mode[0]
                                    ? classes.active
                                    : ""
                              }
                           >
                              {capitalize(mode[0])}
                           </p>
                        </div>
                     ))}
               </div>
            </div>
         </DirectionsContainerWrapper>
         {loading && <DirectionLoader />}
         <div className={classes.btnContainer}>
            <button onClick={postSelectedLga}>No idea, Skip</button>
         </div>
      </>
   );
};

export default NewModeDesign;
