import React from "react";
import classes from "./genericButton.module.css";
import PropTypes from "prop-types";
import useTask from "../../../hooks/useTask";
import { useDispatch } from "react-redux";
import { utilitiesActions } from "../../../redux/utilitiesSlice";
import { saveTripActions } from "../../../redux/saveTripSlice";

const GenericButton = ({ ButtonText, className, click }) => {
   const { choseAppropriateTask } = useTask();
   const dispatch = useDispatch();

   const onclick = () => {
      choseAppropriateTask(1);
      dispatch(utilitiesActions.setFromWidget(false));
      dispatch(saveTripActions.reset());
   };
   return (
      <div className={classes.skipButtonContainer}>
         <button
            onClick={click ?? onclick}
            className={`${classes.skipButton} ${className}`}
         >
            {ButtonText}
         </button>
      </div>
   );
};

GenericButton.propTypes = {
   ButtonText: PropTypes.string.isRequired,
   className: PropTypes.any,
   click: PropTypes.func,
};
export default GenericButton;
