import React from "react";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import DownloadLaraTask from "./DownloadLaraTask";

const DownloadLaraTaskContainer = () => {
   return (
      <>
         <DirectionsContainerWrapper>
            <DownloadLaraTask />
         </DirectionsContainerWrapper>

         <GenericButton ButtonText="Back to directions" />
      </>
   );
};

export default DownloadLaraTaskContainer;
