/**
 * This function is used to get the directions from the server.
 * @returns The function that sends the request to the server.
 */
import { useDispatch, useSelector } from "react-redux";
import { directionActions } from "../redux/directionsSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import useHttps from "./useHttps";

const { setLoading, setDirectionsData } = directionActions;

const useDirection = () => {
   const {
      directions: {
         routeInformation: {
            toText,
            toLat,
            toLong,
            fromLat,
            fromLong,
            fromText,
            mode,
         },
      },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const setErrorState = (error) => {
      dispatch(
         utilitiesActions.setError({
            errorText: error.message,
            isError: true,
            errorButtonText: "Try a new search",
            errorFunctionType: "MODAL",
         })
      );
   };

   const sendMode = mode ? `${mode}` : "pre-check";
   const { sendRequest } = useHttps();

   const url =
      `search/route?uid=aytgday6&lat_to=${toLat}&lng_to=${toLong}&from=${fromText}&to=${toText}&lat=${fromLat}&lng=${fromLong}&mode=${sendMode}`.trim();

   const config = {
      url,
      method: "get",
   };

   const loadingCallback = (value) => dispatch(setLoading(value));

   const getDirectionFromServer = (successCallback) => {
      dispatch(setDirectionsData({}));
      sendRequest(
         config,
         successCallback,
         loadingCallback,
         null,
         setErrorState,
         null,
         null
      );
   };

   return getDirectionFromServer;
};

export default useDirection;
