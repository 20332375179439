import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   singleTrip: {},
   saveTripOptions: {
      showSaveTrip: true,
      showTripSelection: false,
      giveTripName: false,
      tripSaved: false,
      showSaveTripsList: false,
   },
   userTrips: [],
   savedTrips: [],
   startSaveTrip: false,
};

const saveTripSlice = createSlice({
   name: "saveTrip",
   initialState,
   reducers: {
      setSingleTrip: (state, action) => {
         state.singleTrip = action.payload;
      },
      selectSaveTripOptions: (state, action) => {
         for (let key in state.saveTripOptions) {
            state.saveTripOptions[key] = false;
         }
         state.saveTripOptions[action.payload] = true;
      },
      showUserTrips: (state, action) => {
         state.userTrips = action.payload;
      },
      setSavedTrips: (state, action) => {
         state.savedTrips = action.payload;
      },
      setStartSaveTrip: (state, action) => {
         state.startSaveTrip = action.payload;
      },
      reset() {
         return initialState;
      },
   },
});

export const saveTripActions = saveTripSlice.actions;
export const saveTripReducer = saveTripSlice.reducer;

export default saveTripSlice;
