import React from "react";
import { useDispatch } from "react-redux";
import useMaps from "../../../../hooks/useMaps";
import { directionActions } from "../../../../redux/directionsSlice";
import { TO_DIRECTION } from "../../../../Utilities/mapTags";
import { ADD_TO_DATA } from "../../../../Utilities/types";
import SearchInput from "../../../components/SearchInput/SearchInput";
import PoiData from "../../../utilities/Preferences/PoiData";
import classes from "../funPlacesNearMe/FunPlaces.module.css";

const InterState = () => {
   const dispatch = useDispatch();

   const { setMapTag } = useMaps();

   const onSubmitFunction = (selectedList) => {
      const { lat, lng, stop_name } = selectedList;
      dispatch(
         directionActions.updateRouteInformation({
            toText: stop_name,
            toLat: lat,
            toLong: lng,
         })
      );
   };

   return (
      <div className={classes.container}>
         <h2>Let’s go a little farther</h2>

         <p>Try Directions to another state</p>

         <div className={classes.funPlaces}>
            <PoiData text="Here are some suggestions" poiData={[]} />
         </div>

         <SearchInput
            className={classes.input}
            placeholder={"Try your own searches"}
            onSubmitFunction={onSubmitFunction}
            type={ADD_TO_DATA}
            onFocus={() => setMapTag(TO_DIRECTION)}
         />
      </div>
   );
};

export default InterState;
