/**
 * If the user is coming from a shared link, we check if the user is already registered. If not, we
check if the user is already invited. If not, we check if the user is already shared.
 * @returns The function that checks if the invite token is valid.
 */
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { onboardingActions } from "../redux/onboardingSlice";
import { ABOUT } from "../routes/Path";
import useHttps from "./useHttps";

const useShareBeta = () => {
   const { search } = useLocation();
   const { sendRequest } = useHttps();
   const history = useHistory();
   const dispatch = useDispatch();

   const queryParams = new URLSearchParams(search);
   const invite_token = queryParams.get("lara_share_token");

   const setInviteCallback = (data) => {
      const { data: responseData, status } = data;

      if (!status) {
         return dispatch(onboardingActions.checkIsShared(true));
      }
      dispatch(onboardingActions.setInviteeData(responseData));
      const inviteeSplitedName = responseData.invitee_name.split(" ");

      dispatch(
         onboardingActions.addToUserData({
            name: responseData.invitee_name,
            firstName: inviteeSplitedName[0],
            lastName: inviteeSplitedName[1],
            referral_token: invite_token,
         })
      );
      history.push(`/${ABOUT}`);
   };

   const errorfunc = () => {
      dispatch(onboardingActions.checkIsShared(true));
   };

   const getInviteInfo = useCallback(() => {
      sendRequest(
         {
            url: `invite/token?token=${invite_token}`,
            method: "get",
         },
         setInviteCallback,
         null,
         null,
         errorfunc
      );
   }, [invite_token]);

   const checkInvite = () => {
      setTimeout(() => {
         invite_token
            ? getInviteInfo()
            : dispatch(onboardingActions.checkIsShared(true));
      }, 2000);
   };

   return { checkInvite };
};

export default useShareBeta;
