import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import usePoi from "../../../hooks/usePoi";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import styles from "../taskpreviews/missingPois/MissingPois.module.css";
import MissingPoiForm from "./MissingPoiForm";
import classes from "./MissingPoiTask.module.css";

const MissingPoiTask = () => {
   const { onShowPoiForm, showMissingFeedbackForm, cityData, getCities } =
      usePoi();
   const {
      feedback: { poiFormData },
   } = useSelector((state) => state);

   useEffect(() => {
      getCities(false);
   }, []);

   const headerElement = () => (
      <div className={styles.container}>
         <p>Thank you for helping Lara</p>
         <h2>Add a missing POI/Street Data</h2>
         <img height="30px" src={iconImages.destinationIconImg} alt="" />
         <div className={styles.buttonContainer}>
            <button onClick={() => onShowPoiForm("PLACE")}>Add Place</button>
            <button onClick={() => onShowPoiForm("STREET")}>
               Add Missing Street
            </button>
         </div>
      </div>
   );
   return (
      <>
         <DirectionsContainerWrapper>
            {!showMissingFeedbackForm ? (
               <div className={classes.container}>{headerElement()}</div>
            ) : (
               <MissingPoiForm cityData={cityData} formData={poiFormData} />
            )}
         </DirectionsContainerWrapper>
         <GenericButton ButtonText="skip to directions" />
      </>
   );
};

export default MissingPoiTask;
