import React from "react";
import { iconImages } from "../../../assets";
import classes from "./LaraLoader.module.css";

const LaraLoader = () => {
   return (
      <div className={classes.container}>
         <lottie-player
            src={"https://assets8.lottiefiles.com/packages/lf20_ttrvterq.json"}
            background="transparent"
            speed="1"
            style={{ width: "300px", height: "250px" }}
            loop
            autoplay
         ></lottie-player>
         <img height="50px" src={iconImages.laraLogoText} />
      </div>
   );
};

export default LaraLoader;
