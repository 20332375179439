import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useTask from "../../../hooks/useTask";
import { feedbackActions } from "../../../redux/feedbackSlice";
import ratingInfo from "../../../Utilities/ratingInfo";
import FeedbackTextArea from "../FeedbackTextArea/FeedbackTextArea";
import classes from "./RatingFeedback.module.css";

const { setRatingInfo, setFeedbackInfo } = feedbackActions;

const RatingFeedback = () => {
   const {
      feedback: { ratingInformation },
      tasks: { maximize },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const [showFeedbackText, setShowFeedbackText] = useState(false);
   const [feelingText, setFeelingText] = useState(
      ratingInformation.description ?? "Tell us how you’re feeling"
   );

   const { onMaximize } = useTask();

   const ratingImgUrl = (ratingArrayElement) =>
      ratingArrayElement.id === ratingInformation.id
         ? ratingInformation.lightImgUrl
         : ratingArrayElement.darkImgUrl;

   const rateSmileyElement = () =>
      ratingInfo.map((ratingEL) => (
         <img
            key={ratingEL.id}
            src={ratingImgUrl(ratingEL)}
            alt=""
            onClick={() => onImageClick(ratingEL.id)}
         />
      ));

   const onImageClick = (index) => {
      const rating = ratingInfo.find((element) => element.id === index);
      index <= 4 ? setShowFeedbackText(true) : setShowFeedbackText(false);
      setFeelingText(rating.description);
      onMaximize();

      dispatch(setRatingInfo(rating));
      dispatch(
         setFeedbackInfo({
            id: rating.id,
            text: rating.description,
            typed_text: rating.description,
         })
      );
   };

   return (
      <>
         <div className={classes.rateSmileyContainer}>
            {rateSmileyElement()}
         </div>

         {maximize && !showFeedbackText && (
            <p className={classes.description}>{feelingText}</p>
         )}

         {showFeedbackText && (
            <>
               <div className={classes.feedbacPlaceHolder}>
                  <h4>What was wrong ?</h4>
                  <p>e.g. Price is now #50</p>
                  <p>Danfo no longer exists, only keke,</p>
                  <p>Correct Direction is from A Bus Stop to B Bus Stop,</p>
               </div>
               <FeedbackTextArea />
            </>
         )}
      </>
   );
};

export default RatingFeedback;
