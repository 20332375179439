import { AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import {
   Redirect,
   Route,
   Switch,
   useLocation,
   useRouteMatch,
} from "react-router-dom";
import useSetRouteLogic from "../hooks/useSetRouteLogic";
import OnboardingWrapperContainer from "../views/components/Wrappers/OnboardingWrapperContainer/OnboardingWrapperContainer";
import ForgotPassword from "../views/pages/forgotPassword/ForgotPassword";
import ContinuetoEmail from "../views/pages/onboarding/EmailVerification/ContinuetoEmail/ContinuetoEmail";
import VerifyEmail from "../views/pages/onboarding/EmailVerification/VerifyEmail/VerifyEmail";
import Enterpassword from "../views/pages/onboarding/Enterpassword/Enterpassword";
import OnboardingAbout from "../views/pages/onboarding/OnboardingAbout/OnboardingAbout";
import OnboardingHome from "../views/pages/onboarding/OnboardingHome/OnboardingHome";
import OnboardingLogin from "../views/pages/onboarding/OnboardingLogin/OnboardingLogin";
import OnboardingSignup from "../views/pages/onboarding/OnboardingSignup/OnboardingSignup";
import SocialSignup from "../views/pages/onboarding/SocialSignup/SocialSignup";
import UpdatePassword from "../views/pages/updatePassword/UpdatePassword";
import {
   ABOUT,
   CONTINUE_TO_EMAIL,
   ENTER_PASSWORD,
   FORGET_PASSWORD,
   LOGIN,
   REGISTER,
   SOCIAL_LOGIN,
   UPDATE_PASSWORD,
   VERIFY_EMAIL,
} from "./Path";

const OnboardingRoutes = () => {
   const match = useRouteMatch();
   const location = useLocation();
   const { redirectToDashboard } = useSetRouteLogic();

   useEffect(() => {
      redirectToDashboard();
   }, [redirectToDashboard]);
   return (
      <OnboardingWrapperContainer>
         <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.key}>
               <Route path={`${match.path}`} component={OnboardingHome} exact />
               <Route
                  path={`${match.path}${REGISTER}`}
                  component={OnboardingSignup}
                  exact
               />

               <Route
                  path={`${match.path}${ABOUT}`}
                  component={OnboardingAbout}
                  exact
               />
               <Route
                  path={`${match.path}${ENTER_PASSWORD}`}
                  component={Enterpassword}
                  exact
               />
               <Route
                  path={`${match.path}${SOCIAL_LOGIN}/:string`}
                  component={SocialSignup}
                  exact
               />
               <Route
                  path={`${match.path}${CONTINUE_TO_EMAIL}`}
                  component={ContinuetoEmail}
                  exact
               />
               <Route
                  path={`${match.path}${VERIFY_EMAIL}`}
                  component={VerifyEmail}
                  exact
               />
               <Route path={`/${LOGIN}`} component={OnboardingLogin} exact />
               <Route
                  path={`/${UPDATE_PASSWORD}`}
                  component={UpdatePassword}
                  exact
               />
               <Route
                  component={ForgotPassword}
                  path={`/${FORGET_PASSWORD}`}
                  exact
               />

               <Route path={`${match.path}*`}>
                  <Redirect to={`${match.path}`} />
               </Route>
            </Switch>
         </AnimatePresence>
      </OnboardingWrapperContainer>
   );
};

export default OnboardingRoutes;
