import axios from "axios";
import { useCallback, useState } from "react";
import { useToasts } from "react-toast-notifications";

/*
It takes in a request object, and returns a function that can be used to send the request.

Args:
  None
Returns:
  The object containing the sendRequest function.
*/
const useHttps = () => {
   const [cancelRequest, setCancelRequest] = useState(true);
   const { addToast } = useToasts();
   const sendRequest = useCallback(
      async (
         { url, method, headers, body, isSearch },
         callback,
         LoadingStateFunction,
         successCallback,
         errorStateFunction
      ) => {
         LoadingStateFunction && LoadingStateFunction(true);
         /* eslint-disable no-undef */
         const apiUrl = `${process.env.REACT_APP_API}${url}`;

         const config = {
            url: apiUrl,
            method,
            data: body,
            headers: {
               ...headers,
               "Content-Type": "application/json",
            },
         };

         try {
            const response = await axios(config);
            setCancelRequest(false);
            callback(response.data);
            successCallback && successCallback();
            LoadingStateFunction && LoadingStateFunction(false);
         } catch (err) {
            LoadingStateFunction && LoadingStateFunction(false);
            setCancelRequest(false);
          
            !isSearch &&
               addToast(
                  err?.response?.data?.message ?? "Something went wrong",
                  {
                     appearance: "error",
                     autoDismiss: true,
                  }
               );
            errorStateFunction &&
               errorStateFunction(
                  err?.response?.data ?? "something went wrong"
               );
         }
      },
      []
   );

   return {
      sendRequest,
      cancelRequest,
      setCancelRequest,
   };
};

export default useHttps;
