import React from "react";
import { iconImages } from "../../../assets";
import FacebookSocialLogin from "../../../socialLogins/FacebookSocilaLogin";
import PropTypes from "prop-types";
import useAppleSocialLogin from "../../../socialLogins/AppleSocialLogin";
import classes from "./SocialLogin.module.css";

const SocialLogin = (props) => {
   const {
      className,
      googleClickHandler,
      appleClickHandler,
      facebookClickHandler,
      login,
      signup,
   } = props;

   const facebookElement = (clickHandler) => (
      <li
         className={classes.li}
         onClick={!login ? facebookClickHandler : clickHandler}
      >
         <img src={iconImages.facebookIConImg} alt="" />
      </li>
   );

   const { isApple, appleClickHandler: getAppleLoginInfo } =
      useAppleSocialLogin();

   return (
      <ul className={className}>
         <li onClick={googleClickHandler} className={classes.li}>
            {" "}
            <img src={iconImages.googleIconImg} alt="" />
         </li>
         {isApple && (
            <li
               onClick={
                  appleClickHandler ? appleClickHandler : getAppleLoginInfo
               }
               className={classes.li}
            >
               <img
                  src={
                     signup
                        ? iconImages.appleIosIconImg
                        : iconImages.appleIconImg
                  }
                  alt=""
               />
            </li>
         )}
         <FacebookSocialLogin facebookElement={facebookElement} />
      </ul>
   );
};

SocialLogin.propTypes = {
   className: PropTypes.string,
   googleClickHandler: PropTypes.func,
   appleClickHandler: PropTypes.func,
   facebookClickHandler: PropTypes.func,
   login: PropTypes.bool,
   signup: PropTypes.bool,
};

export default SocialLogin;
