/**
 * This function is used to handle all the user authentication related tasks.
 * @param sendRequest - The function that sends the request to the server.
 * @returns The useAuth hook returns an object with the following properties:
 */
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { utilitiesActions } from "../redux/utilitiesSlice";
import { CONTINUE_TO_EMAIL, DASHBOARD, USER } from "../routes/Path";
import { getit, setit } from "../Utilities/helper";

const useAuth = (sendRequest) => {
   const history = useHistory();
   const dispatch = useDispatch();

   const {
      onboarding: { userData },
   } = useSelector((state) => state);
   const { firstName, name, email, referral_token } = userData;

   const loginSuccess = useCallback(
      ({ data: { token } }) => {
         setit("lara_token", token, 360);
         history.push(`/${USER}/${DASHBOARD}`);
      },
      [setit]
   );

   const logout = () => {
      setit("lara_token", "", 0);
      window.location.reload();
   };

   const setIsLogin = () => {
      const token = getit("lara_token");
      return token && dispatch(utilitiesActions.setIsloggedin(true));
   };

   const LoadingStateFunction = (value) => {
      dispatch(utilitiesActions.setNormalLoading(value));
   };

   const loginErrorStateFunction = (error) => {
      dispatch(
         utilitiesActions.setError({
            errorText: error.message,
            isError: true,
            errorFunctionType: "LOGIN",
            errorButtonText: "Try Again",
         })
      );
   };

   const loginUser = ({ email, password }) => {
      const config = {
         url: "auth/login",
         method: "post",
         body: {
            email,
            password,
         },
      };
      sendRequest(config, loginSuccess, LoadingStateFunction, null);
   };

   const createUserSuccess = () => {
      history.push(`/${CONTINUE_TO_EMAIL}`);
   };

   const createUser = useCallback(
      ({ password }) => {
         const body = {
            email: email.trim(),
            name: `${name.trim()}`,
            password: password.trim(),
         };

         referral_token && (body.referral_token = referral_token);

         const config = {
            url: "account",
            method: "post",
            body,
         };

         sendRequest(
            config,
            createUserSuccess,
            LoadingStateFunction,
            null,
            null,
            null
         );
      },
      [userData, sendRequest]
   );

   return {
      loginSuccess,
      logout,
      setIsLogin,
      loginUser,
      firstName,
      createUser,
      loginErrorStateFunction,
      LoadingStateFunction,
   };
};

export default useAuth;
