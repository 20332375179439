import React, { useEffect, useState } from "react";
import usePoi from "../../../hooks/usePoi";
import classes from "./MissingPoiTask.module.css";
import { useDispatch, useSelector } from "react-redux";
import useInput from "../../../hooks/useInput";
import { iconImages, POIs } from "../../../assets";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { LARAMAPS, USER } from "../../../routes/Path";
import { feedbackActions } from "../../../redux/feedbackSlice";
import validators from "../../../Utilities/inputValidator";
import useMaps from "../../../hooks/useMaps";
import { MISSING_POI } from "../../../Utilities/mapTags";

const MissingPoiForm = ({ formData, cityData }) => {
   const {
      user: { userInformation: userInfo },
      directions: { stopByLga },
      feedback: { missingPoiFeedback },
   } = useSelector((state) => state);

   const cityInfo = missingPoiFeedback?.city ?? userInfo?.city;
   const [city, setCity] = useState(cityInfo ?? "");
   const [lga, setLga] = useState(missingPoiFeedback?.lga ?? {});
   const { getLgaPercity } = usePoi();
   const history = useHistory();
   const dispatch = useDispatch();
   const { setMapTag } = useMaps();

   useEffect(() => {
      let idenifier = setTimeout(() => {
         getLgaPercity(city);
         idenifier = null;
      }, 1);

      return () => {
         clearTimeout(idenifier);
      };
   }, [city]);

   const {
      value: name,
      valueInputChange: nameInputChange,
      inputBlurHandler: nameBlurHandler,
   } = useInput(validators.checkValueIsEmpty, missingPoiFeedback?.name ?? "");

   const {
      value: address,
      valueInputChange: addressInputChange,
      inputBlurHandler: addressBlurHandler,
   } = useInput(
      validators.checkValueIsEmpty,
      missingPoiFeedback?.address ?? ""
   );

   const citySelectOnchange = (e) => {
      setCity(e.target.value);
   };

   const lgaOnChange = (e) => {
      const lga = stopByLga.find((lga) => lga.lga === e.target.value);
      setLga(lga);
   };

   const formisValid = name && address && city && lga?.lga;

   const redirectToMaps = () => {
      setMapTag(MISSING_POI);
      dispatch(
         feedbackActions.setMissingPoiFeedback({ name, address, city, lga })
      );
      history.push(`/${USER}/${LARAMAPS}`);
   };

   return (
      <div className={classes.fullContainer}>
         <h2>
            {formData.title} &nbsp;
            <img className={classes.missingpoi} src={formData.icon} />
         </h2>

         <div className={classes.formContainer}>
            <div className={classes.formControl}>
               <label htmlFor="">{formData.nameLabel}</label>
               <input
                  type="text"
                  value={name}
                  onChange={nameInputChange}
                  onBlur={nameBlurHandler}
               />
               <img src={POIs.addPOIIcon} alt="" />
            </div>

            <div className={classes.formGroup}>
               <div className={classes.formControl}>
                  <label htmlFor="">{formData.cityLabel}</label>
                  <select
                     value={city}
                     name="city"
                     onChange={citySelectOnchange}
                  >
                     <option value="">Select city</option>
                     {cityData.map((city) => (
                        <option key={city.id} value={city.name}>
                           {city.name}
                        </option>
                     ))}
                  </select>
               </div>
               <div className={classes.formControl}>
                  <label htmlFor="">{formData.lgaLabel}</label>
                  <select
                     name="lga"
                     value={missingPoiFeedback?.lga?.lga ?? lga?.lga ?? ""}
                     onChange={lgaOnChange}
                     placeholder="Select Lga"
                  >
                     <option value="">Select lga</option>
                     {stopByLga.map((lga) => (
                        <option key={lga.stop_id} value={lga.lga}>
                           {lga.lga}
                        </option>
                     ))}
                  </select>
               </div>
            </div>
            <div className={classes.formControl}>
               <label htmlFor="">{formData.fullAddressLabel}</label>
               <input
                  type="text"
                  value={address}
                  onChange={addressInputChange}
                  onBlur={addressBlurHandler}
                  placeholder={
                     formData.tag === "PLACE"
                        ? ""
                        : "Enter a major & nearby street"
                  }
                  className={classes.location}
               />
               <img src={POIs.addPOIIcon} alt="" />
            </div>
         </div>
         <div className={classes.locationContainer}>
            <button
               disabled={!formisValid}
               className={classes.setLocationOnMap}
               onClick={redirectToMaps}
            >
               <img src={iconImages.setLocationOnMapImg} alt="" />
               <p>Set location on map</p>
            </button>
         </div>
      </div>
   );
};

MissingPoiForm.propTypes = {
   formData: PropTypes.object,
   cityData: PropTypes.array,
};

export default MissingPoiForm;
