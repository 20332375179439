import React from 'react';
import WhatsYourName from '../../../components/WhatsYourName/WhatsYourName';
import Container from '../../../components/Wrappers/container/Container';
import Logo from '../../../utilities/Logo/Logo';
import classes from './OnboardingSignup.module.css';

const OnboardingSignup = () => {
	return (
		<Container className={classes.container}>
			<>
				<div className={classes.imgContainer}>
					<Logo />
				</div>
				<WhatsYourName />
			</>
		</Container>
	);
};

export default OnboardingSignup;
