import React from "react";
import classes from "./SaveTripsList.module.css";
import DirectionsContainerWrapper from "../../../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import { useSelector } from "react-redux";
import { capitalize, dateFunc } from "../../../../../Utilities/helper";
import GenericButton from "../../../../components/genericButton/GenericButton";

const SaveTripsList = () => {
   const {
      saveTrip: { savedTrips },
   } = useSelector((state) => state);

   return (
      <div className={classes.SelectTrip}>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <h2>My Saved trips</h2>
               <p>Here are all your saved trips</p>

               <div className={classes.tripList}>
                  {savedTrips.length > 0 &&
                     savedTrips.map((trip) => (
                        <div
                           key={trip.search_id}
                           className={classes.singleTrip}
                        >
                           <div className={classes.icon}>
                              {" "}
                              <img
                                 src={`/assets/images/modes/${capitalize(
                                    trip.state
                                 )}/${trip.mode.toUpperCase()}.svg`}
                                 alt=""
                                 height={"35px"}
                              />{" "}
                           </div>
                           <div className={classes.trip}>
                              <p>
                                 {trip.search_from} - {trip.search_to}{" "}
                              </p>
                              <span>
                                 {capitalize(trip.mode)},{" "}
                                 {capitalize(trip.state)} -{" "}
                                 {dateFunc(trip.date)}
                              </span>
                           </div>
                        </div>
                     ))}
               </div>
            </div>
         </DirectionsContainerWrapper>

         <GenericButton ButtonText="Get directions" />
      </div>
   );
};

export default SaveTripsList;
