import React from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import Logo from "../../utilities/Logo/Logo";
import classes from "./Feedbackthanks.module.css";

const Feedbackthanks = () => {
   const {
      user: { userInformation },
   } = useSelector((state) => state);
   return (
      <div className={classes.container}>
         <div className={classes.logoContainer}>
            <Logo />
         </div>
         <div className={classes.name}>
            <p>{userInformation.firstName}</p>
         </div>

         <div className={classes.feedbackText}>
            <img src={iconImages.emeraldCheckIconImg} alt="" />
            <p>Thanks for the feedback</p>
         </div>
      </div>
   );
};

export default Feedbackthanks;
