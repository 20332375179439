import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: {} };

const newcitySlice = createSlice({
   name: "newcity",
   initialState,
   reducers: {
      addToWaitingList(state, action) {
         state.data = { ...state.data, ...action.payload };
      },
      clearData(state) {
         state.data = {};
      },
   },
});

export const newcityActions = newcitySlice.actions;
export const newcityReducer = newcitySlice.reducer;

export default newcitySlice;
