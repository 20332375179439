/**
 * Cannot generate summary
 * @returns The request object.
 */
import axios from "axios";
import { getit, setit } from "../Utilities/helper";

const authInterceptor = () => {
   axios.interceptors.request.use(
      (request) => {
         const token = getit("lara_token");
         if (token) {
            request.headers.Authorization = `Bearer ${token}`;
         }
         return request;
      },
      (error) => {
         return Promise.reject(error);
      }
   );
};

const errorInterceptor = () => {
   axios.interceptors.response.use(
      (response) => response,
      async (err) => {
         if (err.response.status === 401) {
            setit("lara_token", "", 360);
         }

         return Promise.reject(err);
      }
   );
};

export const interceptors = {
   authInterceptor,
   errorInterceptor,
};
