import React from 'react';
import DirectionContainerHeader from '../DirectionContainerHeader/DirectionContainerHeader';
import DirectionsContainerWrapper from '../Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper';

const DirectionsContainer = () => {
   return (
      <DirectionsContainerWrapper>
         <DirectionContainerHeader />
      </DirectionsContainerWrapper>
   );
};

export default DirectionsContainer;
