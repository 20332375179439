import React from 'react';
import { iconImages } from '../../../assets';
import classes from './Logo.module.css';

const Logo = () => {
	return (
		<>
			<img className={classes.img} src={iconImages.logo} alt='' />
		</>
	);
};

export default Logo;
