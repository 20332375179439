import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useShareBeta from "../../../../hooks/useShareBeta";
import { onboardingActions } from "../../../../redux/onboardingSlice";
import LoginSignupOption from "../../../components/LoginSignupOption/LoginSignupOption";
import OnboardingVideoContainer from "../../../components/VideoContainer/OnboardingVideoContainer";

const OnboardingHome = () => {
   const dispatch = useDispatch();

   const {
      onboarding: { isShared },
   } = useSelector((state) => state);
   const { checkInvite } = useShareBeta();

   useEffect(() => {
      dispatch(onboardingActions.checkIsShared(false));
      checkInvite();
   }, [dispatch]);

   return (
      <>
         <OnboardingVideoContainer />

         {isShared && <LoginSignupOption />}
      </>
   );
};

export default OnboardingHome;
