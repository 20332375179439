import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   userInformation: {},
   updateUserInformation: {},
   interestsInfo: [],
   homeInfo: {},
   workInfo: {},
};

const userSlice = createSlice({
   initialState,
   name: "User",
   reducers: {
      setUserInformation(state, action) {
         state.userInformation = { ...action.payload };
      },
      updateUserInformation(state, action) {
         state.updateUserInformation = {
            ...state.updateUserInformation,
            ...action.payload,
         };
      },
      setEditedHomeInfo(state, action) {
         state.homeInfo = action.payload;
      },
      setEditedWorkInfo(state, action) {
         state.workInfo = action.payload;
      },
      setInterestsInfo(state, action) {
         state.interestsInfo = action.payload;
      },
   },
});

export const userSliceActions = userSlice.actions;
export const userSliceReducers = userSlice.reducer;

export default userSlice;
