import { iconImages } from "../assets";

const ratingInfo = [
   {
      id: 1,
      darkImgUrl: iconImages.greyRatingOne,
      lightImgUrl: iconImages.colouredRatingOne,
      description: "How are you feeling",
   },
   {
      id: 2,
      darkImgUrl: iconImages.greyRatingTwo,
      lightImgUrl: iconImages.colouredRatingTwo,
      description: "How are you feeling",
   },
   {
      id: 3,
      darkImgUrl: iconImages.greyRatingThree,
      lightImgUrl: iconImages.colouredRatingThree,
      description: "How are you feeling",
   },
   {
      id: 4,
      darkImgUrl: iconImages.greyRatingFour,
      lightImgUrl: iconImages.colouredRatingFour,
      description: "These directions are on point👌🏽",
   },
   {
      id: 5,
      darkImgUrl: iconImages.greyRatingFive,
      lightImgUrl: iconImages.colouredRatingFive,
      description: "Excellent",
   },
];

export default ratingInfo;
