import { configureStore } from "@reduxjs/toolkit";
import { autoGeneratedFeedbackReducer } from "./autoGeneratedFeedbackSlice";
import { directionReducer } from "./directionsSlice";
import { feedbackReducer } from "./feedbackSlice";
import { httpReducer } from "./httpSlice";
import { inviteSliceReducers } from "./inviteSlice";
import { newcityReducer } from "./newcitySlice";
import { onboardingReducer } from "./onboardingSlice";
import { rewardsReducer } from "./rewardsSlice";
import { saveTripReducer } from "./saveTripSlice";
import { tasksReducer } from "./tasksSlice";
import { userNotificationReducer } from "./userNotificationSlice";
import { userSliceReducers } from "./userSlice";
import { utilitiesReducer } from "./utilitiesSlice";

const store = configureStore({
   reducer: {
      onboarding: onboardingReducer,
      newcity: newcityReducer,
      https: httpReducer,
      directions: directionReducer,
      tasks: tasksReducer,
      utilities: utilitiesReducer,
      feedback: feedbackReducer,
      user: userSliceReducers,
      notification: userNotificationReducer,
      autoGeneratedFeedback: autoGeneratedFeedbackReducer,
      rewards: rewardsReducer,
      saveTrip: saveTripReducer,
      invite: inviteSliceReducers,
   },
});

export default store;
