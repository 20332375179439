import { iconImages, socials } from "../assets/index";

export const widgets = [
   {
      id: 2,
      name: "whatsapp",
      className: "fab fa-whatsapp",
      imgUrl: iconImages.whatsappShare,
   },
   {
      id: 5,
      name: "linkedIn",
      className: "fab fa-linkedin-in",
      imgUrl: socials.linkedinWhiteIcon,
   },

   {
      id: 4,
      name: "twitter",
      className: "fab fa-twitter",
      imgUrl: socials.twitterColoredIcon,
   },
   {
      id: 3,
      name: "facebook",
      className: "fab fa-facebook-f",
      imgUrl: socials.facebookWhiteIcon,
   },
   {
      id: 1,
      name: "google",
      className: "fab fa-google",
      imgUrl: iconImages.googleShare,
   },
];

export const socialMediaLinks = [
   {
      id: 4,
      name: "Instagram",
      imgUrl: socials.instagramWhiteIcon,
      link: "https://instagram.com/asklara.ng",
      greyImgUrl: "",
   },
   {
      id: 3,
      name: "Twitter",
      imgUrl: socials.twitterWhiteIcon,
      link: "https://twitter.com/Laradotng",
      greyImgUrl: "",
   },
   {
      id: 2,
      name: "Facebook",
      imgUrl: socials.facebookWhiteIcon,
      link: "https://www.facebook.com/Laradotng",
      greyImgUrl: "",
   },
   {
      id: 5,
      name: "LinkedIn",
      imgUrl: socials.linkedinWhiteIcon,
      link: "https://www.linkedin.com/company/road-preppers-technologies/",
      greyImgUrl: "",
   },
];

export const fullSocialMediaLinks = [
   {
      id: 4,
      name: "Instagram",
      imgUrl: socials.instagramColoredIcon,
      link: "https://instagram.com/asklara.ng",
      greyImgUrl: "",
   },
   {
      id: 3,
      name: "Twitter",
      imgUrl: socials.twitterColoredIcon,
      link: "https://twitter.com/Laradotng",
      greyImgUrl: "",
   },
   {
      id: 2,
      name: "Facebook",
      imgUrl: socials.facebookWhiteIcon,
      link: "https://www.facebook.com/Laradotng",
      greyImgUrl: "",
   },
   {
      id: 5,
      name: "LinkedIn",
      imgUrl: socials.linkedinColoredIcon,
      link: "https://www.linkedin.com/company/road-preppers-technologies/",
      greyImgUrl: "",
   },
];
