import React from "react";
import classes from "./DirectionsContainerWrapper.module.css";
import PropTypes from "prop-types";
import { iconImages } from "../../../../assets";
import { useHistory } from "react-router";
import { TASK, USER } from "../../../../routes/Path";
import { useDispatch } from "react-redux";
import { directionActions } from "../../../../redux/directionsSlice";
import { useSelector } from "react-redux";

const DirectionsContainerWrapper = (props) => {
   const history = useHistory();
   const dispatch = useDispatch();
   const {
      directions: { showFullDirection, directionsData },
      utilities: { fromWidget },
      tasks: { currentUrl },
   } = useSelector((state) => state);
   const backToTaskPage = () => {
      history.push(`/${USER}/${TASK}`);
   };
   const onShowFullDirection = () => {
      dispatch(directionActions.setShowFullDirection(!showFullDirection));
   };
   const showMinimize = () => {
      return !currentUrl.includes("dashboard");
   };
   return (
      <div className={classes.container}>
         <div className={classes.innerContainer}>
            <div className={`${classes.mainContainer} ${props.className}`}>
               {props.children}

               {showMinimize() && !fromWidget && (
                  <>
                     {directionsData?.path?.length > 0 ? (
                        <div className={classes.fullDirection}>
                           <button onClick={onShowFullDirection}>
                              <img
                                 src={iconImages.minimizeCardWithinDirectionImg}
                                 height="30px"
                                 alt=""
                              />
                           </button>
                           <p>
                              {showFullDirection
                                 ? "minimize"
                                 : "See full direction"}
                           </p>
                        </div>
                     ) : (
                        <button
                           onClick={backToTaskPage}
                           className={classes.minimize}
                        >
                           <img
                              src={iconImages.minimizeCardWithinDirectionImg}
                              alt=""
                           />
                           <span>Minimize</span>
                        </button>
                     )}
                  </>
               )}
            </div>
         </div>
      </div>
   );
};

DirectionsContainerWrapper.propTypes = {
   className: PropTypes.string,
   children: PropTypes.element,
};

export default DirectionsContainerWrapper;
