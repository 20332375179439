import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DirectionCardContainer from "../DirectionCardContainer/DirectionCardContainer";
import DirectionLoader from "../DirectionLoader/DirectionLoader";
import DirectionsContainer from "../DirectionsContainer/DirectionsContainer";
import LoginButton from "../LoginButton/LoginButton";
import MinimizedDirection from "../MinimizedDirectionContainer/MinimizedDirection";
import ModePreference from "../ModePreference/ModePreference";

const DirectionLogic = () => {
   const {
      directions: { showModal, directionsData, showFullDirection, loading },
      utilities: { isLoggedIn },
   } = useSelector((state) => state);

   const scrollDown = () => {
      window.scrollTo({
         top: window.innerHeight,
         behavior: "smooth",
      });
   };

   useEffect(() => {
      if (loading) {
         scrollDown();
      }
   }, [loading]);

   return (
      <>
         <>
            {directionsData?.path?.length > 0 ? (
               <MinimizedDirection directionsData={directionsData} />
            ) : (
               <DirectionsContainer />
            )}
         </>
         {showModal && showFullDirection && <ModePreference />}
         {showFullDirection && directionsData?.path?.length > 0 && (
            <DirectionCardContainer />
         )}{" "}
         {!isLoggedIn && <LoginButton />}
         {loading && <DirectionLoader />}
      </>
   );
};

export default DirectionLogic;
