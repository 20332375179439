import { useDispatch, useSelector } from "react-redux";
import { PoiTaskLabel } from "../data/poiTask";
import { directionActions } from "../redux/directionsSlice";
import { feedbackActions } from "../redux/feedbackSlice";
import useHttps from "./useHttps";
import useLoader from "./useLoader";
import useOnboardingInformation from "./useOnboardingInformation";
import useTask from "./useTask";

const usePoi = () => {
   const {
      feedback: { showMissingFeedbackForm },
   } = useSelector((state) => state);
   const { directionLoadingCallback } = useLoader();
   const { getCities, data: cityData } = useOnboardingInformation();
   const dispatch = useDispatch();
   const { onMaximize } = useTask();
   const { sendRequest, cancelRequest, setCancelRequest } = useHttps();

   const onShowPoiForm = (tag) => {
      dispatch(feedbackActions.setShowMissingFeedbackForm(true));
      const data = PoiTaskLabel.find((label) => label.tag === tag);
      dispatch(feedbackActions.setPoiFormData(data));
      onMaximize();
   };

   const getLgaPercity = (city, page) => {
      const successCallback = (data) => {
         dispatch(directionActions.setMetaStopsByLga(data?.data?.meta));
         dispatch(directionActions.setStopsByLga(data?.data?.city_lga));
      };

      const pageValue = page ? `&page=${page}` : "";

      const config = {
         url: `search/auto/location/lga?city=${city}${pageValue}`.trim(),
         method: "get",
      };

      sendRequest(
         config,
         successCallback,
         directionLoadingCallback,
         null,
         null,
         null
      );
   };

   const loader = (value) => dispatch(directionActions.setLoading(value));

   const getPoiData = () => {
      const config = {
         method: "get",
         url: "user/poi/suggestion",
      };

      sendRequest(config, successCallback, loader, null);
   };

   const getPoiDataForFunPlaces = () => {
      const config = {
         method: "get",
         url: "user/poi/suggestion/fun/places",
      };

      sendRequest(config, successCallback, loader, null);
   };

   const successCallback = ({ data }) => {
      dispatch(directionActions.setPoiData(data?.locations));
   };

   return {
      getPoiData,
      showMissingFeedbackForm,
      onShowPoiForm,
      cityData,
      getLgaPercity,
      cancelRequest,
      setCancelRequest,
      getCities,
      getPoiDataForFunPlaces,
   };
};

export default usePoi;
