import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   inviteData: {},
};

const inviteSlice = createSlice({
   initialState,
   name: "Invite",
   reducers: {
      setInviteData(state, action) {
         state.inviteData = action.payload;
      },
   },
});

export const inviteSliceActions = inviteSlice.actions;
export const inviteSliceReducers = inviteSlice.reducer;

export default inviteSlice;
