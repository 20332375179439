import React from 'react';
import classes from './NavContainer.module.css';
import PropTypes from 'prop-types';

const NavContainer = (props) => {
	return (
		<div className={`${classes.container}  ${props.className}`}>
			<ul>{props.children}</ul>
		</div>
	);
};

NavContainer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.element,
};

export default NavContainer;
