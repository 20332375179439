/* eslint-disable no-undef */
import { appleAuthHelpers } from "react-apple-signin-auth";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import useSocialSignUp from "../hooks/useSocialSignUp";

const useAppleSocialLogin = () => {
   const {
      utilities: {
         device: { type },
      },
   } = useSelector((state) => state);

   const getSocialLoginData = useSocialSignUp();
   const { addToast } = useToasts();

   const isApple = type === "IOS" || type === "MACOS";

   const onSuccess = (response) => {
      const { authorization, user } = response;

      const userdata = {
         provider: "apple",
         token: authorization.code,
      };

      if (user) {
         userdata.name = `${user.name.firstName} ${user.name.lastName}`;
         userdata.email = user.email;
      }
      getSocialLoginData(userdata);
   };

   const onError = (error) => {
      addToast(error.message ?? "Can't seem to use this service now", {
         appearance: "error",
         autoDismiss: true,
      });
   };

   const appleClickHandler = () => {
      appleAuthHelpers.signIn({
         authOptions: {
            // eslint-disable-next-line no-undef
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            redirectURI: `${process.env.REACT_APP_APP_URL}login`,
            scope: "email name",
            usePopup: true,
            state: "state",
         },
         onSuccess,
         onError,
      });
   };

   return { appleClickHandler, isApple };
};

export default useAppleSocialLogin;
