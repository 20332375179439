import { motion } from "framer-motion";
import React from "react";
import { Link } from "react-router-dom";
import { LOGIN, REGISTER } from "../../../routes/Path";
import SocialLogin from "../Sociallogins/SocialLogin";
import classes from "./LoginSignupOption.module.css";
import { loginSignupContainerVariants } from "./LoginSignupVariants";

const LoginSignupOption = () => {
   return (
      <motion.div
         variants={loginSignupContainerVariants}
         initial="initial"
         animate="animate"
         exit="exit"
         className={classes.container}
      >
         <div>
            <div className={classes.signup}>
               <Link to={`/${REGISTER}`}>
                  <span>BETA-USER</span>
                  <p>SIGN UP</p>
               </Link>
            </div>
            <div className={classes.login}>
               <Link to={`/${LOGIN}`}>
                  <div className={classes.widgets}>
                     <SocialLogin />
                  </div>
                  <p>LOGIN</p>
               </Link>
            </div>
         </div>

         <p className={classes.terms}>
            Use of this app is subject to out{" "}
            <a
               href="https://beta.lara.ng/assets/End%20User%20Beta%20Testing%20Agreement.docx.pdf"
               target="_blank"
               rel="noreferrer"
            >
               Terms
            </a>{" "}
            &{" "}
            <a
               href="https://beta.lara.ng/assets/Lara.ng%20Privacy%20Policy%20170521.docx.pdf"
               target="_blank"
               rel="noreferrer"
            >
               {" "}
               Privacy policy
            </a>
         </p>
      </motion.div>
   );
};

export default LoginSignupOption;
