import React from "react";
import { useSelector } from "react-redux";
import useSaveTrip from "../../../../../hooks/useSaveTrip";
import DirectionLoader from "../../../../components/DirectionLoader/DirectionLoader";
import GenericButton from "../../../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./TripSaved.module.css";

const TripSaved = () => {
   const { showSelectedtrip, getSavedTrips } = useSaveTrip();
   const {
      directions: { loading },
   } = useSelector((state) => state);
   return (
      <div className={classes.TripSaved}>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <p>Trip Saved</p>
               <h2>Well Done !</h2>
               <div className={classes.saveTripButtonContainer}>
                  <button
                     style={{ background: "#8ECABC" }}
                     className={classes.saveTripButton}
                     onClick={() => showSelectedtrip("saveTrip")}
                  >
                     Save a new trip
                  </button>
                  <button
                     onClick={getSavedTrips}
                     className={classes.saveTripButton}
                  >
                     See your saved trips
                  </button>
               </div>
            </div>
         </DirectionsContainerWrapper>

         {loading && <DirectionLoader />}
         <GenericButton ButtonText="Back to direction" />
      </div>
   );
};

export default TripSaved;
