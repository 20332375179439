import { iconImages } from '../assets';

const socialLoginInfo = [
	{
		name: 'GOOGLE',
		description: 'Continue using Google',
		imgIcon: iconImages.googleIconImg,
	},
	{
		name: 'APPLE',
		description: 'Continue using apple ID',
		imgIcon: iconImages.appleIconImg,
	},
	{
		name: 'FACEBOOK',
		description: 'Continue using Facebook',
		imgIcon: iconImages.facebookIConImg,
	},
];

export default socialLoginInfo;
