/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { iconImages } from "../../../assets";
import { REGISTER } from "../../../routes/Path";
import Logo from "../../utilities/Logo/Logo";
import classes from "./Aboutbetatest.module.css";

const Aboutbetatest = () => {
   const {
      onboarding: {
         userData: { firstName },
         inviteeData: { inviter_name },
      },
   } = useSelector((state) => state);
   const history = useHistory();

   useEffect(() => {
      if (!firstName) {
         return history.push(`/${REGISTER}`);
      }
   }, [firstName, history]);

   return (
      <div className={classes.container}>
         <div className={classes.imgContainer}>
            <Logo></Logo>
         </div>
         <div>
            <div className={classes.name}>
               <h3>Hello {firstName}! </h3>

               <img
                  src={
                     inviter_name
                        ? iconImages.wavingHandMediumImg
                        : iconImages.partyPropperImg
                  }
                  alt=""
               />
            </div>

            <Link to={`/${REGISTER}`}>Not {firstName}?</Link>
         </div>

         <p>You’re here because…</p>
         {inviter_name ? (
            <p>
               <span className={classes.inviterName}>{inviter_name} </span>
               invited you to be among the first exclusive users of Lara as she
               comes to select new cities
            </p>
         ) : (
            <p>
               You've shown interest in being among the first exclusive users of
               Lara as she hits new cities.
            </p>
         )}

         <p>
            You can think of me as your personal directions assistant. I'm very
            good at helping people navigate their way via public transportation.
            I do this by providing accurate detailed directions, estimated bus
            fares, and travel time
         </p>
         <p>
            Just ask around,I be the baddest Or better still, start your waka
            with me and see for yourself 😏
         </p>
      </div>
   );
};

export default Aboutbetatest;
