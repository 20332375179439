import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";

import "./index.css";
import { interceptors } from "./interceptors/interceptors";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorkerRegistration";
import UpdateServiceWorkerNotification from "./views/utilities/NotificationModals/UpdateServiceWorkerNotification/UpdateServiceWorkerNotification";

interceptors.authInterceptor();

const updateServiceWorker = () => {
   ReactDOM.render(
      <UpdateServiceWorkerNotification />,
      document.getElementById("update")
   );
};

ReactDOM.render(
   <React.StrictMode>
      <Provider store={store}>
         <App />
      </Provider>
   </React.StrictMode>,
   document.getElementById("root")
);

reportWebVitals();

serviceWorker.register({
   onUpdate: updateServiceWorker,
});

window.addEventListener("beforeinstallprompt", (event) => {
   event.preventDefault();
   window.deferredPrompt = event;
});
