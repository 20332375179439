import { onboardingVideoAssets } from "../assets/videos/occupation";

const occupationInfo = [
   {
      name: " STUDENT",
      imgUrl: "/assets/images/occupation/student.png",
      videoUrl: onboardingVideoAssets.studentvideoanimation,
   },
   {
      name: "RECENT GRADUATE",
      imgUrl: "/assets/images/occupation/Graduate.svg",
      videoUrl: onboardingVideoAssets.recentgraduateanimation,
   },
   {
      name: "NYSC",
      imgUrl: "/assets/images/occupation/Nysc.png",
      videoUrl: onboardingVideoAssets.nyscVideoAnimation,
   },
   {
      name: "WORKING PROFESSIONAL",
      imgUrl: "/assets/images/occupation/professional.svg",
      videoUrl: onboardingVideoAssets.workingproffessionalannimation,
   },
   {
      name: "BUSINESS OWNER",
      imgUrl: "/assets/images/occupation/professional.svg",
      videoUrl: onboardingVideoAssets.workingproffessionalannimation,
   },
   {
      name: "OTHERS",
      imgUrl: "/assets/images/occupation/professional.svg",
      videoUrl: onboardingVideoAssets.otherCategory,
   },
];

export default occupationInfo;
