import { useGoogleLogin } from "react-google-login";
import { useToasts } from "react-toast-notifications";
import useSocialSignUp from "../hooks/useSocialSignUp";
// eslint-disable-next-line no-undef
const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const useGoogleSocialLogin = () => {
   const getSocialLoginData = useSocialSignUp();
   const { addToast } = useToasts();

   const googleLoginSuccess = ({ profileObj }) => {
      const userdata = {
         name: profileObj.name,
         token: profileObj.googleId,
         email: profileObj.email,
         provider: "google",
      };
      getSocialLoginData(userdata);
   };

   const googleLoginError = (err) =>
      addToast(err.message ?? "Can't seem to use this service now", {
         appearance: "error",
         autoDismiss: true,
      });

   const { loaded, signIn } = useGoogleLogin({
      onSuccess: googleLoginSuccess,
      onFailure: googleLoginError,
      clientId: CLIENT_ID,
   });

   return {
      loaded,
      signIn,
   };
};

export default useGoogleSocialLogin;
