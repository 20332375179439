import React, { useEffect, useRef } from "react";
import { iconImages } from "../../../../assets";
import classes from "./locatedmap.module.css";
import gsap from "gsap";

const Locatedmap = () => {
   const ref = useRef(null);

   useEffect(() => {
      animateMaps();
   }, []);

   const animateMaps = () => {
      const mapPoints = ref?.current?.querySelectorAll("div > div");

      const animation = gsap.timeline();
      if (mapPoints?.length > 0) {
         animation
            .fromTo(mapPoints[0], { opacity: 0 }, { opacity: 1 }, 0.5)
            .fromTo(mapPoints[3], { opacity: 0 }, { opacity: 1 }, 1.0)
            .fromTo(mapPoints[5], { opacity: 0 }, { opacity: 1 }, 1.5)
            .fromTo(mapPoints[2], { opacity: 0 }, { opacity: 1 }, 2.0)
            .fromTo(mapPoints[1], { opacity: 0 }, { opacity: 1 }, 2.5)
            .fromTo(mapPoints[4], { opacity: 0 }, { opacity: 1 }, 3.0)
            .repeat(-1);
      }
   };

   return (
      <div className={classes.container}>
         <div ref={ref}>
            <div className={`${classes.mapdots} ${classes.one}`}></div>
            <div className={`${classes.mapdots} ${classes.two}`}></div>
            <div className={`${classes.mapdots} ${classes.three}`}></div>
            <div className={`${classes.mapdots} ${classes.four}`}></div>
            <div className={`${classes.mapdots} ${classes.five}`}></div>
            <div className={`${classes.mapdots} ${classes.six}`}></div>
            <img src={iconImages.planeMapsImg} alt="" />
         </div>
      </div>
   );
};

export default Locatedmap;
