import React from 'react';
import { iconImages } from '../../../assets';
import classes from './UserInput.module.css';
import PropTypes from 'prop-types';

const UserInput = (props) => {
	return (
		<div className={classes.container}>
			<div>
				<h2>
					I am going to{' '}
					<img
						height='20px'
						src={iconImages.destinationIconImg}
						alt=''
					/>
				</h2>
				<p>{props.text}</p>
			</div>
		</div>
	);
};

UserInput.propTypes = {
	text: PropTypes.string,
};

export default UserInput;
