import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconImages } from "../../../../assets";
import { utilitiesActions } from "../../../../redux/utilitiesSlice";
import classes from "./SuccessNotification.module.css";

const SuccessNotification = () => {
   const {
      utilities: {
         success: { successText },
      },
      user: {
         userInformation: { firstName },
      },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const closeModal = useCallback(() => {
      dispatch(utilitiesActions.setShowModal(false));
      dispatch(
         utilitiesActions.setSuccess({
            successText: "",
            isSuccessFull: false,
         })
      );
   }, [dispatch]);

   return (
      <>
         <div className={classes.modalHeader}>
            <div>
               <p className={classes.modalText}>
                  Nicely done {firstName ? firstName : ""}!
               </p>
               <img
                  className={classes.checkImg}
                  src={iconImages.raiseingHandsIconImg}
                  alt=""
               />
            </div>

            <button onClick={closeModal}>X</button>
         </div>
         <div className={classes.modalBody}>
            <p>{successText}</p>
         </div>
      </>
   );
};

export default SuccessNotification;
