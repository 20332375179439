/* eslint-disable react/no-unescaped-entities */
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { iconImages } from "../../../assets";
import useShare from "../../../hooks/useShare";
import { shareContainerVariants } from "./shareAnimation";
import classes from "./ShareLaraTask.module.css";

const ShareLaraTask = () => {
   const {
      getDirectionInformation,
      shareToSocialMedia,
      copyToClipBoard,
      clipboardText,
      closeShare,
   } = useShare();

   useEffect(() => {
      getDirectionInformation();
   }, [getDirectionInformation]);

   return (
      <motion.div
         className={classes.container}
         style={{
            background: `url('${iconImages.shareLarabg}')`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top 20px",
         }}
         variants={shareContainerVariants}
         animate="animate"
         initial="initial"
         exit="exit"
      >
         <button onClick={closeShare}>X</button>
         <div className={classes.innerContainer}>
            <div className={classes.shareText}>
               <h4>Share to a friend</h4>
               <p>Help them avoid any 'I just lost my way' </p>
            </div>

            <ul className={classes.shareWidgets}>
               <li onClick={() => shareToSocialMedia("WHATSAPP")}>
                  <img src={iconImages.whatsappShare} alt="" />
               </li>
               <li onClick={() => shareToSocialMedia("TWITTER")}>
                  <img src={iconImages.twitterShare} alt="" />
               </li>
               <li onClick={() => shareToSocialMedia("FACEBOOK")}>
                  <img src={iconImages.facebookShare} alt="" />
               </li>
               <li onClick={() => shareToSocialMedia("GOOGLE")}>
                  <img src={iconImages.googleShare} alt="" />
               </li>
               <li
                  onClick={copyToClipBoard}
                  className={classes.clipboardContainer}
               >
                  <a>
                     <img src={iconImages.clipboardShare} alt="" />
                  </a>
                  <div className={classes.clipboardText}>
                     <p>{clipboardText}</p>
                  </div>
               </li>
            </ul>
         </div>
      </motion.div>
   );
};

export default ShareLaraTask;
