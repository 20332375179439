import { motion } from "framer-motion";
import React from "react";
import { useHistory } from "react-router";
import { iconImages } from "../../../assets";
import useInput from "../../../hooks/useInput";
import useOnboardingVerification from "../../../hooks/useOnboardingVerification";
import { SOCIAL_LOGIN } from "../../../routes/Path";
import validators from "../../../Utilities/inputValidator";
import ContinueWith from "../../sharedcomponents/ContinueWith/ContinueWith";
import SocialLogin from "../Sociallogins/SocialLogin";
import classes from "./Startyourwaka.module.css";

const Startyourwaka = () => {
   const history = useHistory();

   const { onEmailExists, emailExists } = useOnboardingVerification();

   const { hasError, value, valueInputChange, valueIsValid, inputBlurHandler } =
      useInput(validators.checkEmailIsValid);

   const submitForm = (event) => {
      event.preventDefault();
      if (!valueIsValid) {
         return;
      }
      onEmailExists(value);
   };

   const googleClickHandler = () => {
      history.push(`/${SOCIAL_LOGIN}/google`);
   };

   const facebookClickHandler = () => {
      history.push(`/${SOCIAL_LOGIN}/facebook`);
   };

   const appleClickHandler = () => {
      history.push(`/${SOCIAL_LOGIN}/apple`);
   };

   return (
      <motion.div className={classes.container}>
         <div>
            <div className={classes.enterEmail}>
               <p>Start your waka with </p>
               <img height="35px" src={iconImages.laraLogoText} />
            </div>

            <form onSubmit={submitForm}>
               <div
                  className={`${classes.formGroup} ${
                     hasError || emailExists ? classes.error : ""
                  }`}
               >
                  {emailExists && (
                     <p className={classes.emailExists}>
                        {" "}
                        Email already registered{" "}
                     </p>
                  )}
                  <input
                     type="email"
                     className={classes.formControl}
                     value={value}
                     onBlur={inputBlurHandler}
                     onChange={valueInputChange}
                     placeholder="Enter your email to continue "
                  />

                  {valueIsValid && (
                     <motion.button className={classes.btn}>
                        <img src={iconImages.betaSendImg} alt="" />
                     </motion.button>
                  )}
               </div>
            </form>
            <ContinueWith className={classes.startyourwaka} />

            <SocialLogin
               appleClickHandler={appleClickHandler}
               facebookClickHandler={facebookClickHandler}
               googleClickHandler={googleClickHandler}
               className={classes.widgets}
               signup={true}
            />
         </div>
      </motion.div>
   );
};

export default Startyourwaka;
