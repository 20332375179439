const emailRegex =
	/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const checkValueIsEmpty = (value) => value.trim() !== '';

const checkEmailIsValid = (value) =>
	emailRegex.test(`${value.trim().toLowerCase()}`);

const checkPasswordIsValid = (value) => value.trim().length > 5;

const validators = {
	checkValueIsEmpty,
	checkEmailIsValid,
	checkPasswordIsValid,
};

export default validators;
