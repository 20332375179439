import React from "react";
import { useSelector } from "react-redux";
import useSaveTrip from "../../../../../hooks/useSaveTrip";
import { capitalize, dateFunc } from "../../../../../Utilities/helper";
import GenericButton from "../../../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./SelectTrip.module.css";

const SelectTrip = () => {
   const { selectTrip, showSelectedtrip } = useSaveTrip();
   const {
      saveTrip: { singleTrip, userTrips },
   } = useSelector((state) => state);

   return (
      <div className={classes.SelectTrip}>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <h2>Select a trip</h2>
               <p>Choose a trip from your trip history</p>
               <div className={classes.timelineContainer}>
                  {userTrips.map((trip, index) => (
                     <div
                        onClick={() => selectTrip(trip)}
                        key={index}
                        className={classes.singleTripBox}
                     >
                        <div
                           style={
                              singleTrip.uid === trip.uid
                                 ? { background: "#bee4df" }
                                 : {}
                           }
                           className={classes.roundPointer}
                        ></div>
                        <div className={classes.singleTrip}>
                           <p>
                              {trip.search_from} - {trip.search_to}{" "}
                           </p>
                           <span>
                              {capitalize(trip.mode)}, {capitalize(trip.state)}{" "}
                              - {dateFunc(trip.date)}
                           </span>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </DirectionsContainerWrapper>

         <GenericButton
            className={`${classes.saveTripButton} ${
               singleTrip.uid ? classes.active : ""
            }`}
            click={() => showSelectedtrip("giveTripName")}
            ButtonText="Save this trip"
         />
      </div>
   );
};

export default SelectTrip;
