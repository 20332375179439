import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconImages } from "../../../../assets";
import useInput from "../../../../hooks/useInput";
import { formatDateTime } from "../../../../Utilities/helper";
import validators from "../../../../Utilities/inputValidator";
import classes from "./Remindmelater.module.css";
import useReminder from "../../../../hooks/useReminder";
import { userNotificationActions } from "../../../../redux/userNotificationSlice";

const Remindmelater = () => {
   const dispatch = useDispatch();
   const {
      notification: { backlogTasks, activeMissedTask, loading },
   } = useSelector((state) => state);

   const { setReminder } = useReminder();

   const {
      value: date,
      valueInputChange: dateValueInputChange,
      valueIsValid: dateIsValid,
   } = useInput(validators.checkValueIsEmpty, "");
   const {
      value: time,
      valueInputChange: timeValueInputChange,
      valueIsValid: timeIsValid,
   } = useInput(validators.checkValueIsEmpty, "");

   const formIsValid = dateIsValid && timeIsValid;

   const closeNotification = () => {
      dispatch(userNotificationActions.setShowRemindmeLater(false));
      dispatch(userNotificationActions.setShowNotification(false));
   };

   const handleFormInfo = () => {
      if (!formIsValid) {
         return;
      }
      const data = {
         task_id: backlogTasks[activeMissedTask].task_id,
         user_task_id: backlogTasks[activeMissedTask].user_task_id,
         date_time: formatDateTime(date, time),
      };
      setReminder(data, closeNotification);
   };
   return (
      <div className={classes.container}>
         <div className={classes.header}>
            <h4>ADD A REMINDER</h4>
            <img src={iconImages.notificationIconImg} alt="" />
         </div>

         <div className={classes.formGroup}>
            <input
               type="date"
               placeholder="Set reminder time"
               value={date}
               onChange={dateValueInputChange}
            />
         </div>
         <div className={classes.formGroup}>
            <input type="time" value={time} onChange={timeValueInputChange} />
         </div>

         <div className={classes.btnContainer}>
            <button
               className={classes.btn}
               disabled={!formIsValid}
               onClick={handleFormInfo}
            >
               {loading ? "Loading..." : "Add reminder"}
            </button>
         </div>
      </div>
   );
};

export default Remindmelater;
