import React from "react";
import { useSelector } from "react-redux";
import {
   capitalize,
   formattedDistance,
   formattedTime,
} from "../../../Utilities/helper";
import classes from "./DirectionCard.module.css";
import PropTypes from "prop-types";

const DirectionCard = (props) => {
   const {
      data: { directions_from, directions_to, path },
      isAutoFeedback,
   } = props;
   const {
      utilities: { isLoggedIn },
   } = useSelector((state) => state);

   const sortByFastest = (path) => {
      const pathToSort = [...path];
      const sortedPath = pathToSort.sort(
         (a, b) => a.duration_total - b.duration_total
      );

      return sortedPath;
   };

   const buttonDesc = (index) => (
      <div>
         <button className={classes.btn}>
            {index === 0 ? "Fastest Route" : `Alt Option - ${index}`}
         </button>
      </div>
   );

   const directionForEachCard = (card, index) => {
      const {
         distance_total,
         duration_total,
         max_fare_sum,
         min_fare_sum,
         result,
         start_text,
      } = card;

      const estimatedAmount =
         max_fare_sum === min_fare_sum ? (
            <span>N{max_fare_sum}</span>
         ) : (
            <span>
               N{min_fare_sum} - {max_fare_sum}
            </span>
         );

      return (
         <div className={classes.getDirections} key={index}>
            {" "}
            {buttonDesc(index)}
            <div className={classes.directionHead}>
               {" "}
               <p>
                  Directions from {directions_from} to {directions_to}{" "}
               </p>{" "}
            </div>{" "}
            {mapThroughResultArray(result, start_text)}
            <div className={classes.directionBody}>
               {directions_to !== result[result.length - 1].drop_at && (
                  <>
                     <p>
                        Then walk to <b>{directions_to}</b> from{" "}
                        <b>{result[result.length - 1].drop_at}</b>{" "}
                     </p>
                     <div className={classes.filterDropdown}>
                        <img src="./images/dropdown.png" alt="" />
                     </div>
                  </>
               )}
            </div>
            <div className={classes.info}>
               {" "}
               <p className={classes.estimate}>
                  <b>Total Estimate:</b>
                  {estimatedAmount}
               </p>
               <p>Estimated travel time: {formattedTime(duration_total)}</p>{" "}
               <p>Total distance : {formattedDistance(distance_total)}</p>
            </div>{" "}
         </div>
      );
   };
   const mapThroughDirectionPath = () => {
      if (isAutoFeedback) {
         return directionForEachCard([...sortByFastest(path)][0], 0);
      }
      return isLoggedIn
         ? sortByFastest(path).map((card, index) => (
              <>{directionForEachCard(card, index)}</>
           ))
         : directionForEachCard([...sortByFastest(path)][0], 0);
   };

   const mapThroughResultArray = (result) =>
      result.map((body, index) => {
         let {
            drop_at,
            going_from,
            heading_towards,
            mode,
            opposite,
            max_fare,
            min_fare,
         } = body;

         const fare_total =
            max_fare === min_fare
               ? `N${max_fare}`
               : `N${min_fare} - N ${max_fare}`;

         const checkOpposite = () => (opposite ? opposite : "Walk to");

         const walkingText = () =>
            index > 0 && result[index - 1].mode === mode ? (
               <p>
                  From {result[index - 1].drop_at}, Please {checkOpposite()}{" "}
                  <b>{drop_at}</b>{" "}
               </p>
            ) : (
               <p>
                  Then {checkOpposite()} <b>{drop_at}</b> from{" "}
                  <b>{going_from}</b>{" "}
               </p>
            );

         const nextMode =
            index > 0 && result[index - 1].mode === mode ? (
               <h5>Then, enter another {capitalize(mode)}</h5>
            ) : (
               <h5>Next, take a {capitalize(mode)}</h5>
            );

         return (
            <div className={classes.directionBody} key={index}>
               {index === 0 && (
                  <>
                     <p className={classes.mask}>
                        Start by remembering to wear your mask
                     </p>
                  </>
               )}

               {mode === "Walking" ? (
                  walkingText()
               ) : (
                  <>
                     {nextMode}
                     <p>From: {going_from}</p>
                     <p>Heading Towards: {heading_towards}</p>
                     <p>Get Off at: {drop_at}</p>
                     {+max_fare > 0 && <h5>Price: {fare_total}</h5>}
                  </>
               )}

               <div className={classes.filterDropdown}>
                  <img src="./images/dropdown.png" alt="" />
               </div>
            </div>
         );
      });
   return (
      <>{[...sortByFastest(path)].length > 0 && mapThroughDirectionPath()}</>
   );
};

DirectionCard.propTypes = {
   data: PropTypes.object,
   isAutoFeedback: PropTypes.bool,
};

export default DirectionCard;
