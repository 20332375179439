import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { ABOUT } from "../../../../routes/Path";
import useAppleSocialLogin from "../../../../socialLogins/AppleSocialLogin";
import FacebookSocialLogin from "../../../../socialLogins/FacebookSocilaLogin";
import useGoogleSocialLogin from "../../../../socialLogins/GoogleSocialLogin";
import { capitalize } from "../../../../Utilities/helper";
import socialLoginInfo from "../../../../Utilities/socialLoginInfo";
import Container from "../../../components/Wrappers/container/Container";
import LoginHeader from "../../../utilities/LoginHeader/LoginHeader";
import TermsandCondition from "../../../utilities/TermsandCondition/TermsandCondition";
import classes from "./SocialSignup.module.css";

const SocialSignup = () => {
   const history = useHistory();
   const [widget, setWidget] = useState({});
   const {
      params: { string },
   } = useRouteMatch();

   const { isApple, appleClickHandler } = useAppleSocialLogin();
   const { signIn } = useGoogleSocialLogin();

   const socialLoginCallback = {
      GOOGLE: signIn,
      APPLE: appleClickHandler,
   };

   const setWidgetToState = useCallback(() => {
      const widgetObject = socialLoginInfo.find(
         (data) => data.name === string.toUpperCase()
      );
      if (
         widgetObject.length < 1 ||
         (!isApple && widgetObject.name === "APPLE")
      ) {
         return history.push(`/${ABOUT}`);
      }

      setWidget(widgetObject);
   }, [history, string, isApple]);

   useEffect(() => {
      setWidgetToState();
   }, [setWidgetToState]);

   const element = (clickHandler) => (
      <div onClick={clickHandler} className={classes.socialLoginContainer}>
         <div>
            <img alt="" src={widget.imgIcon} />
         </div>
         <p>{widget.description}</p>
      </div>
   );

   const termsText = (name) =>
      `By login in with ${capitalize(name.toLowerCase())}`;

   return (
      <>
         {widget.name && (
            <Container className={classes.container}>
               <>
                  <LoginHeader />
                  {widget.name === "FACEBOOK" ? (
                     <FacebookSocialLogin facebookElement={element} />
                  ) : (
                     element(socialLoginCallback[widget.name])
                  )}

                  <TermsandCondition text={termsText(widget.name)} />
               </>
            </Container>
         )}
      </>
   );
};

export default SocialSignup;
