export const showAppopriateTask = {
   1: "showDirectionTask",
   2: "showFeedbackTask",
   3: "showDownloadLaraTask",
   4: "showFunPlacesTask",
   5: "showFeedbackTask",
   6: "showDirectionTask",
   7: "showFeedbackOnApp",
   8: "showShareTask",
   9: "showRecommendaNewFeatureTask",
   10: "showDirectionTask",
   11: "showFeedbackTask",
   12: "showAutoGeneratedFeedbackTask",
   13: "showFollowOnSocials",
   14: "showFunPlacesTask",
   15: "showSavedTripTask",
   16: "showFeedbackOnApp",
   17: "showDirectionTask",
   18: "showDirectionTask",
   19: "showShareTask",
   20: "showAutoGeneratedFeedbackTask",
   21: "showFunPlacesTask",
   22: "showSavedTripTask",
   23: "showDirectionTask",
   24: "showDirectionTask",
   25: "showMissingPoiTask",
   26: "showDirectionTask",
   27: "showInterStateTask",
   28: "showShareTask",
   29: "showFunPlacesTask",
   30: "showDirectionTask",
   31: "showDirectionTask",
   32: "showSavedTripTask",
   33: "showShareTask",
   34: "showDirectionTask",
   35: "showDirectionTask",
   36: "showFunPlacesTask",
   37: "showSavedTripTask",
   38: "showShareTask",
};

export const taskCompletion = {
   data: {
      updated_user_task: [
         {
            total_points: 10,
         },
         {
            badge_level: 20,
            completed: false,
            description:
               "Maintain your point streaks by inviting friends to the beta test, and request for a feature.",
            expiry_date: "2022-05-09 09:12:41",
            name: "Invite friends",
            points: 10,
            start_date: "2022-05-08 09:12:41",
            task_id: 4,
            user_sub_task: [
               {
                  color: null,
                  completed_date: "Tue, 17 May 2022 08:07:00 GMT",
                  is_done: true,
                  name: "invite friends",
                  points: 10,
                  sub_task_id: 8,
                  task_id: 4,
               },
               {
                  color: null,
                  completed_date: null,
                  is_done: false,
                  name: "requests a feature",
                  points: 10,
                  sub_task_id: 9,
                  task_id: 4,
               },
            ],
            user_task_id: 4,
         },
         {
            birthday: "",
            city: "Abuja",
            city_id: 1,
            coins: 10,
            commute_reason: "School",
            email: "chimuanya@roadpreppers.com",
            home: null,
            interests: ["4", "8"],
            job_status: "Student",
            level: 1,
            name: "Chimuanya Ibecheozor",
            phone: null,
            sex: "",
            status: 1,
            work: null,
         },
      ],
   },
   message: "Thanks for completing this mini task, on to the next one!",
   status: true,
};
