import React, { useEffect } from "react";
import { useHistory } from "react-router";
import useAuth from "../../../../hooks/useAuth";
import useHttps from "../../../../hooks/useHttps";
import useInput from "../../../../hooks/useInput";
import { REGISTER } from "../../../../routes/Path";
import validators from "../../../../Utilities/inputValidator";
import Container from "../../../components/Wrappers/container/Container";
import Logo from "../../../utilities/Logo/Logo";
import TermsandCondition from "../../../utilities/TermsandCondition/TermsandCondition";
import { onboardingContainerVariants } from "../onboardingVariants";
import classes from "./Enterpassword.module.css";

const Enterpassword = () => {
   const history = useHistory();
   const { sendRequest } = useHttps();
   const { createUser, firstName } = useAuth(sendRequest);

   const {
      value: passwordValue,
      valueIsValid: passwordIsValid,
      valueInputChange: passwordInputChange,
      inputBlurHandler: passwordBlurHandler,
      hasError: passwordHasError,
      onSetShowPassword,
      showPassword,
   } = useInput(validators.checkPasswordIsValid);

   const { setIsCheckedHandler, isChecked } = useInput(
      (value) => value.checked
   );

   const formIsValid = passwordIsValid && isChecked;

   const onCreateUser = (event) => {
      event.preventDefault();
      if (!formIsValid) {
         return;
      }
      createUser({ password: passwordValue });
   };

   useEffect(() => {
      if (!firstName) {
         return history.push(`/${REGISTER}`);
      }
   }, [firstName, history]);

   return (
      <Container
         variants={onboardingContainerVariants}
         className={classes.passwordContainer}
      >
         <>
            <div className={classes.imgContainer}>
               <Logo />
            </div>
            <h3>Almost there {firstName}</h3>
            <div className={classes.info}>
               <h5>Complete your account setup</h5>
               <p>
                  Enter a strong password to start enjoying the full benefits of
                  Lara.
               </p>
            </div>
            <form onSubmit={onCreateUser}>
               <div
                  className={`${classes.formGroup} ${
                     passwordHasError && classes.error
                  }`}
               >
                  <div>
                     <i
                        className={
                           !showPassword ? `far fa-eye` : "far fa-eye-slash"
                        }
                        onClick={onSetShowPassword}
                     ></i>
                     <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Min 6 Charcters"
                        value={passwordValue}
                        onChange={passwordInputChange}
                        onBlur={passwordBlurHandler}
                     />
                  </div>

                  <button
                     disabled={!formIsValid}
                     className={`${classes.btn} ${
                        !formIsValid && classes.disabled
                     }`}
                  >
                     CREATE ACCOUNT
                  </button>
               </div>
            </form>
            <div className={classes.checkbox}>
               <input onChange={setIsCheckedHandler} type="checkbox" />
               <TermsandCondition text={"By checking this box"} />
            </div>
         </>
      </Container>
   );
};

export default Enterpassword;
