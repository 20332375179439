import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   error: {
      errorText: "",
      isError: false,
      errorFunctionType: null,
      errorButtonText: "",
   },
   isNormalLoading: false,
   device: {},
   success: {
      successText: "",
      isSuccessFull: false,
   },
   levelUpdate: {
      islevelChanged: false,
      level: 0,
      state: "",
   },
   taskCompletion: {
      isCompleted: false,
      points: 0,
      headerText: "",
      subText: "",
   },
   showPushModal: false,
   showModal: false,
   serviceWorkerUpdate: false,
   cancelUseffect: false,
   isLoggedIn: false,
   fromWidget: false,
   showFeedbackThanks: false,
   downloadDisplay: false,
};

const utilitiesSlice = createSlice({
   name: "utilities",
   initialState,
   reducers: {
      setError(state, action) {
         state.error = { ...action.payload };
      },
      setNormalLoading(state, action) {
         state.isNormalLoading = action.payload;
      },
      setDeviceInfo(state, action) {
         state.device = { ...action.payload };
      },
      setLevelUpdate(state, action) {
         state.levelUpdate = { ...action.payload };
      },
      setTaskCompletion(state, action) {
         state.taskCompletion = { ...action.payload };
      },
      setSuccess(state, action) {
         state.success = { ...action.payload };
      },
      setShowModal(state, action) {
         state.showModal = action.payload;
      },
      setServiceWorkerUpdate(state, action) {
         state.serviceWorkerUpdate = action.payload;
      },
      setCancelUseEffect(state, action) {
         state.cancelUseffect = action.payload;
      },

      setIsloggedin(state, action) {
         state.isLoggedIn = action.payload;
      },

      setShowPushModal(state, action) {
         state.showPushModal = action.payload;
      },
      setFromWidget(state, action) {
         state.fromWidget = action.payload;
      },
      setShowFeedbackThanks(state, action) {
         state.showFeedbackThanks = action.payload;
      },
      setDownloadDisplay(state, action) {
         state.downloadDisplay = action.payload;
      },
   },
});

export const utilitiesActions = utilitiesSlice.actions;
export const utilitiesReducer = utilitiesSlice.reducer;

export default utilitiesSlice;
