import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   loading: false,
   remindMe: false,
   showNotification: false,
   backlogTasks: [],
   seenBacklogScreen: false,
   activeMissedTask: null,
   clearedNotifications: [],
   emailToggleValue: 1,
   pushToggleValue: 1,
};

const userNotificationSlice = createSlice({
   name: "notification",
   initialState,
   reducers: {
      setShowRemindmeLater(state, action) {
         state.remindMe = action.payload;
      },
      setShowNotification(state, action) {
         state.showNotification = action.payload;
      },
      setBacklogTask(state, action) {
         state.backlogTasks = action.payload;
      },
      setSeenBacklogScreen(state, action) {
         state.seenBacklogScreen = action.payload;
      },
      setActiveMissedTask(state, action) {
         state.activeMissedTask = action.payload;
      },
      setClearedNotifications(state, action) {
         state.clearedNotifications = action.payload;
      },
      setEmailToggleValue(state, action) {
         state.emailToggleValue = action.payload;
      },
      setPushToggleValue(state, action) {
         state.pushToggleValue = action.payload;
      },
      setLoading(state, action) {
         state.loading = action.payload;
      },
   },
});

export const userNotificationActions = userNotificationSlice.actions;
export const userNotificationReducer = userNotificationSlice.reducer;

export default userNotificationSlice;
