/* eslint-disable no-redeclare */

import { finalStageAssetsFiles } from "../assets/images/Final stage assets";
import { INVITE, NOTIFICATION, PLAY_AREA, USER } from "../routes/Path";
import { helpGetImages } from "../views/components/SetTaskInformation/helpImages";

/**
 * The checkTime function returns a string that is the greeting for the current time of day.
 */
export const checkTime = () => {
   const time = new Date();
   const hour = time.getHours();

   let greeting = "Good ";
   greeting += hour >= 0 && hour <= 11 ? "morning" : "";
   greeting += hour >= 12 && hour <= 16 ? "afternoon" : "";
   greeting += hour >= 17 && hour <= 24 ? "evening" : "";

   return greeting;
};

export const filterModes = (modes) => {
   if (modes) {
      let filteredMode = Object.entries(modes);
      filteredMode = filteredMode.filter((mode) => {
         return mode[1] === 1;
      });
      return filteredMode;
   } else {
      return [];
   }
};

export const countDownTimer = (countDownDate) => {
   const now = new Date().getTime();
   const usefulCountDownDate = countDownDate.replace(" ", "T");
   const distance = new Date(usefulCountDownDate) - now;
   const days = Math.floor(parseFloat(distance / (1000 * 60 * 60 * 24)));
   const hours = Math.floor(
      parseFloat((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
   );
   const minutes = Math.floor(
      parseFloat((distance % (1000 * 60 * 60)) / (1000 * 60))
   );

   return `${days}d ${hours}h ${minutes}mins`;
};

export const capitalize = (s) => {
   if (typeof s !== "string") return "";
   return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Get the available modes for the route.
 */
export const getAvailableModes = (data) => {
   if (!data.status) {
      return {};
   }
   const {
      data: {
         directions_from,
         directions_to,
         path: { modes, state },
      },
   } = data.status && data;
   return { modes: filterModes(modes), state, directions_from, directions_to };
};

export const formattedTime = (time) => {
   time = Number(time);
   let h = Math.floor(time / 3600);
   let m = Math.floor((time % 3600) / 60);

   let hDisplay = h > 0 ? h + (h === 1 ? " hour " : " hours ") : "";
   let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes ") : "";

   return hDisplay + mDisplay;
};

export const formattedDistance = (number) => {
   if (number > 1000) {
      number = Math.round(number);
      number = Math.ceil(number / 1000);
      number = `${number} Kilometres`;
   } else {
      number = Math.round(number);
      number = Math.ceil(number / 100) * 100;
      number = `${number} metres`;
   }
   return number;
};

export const scrollDown = (element) => {
   element.scrollTop = element.scrollHeight;
};

export const dayNames = ["Today", "Tommorrow", "Overmorrow"];

export const getDateInformation = (date) => {
   const dateNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
   const getDate = date.getDate();
   const getDay = date.getDay();

   const day = { date: getDate, day: dateNames[getDay] };

   return day;
};

export const createUserid = () => Math.random().toString(36).substring(3);

export const setCountdown = (countDownDate) => {
   const timeNow = new Date().getTime();
   let countDownString = "";

   setInterval(() => {
      const distance = countDownDate - timeNow;
      const h = Math.floor(distance / 1000 / 60 / 60);
      const m = Math.floor(distance / 1000 / 60) % 60;
      const s = Math.floor(distance / 1000) % 60;

      countDownString = `${h}hrs ${m}mins ${s}seconds`;
   }, 1000);

   return countDownString;
};

export const setit = (cname, cvalue, exdays) => {
   const d = new Date();
   d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
   const expires = "expires=" + d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};
export const getit = (cname) => {
   let name = cname + "=";
   let ca = document.cookie.split(";");
   for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
         c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
         return c.substring(name.length, c.length);
      }
   }
   return "";
};

export const reduceArrayToGetTotalAmount = (array = [], elementType) => {
   let amount = 0;
   for (let i = 0; i < array.length; i++) {
      amount = amount + +array[i][elementType];
   }
   return amount;
};

export const sha256 = (s) => {
   // function SHA256(s) {

   var chrsz = 8;

   var hexcase = 0;

   function safe_add(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff);

      var msw = (x >> 16) + (y >> 16) + (lsw >> 16);

      return (msw << 16) | (lsw & 0xffff);
   }

   function S(X, n) {
      return (X >>> n) | (X << (32 - n));
   }

   function R(X, n) {
      return X >>> n;
   }

   function Ch(x, y, z) {
      return (x & y) ^ (~x & z);
   }

   function Maj(x, y, z) {
      return (x & y) ^ (x & z) ^ (y & z);
   }

   function Sigma0256(x) {
      return S(x, 2) ^ S(x, 13) ^ S(x, 22);
   }

   function Sigma1256(x) {
      return S(x, 6) ^ S(x, 11) ^ S(x, 25);
   }

   function Gamma0256(x) {
      return S(x, 7) ^ S(x, 18) ^ R(x, 3);
   }

   function Gamma1256(x) {
      return S(x, 17) ^ S(x, 19) ^ R(x, 10);
   }

   function core_sha256(m, l) {
      var K = new Array(
         0x428a2f98,
         0x71374491,
         0xb5c0fbcf,
         0xe9b5dba5,
         0x3956c25b,
         0x59f111f1,
         0x923f82a4,
         0xab1c5ed5,
         0xd807aa98,
         0x12835b01,
         0x243185be,
         0x550c7dc3,
         0x72be5d74,
         0x80deb1fe,
         0x9bdc06a7,
         0xc19bf174,
         0xe49b69c1,
         0xefbe4786,
         0xfc19dc6,
         0x240ca1cc,
         0x2de92c6f,
         0x4a7484aa,
         0x5cb0a9dc,
         0x76f988da,
         0x983e5152,
         0xa831c66d,
         0xb00327c8,
         0xbf597fc7,
         0xc6e00bf3,
         0xd5a79147,
         0x6ca6351,
         0x14292967,
         0x27b70a85,
         0x2e1b2138,
         0x4d2c6dfc,
         0x53380d13,
         0x650a7354,
         0x766a0abb,
         0x81c2c92e,
         0x92722c85,
         0xa2bfe8a1,
         0xa81a664b,
         0xc24b8b70,
         0xc76c51a3,
         0xd192e819,
         0xd6990624,
         0xf40e3585,
         0x106aa070,
         0x19a4c116,
         0x1e376c08,
         0x2748774c,
         0x34b0bcb5,
         0x391c0cb3,
         0x4ed8aa4a,
         0x5b9cca4f,
         0x682e6ff3,
         0x748f82ee,
         0x78a5636f,
         0x84c87814,
         0x8cc70208,
         0x90befffa,
         0xa4506ceb,
         0xbef9a3f7,
         0xc67178f2
      );

      var HASH = new Array(
         0x6a09e667,
         0xbb67ae85,
         0x3c6ef372,
         0xa54ff53a,
         0x510e527f,
         0x9b05688c,
         0x1f83d9ab,
         0x5be0cd19
      );

      var W = new Array(64);

      var a, b, c, d, e, f, g, h, i, j;

      var T1, T2;

      m[l >> 5] |= 0x80 << (24 - (l % 32));

      m[(((l + 64) >> 9) << 4) + 15] = l;

      for (var i = 0; i < m.length; i += 16) {
         a = HASH[0];

         b = HASH[1];

         c = HASH[2];

         d = HASH[3];

         e = HASH[4];

         f = HASH[5];

         g = HASH[6];

         h = HASH[7];

         for (var j = 0; j < 64; j++) {
            if (j < 16) W[j] = m[j + i];
            else
               W[j] = safe_add(
                  safe_add(
                     safe_add(Gamma1256(W[j - 2]), W[j - 7]),
                     Gamma0256(W[j - 15])
                  ),
                  W[j - 16]
               );

            T1 = safe_add(
               safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]),
               W[j]
            );

            T2 = safe_add(Sigma0256(a), Maj(a, b, c));

            h = g;

            g = f;

            f = e;

            e = safe_add(d, T1);

            d = c;

            c = b;

            b = a;

            a = safe_add(T1, T2);
         }

         HASH[0] = safe_add(a, HASH[0]);

         HASH[1] = safe_add(b, HASH[1]);

         HASH[2] = safe_add(c, HASH[2]);

         HASH[3] = safe_add(d, HASH[3]);

         HASH[4] = safe_add(e, HASH[4]);

         HASH[5] = safe_add(f, HASH[5]);

         HASH[6] = safe_add(g, HASH[6]);

         HASH[7] = safe_add(h, HASH[7]);
      }

      return HASH;
   }

   function str2binb(str) {
      var bin = Array();

      var mask = (1 << chrsz) - 1;

      for (var i = 0; i < str.length * chrsz; i += chrsz) {
         bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << (24 - (i % 32));
      }

      return bin;
   }

   function Utf8Encode(string) {
      string = string.replace(/\r\n/g, "\n");

      var utftext = "";

      for (var n = 0; n < string.length; n++) {
         var c = string.charCodeAt(n);

         if (c < 128) {
            utftext += String.fromCharCode(c);
         } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192);

            utftext += String.fromCharCode((c & 63) | 128);
         } else {
            utftext += String.fromCharCode((c >> 12) | 224);

            utftext += String.fromCharCode(((c >> 6) & 63) | 128);

            utftext += String.fromCharCode((c & 63) | 128);
         }
      }

      return utftext;
   }

   function binb2hex(binarray) {
      var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";

      var str = "";

      for (var i = 0; i < binarray.length * 4; i++) {
         str +=
            hex_tab.charAt(
               (binarray[i >> 2] >> ((3 - (i % 4)) * 8 + 4)) & 0xf
            ) + hex_tab.charAt((binarray[i >> 2] >> ((3 - (i % 4)) * 8)) & 0xf);
      }
      return str;
   }

   s = Utf8Encode(s);

   return binb2hex(core_sha256(str2binb(s), s.length * chrsz));
};

export const splitContactNumber = (contactNumber = "") => {
   if (!contactNumber) {
      return "";
   }
   const firstNumber = contactNumber.substring(0, 3);
   const secondNumber = contactNumber.substring(3, 7);
   const thirdNumber = contactNumber.substring(7, contactNumber.length);
   return { firstNumber, secondNumber, thirdNumber };
};

export const getBirthdayDate = (launchDate) => {
   var date = new Date(launchDate);

   launchDate =
      date.getUTCDate() +
      "-" +
      (date.getUTCMonth() + 1) +
      "-" +
      date.getUTCFullYear();
   return launchDate;
};

export const dateFunc = (params) => {
   const date = new Date(params);
   const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
   ];

   let formatted_date = `${date.getDate()} ${
      months[date.getMonth()]
   }, ${date.getFullYear()}`;
   return formatted_date;
};

export const toggleTaskIcons = (id, name, city) => {
   const icon = id === 0 ? helpGetImages(name, city) : "";

   return icon;
};

export const shortenTaskName = (taskName) => {
   return taskName.length > 26 ? taskName.slice(0, 26) + "..." : taskName;
};

export function urlB64ToUint8Array(base64String) {
   const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
   const base64 = (base64String + padding)
      // eslint-disable-next-line no-useless-escape
      .replace(/\-/g, "+")
      .replace(/_/g, "/");

   const rawData = window.atob(base64);
   const outputArray = new Uint8Array(rawData.length);

   for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
   }
   return outputArray;
}

export const formatDateTime = (date, time) => `${date} ${time}:00`;

export const weekDays = {
   1: "Mon",
   2: "Tue",
   3: "Wed",
   4: "Thurs",
   5: "Fri",
   6: "Sat",
   7: "Sun",
   0: "Sun",
};
export const handleBar = (singleTask) => {
   if (singleTask?.day?.dayName === "Today") {
      return "flex-start";
   }
   if (singleTask?.day?.dayName === "Tommorrow") {
      return "center";
   }
   if (singleTask?.day?.dayName === "Overmorrow") {
      return "flex-end";
   }
};

export const finalStageAssets = {
   cookie1:
      "https://res.cloudinary.com/lara-ng/image/upload/v1663180727/lara%20beta/Fortune_cookie-1_xgb1z9.svg",
   diamond:
      "https://res.cloudinary.com/lara-ng/image/upload/v1663180780/lara%20beta/Jewel_claim_reward_yybxph.svg",
   check: "https://res.cloudinary.com/lara-ng/image/upload/v1663180817/lara%20beta/Checkmark_lj0xic.svg",
   flag: "https://res.cloudinary.com/lara-ng/image/upload/v1663180825/lara%20beta/Complete_task_jjcl47.svg",
};

export const getFinalStageData = (value) => {
   const allAvailableData = [
      {
         buttonText: "All tasks completed",
         buttonAsset: finalStageAssetsFiles.completeTask,
         msg: "Nicely done!",
         desc: "",
         cookie: finalStageAssetsFiles.fortuneCookieOne,
         route: "",
      },
      {
         buttonText: "Complete Task",
         buttonAsset: finalStageAssetsFiles.completeTask,
         msg: "What do you need to do? ",
         desc: "No points to earn today, check notifications if you left some behind",
         cookie: finalStageAssetsFiles.fortuneCookieOne,
         route: `/${USER}/${NOTIFICATION}`,
      },
      {
         buttonText: "Take me there",
         buttonAsset: finalStageAssetsFiles.takeMeThere,
         msg: "What do you need to do? ",
         desc: "Even though there are no tasks here for you today, it’s another day, another naira for the hustlers out there.",
         cookie: finalStageAssetsFiles.nairaHusle,
         route: `/${PLAY_AREA}`,
      },
      {
         buttonText: "Take me there",
         buttonAsset: finalStageAssetsFiles.takeMeThere,
         msg: "What do you need to do? ",
         desc: "At the end of the road is a fun place you are yet to discover. Discover beautiful places without getting lost with Lara.",
         cookie: finalStageAssetsFiles.fortuneCookieTwo,
         route: `/${PLAY_AREA}`,
      },
      {
         buttonText: "Plan a trip",
         buttonAsset: finalStageAssetsFiles.planATrip,
         msg: "What do you need to do? ",
         desc: "It’s a good day to waka, don’t sleep-don’t sleep, go out & collect your money.",
         cookie: finalStageAssetsFiles.fortuneCookieTwo,
         route: `/${PLAY_AREA}`,
      },
      {
         buttonText: "Tell a friend",
         buttonAsset: finalStageAssetsFiles.tellAFriend,
         msg: "What do you need to do? ",
         desc: "Calm down, your future don bright already",
         cookie: finalStageAssetsFiles.fortuneCookieTwo,
         route: `/${INVITE}`,
      },
      {
         buttonText: "Tell a friend",
         buttonAsset: finalStageAssetsFiles.tellAFriend,
         msg: "What do you need to do? ",
         desc: "Nothing to see here. Just a good day to tell a friend about Lara.",
         cookie: finalStageAssetsFiles.fortuneCookieTwo,
         route: `/${INVITE}`,
      },
      {
         buttonText: "Ask Lara now",
         buttonAsset: finalStageAssetsFiles.askLaraNow,
         msg: "What do you need to do? ",
         desc: "You can’t turn back the hand of time but you can get directions cos Lara is just a chat away.",
         cookie: finalStageAssetsFiles.fortuneCookieTwo,
         route: `/${PLAY_AREA}`,
      },
   ];

   return allAvailableData[value] ?? allAvailableData[1];
};
