import React from "react";
import { Link } from "react-router-dom";
import useInput from "../../../hooks/useInput";
import useOnboardingVerification from "../../../hooks/useOnboardingVerification";
import { REGISTER } from "../../../routes/Path";
import validators from "../../../Utilities/inputValidator";
import Container from "../../components/Wrappers/container/Container";
import OnboardingWrapperContainer from "../../components/Wrappers/OnboardingWrapperContainer/OnboardingWrapperContainer";
import InterestButton from "../../utilities/InterestsButton/InterestButton";
import Logo from "../../utilities/Logo/Logo";
import classes from "./UpdatePassword.module.css";

const UpdatePassword = () => {
   const {
      value: passwordValue,
      hasError: passwordHasError,
      inputBlurHandler: passwordInputBlurHandler,
      valueIsValid: passwordIsValid,
      valueInputChange: passwordValueInputChange,
   } = useInput(validators.checkPasswordIsValid);

   const { updatePassword } = useOnboardingVerification();

   const comparePassword = (value) => passwordValue === value;

   const {
      value: confirmPasswordValue,
      hasError: confrimPasswordHasError,
      inputBlurHandler: confirmPasswordInputBlurHandler,
      valueIsValid: confirmPasswordValueIsValid,
      valueInputChange: confirmPasswordValueInputChange,
   } = useInput(comparePassword);

   const isValid = passwordIsValid && confirmPasswordValueIsValid;

   const onUpdatePassword = (e, isValid) => {
      e.preventDefault();
      if (!isValid) {
         return;
      }

      updatePassword(passwordValue);
   };

   return (
      <OnboardingWrapperContainer>
         <Container className={classes.container}>
            <>
               <div className={classes.imgContainer}>
                  <Logo />
               </div>
               <div className={classes.info}>
                  <h2>Update Password</h2>
               </div>

               <form action="">
                  <div
                     className={`${classes.formGroup} ${
                        passwordHasError && classes.error
                     }`}
                  >
                     <label htmlFor="">Enter new password</label>
                     <input
                        type="password"
                        className={classes.formControl}
                        onChange={passwordValueInputChange}
                        onBlur={passwordInputBlurHandler}
                        value={passwordValue}
                     />
                     {passwordHasError && (
                        <span className={classes.errorText}>
                           Password should not be less than six characters{" "}
                        </span>
                     )}
                  </div>
                  <div
                     className={`${classes.formGroup} ${
                        confrimPasswordHasError && classes.error
                     }`}
                  >
                     <label htmlFor="">Confirm Password</label>
                     <input
                        type="password"
                        className={classes.formControl}
                        onChange={confirmPasswordValueInputChange}
                        onBlur={confirmPasswordInputBlurHandler}
                        value={confirmPasswordValue}
                     />
                     {confrimPasswordHasError && (
                        <span className={classes.errorText}>
                           Password mismatch{" "}
                        </span>
                     )}
                  </div>
                  <InterestButton
                     isValid={isValid}
                     confirmSelection={(e) => onUpdatePassword(e, isValid)}
                     className={classes.btn}
                  >
                     Update Password
                  </InterestButton>
               </form>
               <p className={classes.signup}>
                  Not registered? <Link to={`/${REGISTER}`}>Signup now</Link>{" "}
               </p>
            </>
         </Container>
      </OnboardingWrapperContainer>
   );
};

export default UpdatePassword;
