export const onboardingContainerVariants = {
   initial: { y: "100vh", opacity: 0 },
   animate: {
      y: 0,
      opacity: 1,
      transition: {
         when: "beforeChildren",
         type: "easeIn",
         duration: 0.5,
         staggerChildren: 0.1,
      },
   },
   exit: {
      opacity: 0,
      y: "-100vh",
      transition: {
         duration: 0.5,
      },
   },
};

export const onboardingInterestsContainerVariants = {
   initial: { x: "100vw", opacity: 0 },
   animate: {
      x: 0,
      opacity: 1,
      transition: {
         when: "beforeChildren",
         type: "easeIn",
         duration: 0.1,
         staggerChildren: 0.1,
      },
   },
   exit: {
      opacity: 0,
      x: "-100vw",
      transition: {
         duration: 0.1,
      },
   },
};

export const onboardingChangeDropdownToInput = {
   initial: { y: "100vh", opacity: 0 },
   animate: {
      y: 0,
      opacity: 1,
      transition: {
         when: "beforeChildren",
         type: "spring",
         duration: 0.3,
         staggerChildren: 0.5,
      },
   },
   exit: {
      opacity: 0,
      y: "-100vh",
      transition: {
         duration: 0.2,
      },
   },
};

export const OnboardingDropdownVariants = {
   initial: { y: "100vh", opacity: 0 },
   animate: {
      y: 0,
      opacity: 1,
      transition: {
         when: "beforeChildren",
         type: "easeIn",
         duration: 0.3,
         staggerChildren: 0.5,
      },
   },
   exit: {
      opacity: 0,
      y: "100vh",
      transition: {
         duration: 0.2,
         type: "easeOut",
      },
   },
};
