import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { directionActions } from "../redux/directionsSlice";
import useHttps from "./useHttps";
/*
The above code is requesting the user's location.
*/

const options = {
   enableHighAccuracy: true,
   timeout: 5000,
   maximumAge: 0,
};

const { setCurrentLocationData } = directionActions;

/**
 * It uses the `useCallback` hook to create a function that uses the `useDispatch` hook to dispatch
an action to the Redux store.
 * @returns The locator function.
 */
const useLocation = () => {
   const dispatch = useDispatch();
   const { sendRequest } = useHttps();
   const { addToast } = useToasts();

   const getAddressForCoordinates = (
      { fromLat, fromLong },
      successCallback,
      loadingCallback
   ) => {
      const config = {
         url: `coordinate/address?uid=aytgday&lat=${fromLat}&lng=${fromLong}`,
         method: "get",
      };

      const getSuccessCallback = (data) =>
         successCallback(data, { fromLat, fromLong });

      sendRequest(
         config,
         getSuccessCallback,
         loadingCallback,
         null,
         null,
         null
      );
   };

   const locator = useCallback(
      (successCallback, loadingCallback, type) => {
         if (!navigator.geolocation) {
            return addToast(`You don't seem to have this function available`, {
               appearance: "error",
               autoDismiss: true,
            });
         }

         loadingCallback && loadingCallback(true);

         const success = (position) => {
            const positionObj = {
               fromLat: position.coords.latitude,
               fromLong: position.coords.longitude,
            };

            if (type) {
               return successCallback(positionObj);
            }

            dispatch(setCurrentLocationData(positionObj));

            getAddressForCoordinates(
               positionObj,
               successCallback,
               loadingCallback
            );
         };

         const error = () => {
            switch (error.code) {
               case error.PERMISSION_DENIED:
                  addToast(
                     `Sorry, I can't get your location. You might have to grant me permission or turn on your GPS`,
                     {
                        appearance: "error",
                        autoDismiss: true,
                     }
                  );

                  break;
               case error.POSITION_UNAVAILABLE:
                  addToast(
                     `Sorry, I can't get your location. Your location information is unavailable. Please try again later or type a new query.`,
                     {
                        appearance: "error",
                        autoDismiss: true,
                     }
                  );
                  break;
               case error.TIMEOUT:
                  addToast(
                     `Sorry, I can't get your location. It's taking a long time to process. Please try again later.`,
                     {
                        appearance: "error",
                        autoDismiss: true,
                     }
                  );
                  break;
               case error.UNKNOWN_ERROR:
                  addToast(
                     `Sorry, I can't get your location at this time. Try typing a closeby landmark.`,
                     {
                        appearance: "error",
                        autoDismiss: true,
                     }
                  );
                  break;
            }
            loadingCallback && loadingCallback(false);
         };

         navigator.geolocation.getCurrentPosition(success, error, options);
      },
      [dispatch]
   );

   return {
      locator,
   };
};

export default useLocation;
