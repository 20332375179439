import React from "react";
import { useSelector } from "react-redux";
import { testDir } from "../../../../../assets/index";
import useAutoGeneratedFeedback from "../../../../../hooks/useAutoGeneratedFeedback";
import { capitalize } from "../../../../../Utilities/helper";
import { BackFromMaps } from "../../../../components/BackFromMaps/BackFromMaps";
import Card from "../../../../components/DirectionCard/DirectionCard";
import PlayAreaContainer from "../../../../components/Wrappers/PlayAreaContainer/PlayAreaContainer";
import classes from "./FlagDirection.module.css";
import ProblemDey from "./problemDey/ProblemDey";

const FlagDirection = () => {
   const {
      autoGeneratedFeedback: {
         autoGeneratedDirectionInformation,
         autoGeneratedFeedbackDirectionData,
      },
   } = useSelector((state) => state);

   const { problemDey, onSelectProblemDey, submitFinalFeedback } =
      useAutoGeneratedFeedback();

   return (
      <PlayAreaContainer>
         <>
            <BackFromMaps />
            {problemDey ? (
               <ProblemDey />
            ) : (
               <div className={classes.cardContainer}>
                  <div className={classes.modeOption}>
                     <img
                        src={`/assets/images/modes/${capitalize(
                           autoGeneratedDirectionInformation?.state
                        )}/${autoGeneratedDirectionInformation?.mode.toUpperCase()}.svg`}
                        alt={`${autoGeneratedDirectionInformation?.mode} icon`}
                     />
                     <p>USING {autoGeneratedDirectionInformation?.mode}</p>
                  </div>

                  {autoGeneratedFeedbackDirectionData?.path?.length > 0 && (
                     <Card
                        data={autoGeneratedFeedbackDirectionData}
                        isAutoFeedback={true}
                     />
                  )}

                  <div className={classes.reviewContainer}>
                     <div className={classes.btnContainer}>
                        <div
                           className={classes.btnDiv}
                           onClick={onSelectProblemDey}
                        >
                           <img src={testDir.problemDey} alt="" />
                           <button>Problem dey</button>
                        </div>

                        <div
                           className={classes.btnDiv}
                           onClick={submitFinalFeedback}
                        >
                           <img src={testDir.makeSense} alt="" />
                           <button>Make sense</button>
                        </div>
                     </div>
                  </div>
               </div>
            )}
         </>
      </PlayAreaContainer>
   );
};

export default FlagDirection;
