import React from 'react';
import Logo from '../Logo/Logo';
import classes from './LoginHeader.module.css';

const LoginHeader = () => {
   return (
      <div className={classes.widgetsContainer}>
         <h3>Login into</h3>
         <p>Beta. Lara.ng</p>
         <div className={classes.imgContainer}>
            <Logo />
         </div>
         <span>using</span>
      </div>
   );
};

export default LoginHeader;
