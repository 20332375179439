import { useEffect, useState, createRef } from "react";

const useElementRefs = (params = { dataset: [] }) => {
   const { dataset } = params;
   const [elementRefs, setElementRefs] = useState([]);
   const arrayLength = dataset?.length;

   useEffect(() => {
      if (arrayLength > 0) {
         const refArray = Array(arrayLength)
            .fill()
            .map((_, index) => elementRefs[index] || createRef());
         setElementRefs((state) => [...state, ...refArray]);
      }
   }, [arrayLength]);

   return {
      elementRefs,
   };
};

export default useElementRefs;
