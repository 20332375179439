import { motion } from "framer-motion";
import React from "react";
import { iconImages } from "../../../assets";
import { onboardingVideoAnimation } from "../../../assets/videos/Onboarding";
import {
   onboardingHomeVideoContainerVariants,
   onboardingHomeVideoImgVariants,
   onboardingHomeVideoTextVariants,
} from "./animationVariants";
import classes from "./OnboardingVideoContainer.module.css";

const OnboardingVideoContainer = () => {
   return (
      <motion.div
         variants={onboardingHomeVideoContainerVariants}
         initial="initial"
         animate="animate"
         className={classes.container}
      >
         <video
            muted
            src={onboardingVideoAnimation.bacgroundAnimation}
            autoPlay
            playsInline
            poster={iconImages.homescreenPlaceholder}
            crossOrigin="anonymous"
         ></video>
         <motion.div
            variants={onboardingHomeVideoTextVariants}
            className={classes.enterEmail}
         >
            <p>Start your waka with </p>
            <motion.img
               variants={onboardingHomeVideoImgVariants}
               height="40px"
               src={iconImages.laraLogoText}
            />
         </motion.div>
      </motion.div>
   );
};

export default OnboardingVideoContainer;
