export const shareContainerVariants = {
   initial: { opacity: 0, y: "100vh" },
   animate: {
      opacity: 1,
      y: "0vh",
      transition: {
         when: "beforeChildren",
         type: "easeIn",
         duration: 0.3,
         staggerChildren: 0.3,
      },
   },
   exit: {
      opacity: 0,
      y: "100vh",
      transition: {
         duration: 0.3,
      },
   },
};
