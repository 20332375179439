import { iconImages } from '../assets';

const devicesInfo = [
	{
		type: 'IOS',
		icon: iconImages.appleIosIconImg,
		downloadImg: iconImages.iosPwaImg,
		name: 'IOS',
	},
	{
		type: 'WINDOWS',
		icon: iconImages.windowsIonImg,
		downloadImg: iconImages.windowsPwaImg,
		name: 'Windows',
	},
	{
		type: 'ANDROID',
		icon: iconImages.androidIconImg,
		downloadImg: iconImages.androidPwaImg,
		name: 'Android',
	},
	{
		type: 'MACOS',
		icon: iconImages.appleIconImg,
		downloadImg: iconImages.iosPwaImg,
		name: 'MAC',
	},
];

export default devicesInfo;
