import React from "react";
import { useHistory } from "react-router";
import classes from "./LoginButton.module.css";

const LoginButton = () => {
   const history = useHistory();

   const redirectToLogin = () => {
      history.push(`/`);
   };
   return (
      <div className={classes.container}>
         <p>Need more directions like this?</p>
         <button onClick={redirectToLogin}>Login in to Beta.Lara</button>
      </div>
   );
};

export default LoginButton;
