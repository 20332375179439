import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import useDirection from "../../../hooks/useDirection";
import useMaps from "../../../hooks/useMaps";
import { directionActions } from "../../../redux/directionsSlice";
import { FROM_DIRECTION } from "../../../Utilities/mapTags";
import { ADD_FROM_DATA } from "../../../Utilities/types";
import SearchInput from "../SearchInput/SearchInput";
import LaraMessageContainer from "../Wrappers/LaraMessageContainer/LaraMessageContainer";
import classes from "./LocationInput.module.css";

const LocationInput = () => {
   const dispatch = useDispatch();
   const {
      directions: {
         isFoundLocation,
         routeInformation: { fromText, mode },
      },
   } = useSelector((state) => state);
   const { setMapTag } = useMaps();

   const getAvailableModes = (data) => {
      dispatch(directionActions.setShowModal(true));
      dispatch(directionActions.setAvailableModesData(data));
   };

   const getDataFromServer = useDirection();
   const containerRef = useRef();

   useEffect(() => {
      containerRef.current.scrollIntoView({
         behavior: "smooth",
         block: "center",
         inline: "center",
      });

      fromText && !mode && getDataFromServer(getAvailableModes);
   }, [fromText, mode]);

   const onSubmitFunction = (selectedList) => {
      const { lat, lng, stop_name } = selectedList;
      dispatch(
         directionActions.updateRouteInformation({
            fromText: stop_name,
            fromLat: lat,
            fromLong: lng,
         })
      );
   };

   const searchClassName = !isFoundLocation && classes.noLocationInput;

   return (
      <LaraMessageContainer className={classes.container}>
         <div className={classes.dropdownContainer} ref={containerRef}>
            <div className={classes.dropdownHeader}>
               {isFoundLocation && (
                  <label className={classes.checkContainer}>
                     <span>I have found your location</span>
                     <div className={classes.checked}>
                        <div className={classes.checkedInside}></div>
                     </div>
                  </label>
               )}

               <div className={classes.inputContainer}>
                  <SearchInput
                     onSubmitFunction={onSubmitFunction}
                     className={`${classes.searchInput} ${searchClassName}`}
                     propValue={fromText}
                     placeholder={`Type in a start location`}
                     type={ADD_FROM_DATA}
                     onFocus={() => setMapTag(FROM_DIRECTION)}
                  />
                  {!isFoundLocation && (
                     <img src={iconImages.locationSuggestionIconImg} alt="" />
                  )}
               </div>
            </div>
         </div>
      </LaraMessageContainer>
   );
};

export default LocationInput;
