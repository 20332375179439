import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { utilitiesActions } from "../redux/utilitiesSlice";
import cityInfo from "../Utilities/cityInfo";
import occupationInfo from "../Utilities/occupationInfo";
import useHttps from "./useHttps";

const useOnboardingInformation = () => {
   const dispatch = useDispatch();
   const { sendRequest, cancelRequest, setCancelRequest } = useHttps();
   const [data, setData] = useState([]);

   const configUrl = {
      city: "cities",
      commute: "commute/reason",
      occupation: "job/status",
      interests: "interest",
   };

   const setCityInformation = (data) => {
      const { cities } = data.data;
      const citiesInfo = cities.map((data) => {
         const getCityInfoData = getInfoData(cityInfo, data.name);
         return {
            name: data.name,
            id: data.id,
            imgUrl: getCityInfoData ? getCityInfoData.imgUrl : "",
            videoUrl: getCityInfoData ? getCityInfoData.videoUrl : "",
         };
      });

      setData(citiesInfo);
   };

   const setOccupationInformation = (data) => {
      const occupation = data.data;
      const occupationData = occupation.filter(
         (dataset) => dataset.status !== "Unemployed"
      );

      const job_statuses = occupationData.map((dataset) => {
         const getOccupationData = getInfoData(occupationInfo, dataset.status);

         const newDataSet = {
            name: dataset.status,
            id: dataset.id,
            imgUrl: getOccupationData ? getOccupationData.imgUrl : "",
            videoUrl: getOccupationData ? getOccupationData.videoUrl : "",
         };
         return newDataSet;
      });

      setData(job_statuses);
   };

   const setInterestInformation = (data) => {
      const interestData = data.data ?? [];
      const sortedData = interestData.sort((a, b) => a?.id - b?.id);
      setData(sortedData);
   };

   const setCommuteInformation = (data) => {
      const commuteData = data.data;
      let newData = commuteData.map((dataset) => ({
         ...dataset,
         name: dataset.reason,
      }));
      setData(newData);
   };

   const setLoadingFunction = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   const getInfoData = (array, data) =>
      array.find(
         (info) => info.name.toUpperCase().trim() === data.toUpperCase().trim()
      );

   const getJobStatus = (showLoading) => {
      const config = {
         url: configUrl.occupation,
         method: "get",
      };

      const loadingFunc = showLoading ? setLoadingFunction : null;

      sendRequest(
         config,
         setOccupationInformation,
         loadingFunc,
         null,
         null,
         null
      );
   };

   const getCommuteReason = () => {
      const config = {
         url: configUrl.commute,
         method: "get",
      };

      sendRequest(
         config,
         setCommuteInformation,
         setLoadingFunction,
         null,
         null
      );
   };

   const getInterests = () => {
      const config = {
         url: configUrl.interests,
         method: "get",
      };

      sendRequest(
         config,
         setInterestInformation,
         setLoadingFunction,
         null,
         null
      );
   };

   const getCities = useCallback(() => {
      const config = {
         url: configUrl.city,
         method: "get",
      };

      sendRequest(config, setCityInformation, setLoadingFunction, null, null);
   }, []);

   return {
      data,
      getCities,
      getInterests,
      getCommuteReason,
      getJobStatus,
      cancelRequest,
      setCancelRequest,
   };
};

export default useOnboardingInformation;
