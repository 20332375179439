import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { userNotificationActions } from "../redux/userNotificationSlice";
import useHttps from "./useHttps";

const useReminder = () => {
   const dispatch = useDispatch();
   const { addToast } = useToasts();
   const { sendRequest } = useHttps();
   const config = {
      method: "patch",
      url: "user/set/task/reminder",
   };

   const loader = (value) =>
      dispatch(userNotificationActions.setLoading(value));

   const successCallback = useCallback((data) => {
      addToast(data.message ?? "Successfully set reminder.", {
         appearance: "success",
         autoDismiss: true,
      });
   }, []);

   const setReminder = (data, callOnSuccess) => {
      const postConfig = {
         ...config,
         body: data,
      };

      sendRequest(postConfig, successCallback, loader, callOnSuccess);
   };

   return {
      setReminder,
   };
};

export default useReminder;
