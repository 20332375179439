import PropTypes from "prop-types";
import React from "react";
import classes from "./PlayAreaContainer.module.css";

const PlayAreaContainer = (props) => {
   return (
      <div className={classes.container} ref={props.containerRef}>
         {props.children}
      </div>
   );
};

PlayAreaContainer.propTypes = {
   className: PropTypes.string,
   children: PropTypes.element,
   containerRef: PropTypes.any,
};

export default PlayAreaContainer;
