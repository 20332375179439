export const onboardingHomeVideoContainerVariants = {
	initial: { scale: 1.5 },
	animate: {
		scale: 1,
		transition: { duration: 1, ease: 'easeInOut', when: 'beforeChildren' },
	},
};

export const onboardingHomeVideoTextVariants = {
	initial: { y: '10vh' },
	animate: {
		y: 0,
		transition: {
			type: 'easeInOut',
			duration: 1.5,
			when: 'beforeChildren',
		},
	},
};

export const onboardingHomeVideoImgVariants = {
	initial: { y: '10vh', opacity: 0, zIndex: 0 },
	animate: {
		y: 0,
		opacity: 1,
		zIndex: 1,
		transition: {
			stiffness: 120,
		},
	},
};
