import React from 'react';
import classes from './Container.module.css';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

const Container = (props) => {
	const { variants, className, children } = props;
	return (
		<motion.div
			variants={variants ? variants : {}}
			animate='animate'
			initial='initial'
			exit='exit'
			className={`${classes.container} ${className}`}
		>
			{children}
		</motion.div>
	);
};

Container.propTypes = {
	className: PropTypes.string,
	children: PropTypes.element,
	variants: PropTypes.object,
};

export default Container;
