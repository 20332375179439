import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { levelBadgesImages, plateBadgesImages } from "../assets";
import { levelNames } from "../data/userNicknames";
import { userSliceActions } from "../redux/userSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import { INTERESTS, OCCUPATION, USER } from "../routes/Path";
import cityInfo from "../Utilities/cityInfo";
import { levelPoints } from "../Utilities/levelPoints";
import useAuth from "./useAuth";
import useHttps from "./useHttps";

const useSetUserInformation = () => {
   const {
      user: { userInformation: userInfo },
   } = useSelector((state) => state);

   const dispatch = useDispatch();
   const { sendRequest } = useHttps();
   const history = useHistory();
   const { firstName } = userInfo;
   const { setIsLogin } = useAuth();

   const config = {
      url: "user",
      method: "get",
   };
   const LoadingStateFunction = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   const getUserInformation = async () =>
      await sendRequest(config, setUserInformation, LoadingStateFunction, null);

   const setInterests = (data) => {
      dispatch(userSliceActions.setInterestsInfo(data.data));
   };
   const getInterestInfo = () => {
      const config = {
         url: "interest",
         method: "get",
      };
      sendRequest(config, setInterests, LoadingStateFunction, null);
   };

   const updateUserInformation = ({ body, url }, successCallback) => {
      const updateConfig = { ...config, url, method: "PATCH", body };

      const updateUserCallback = async (data) => {
         successCallback(data);
         setUserInformation(data);
      };

      sendRequest(
         updateConfig,
         updateUserCallback,
         LoadingStateFunction,
         null,
         null,
         null
      );
   };

   const checkCity = (city) =>
      city && cityInfo.map((element) => element.name).includes(city);

   const setUserInformation = ({ data }) => {
      const { level, city } = data;
      const name = nameSplit(data.name);

      if (!city || !checkCity(city)) {
         dispatch(
            userSliceActions.setUserInformation({
               ...data,
               ...name,
            })
         );
         return history.push(`/${USER}/${INTERESTS}/${OCCUPATION}`);
      }

      if (data?.home) {
         const homeData = JSON.parse(data.home);
         addHomeInfo(homeData);
      }

      if (data?.work) {
         const workData = JSON.parse(data.work);
         addWorkInfo(workData);
      }

      const userPoints = data.user_tasks.shift().total_points;
      const userTasks = data.user_tasks;

      const getStateBadges = plateBadgesImages.find(
         (data) => data.state.toUpperCase() === city.toUpperCase()
      )[`level${level}`];

      const userNickName = levelNames[city.toUpperCase()][level];

      const setUserLevelInformation = {
         levelBadgeImage: levelBadgesImages[`level${level}BadgeImg`],
         stateBadgeImage: getStateBadges,
         totalPoints: levelPoints[level],
         userNickName,
      };
      const user = {
         ...data,
         ...setUserLevelInformation,
         user_tasks: userTasks,
         userPoints,
         ...name,
      };

      setIsLogin();

      dispatch(userSliceActions.setUserInformation(user));
   };

   const addWorkInfo = (data) => {
      dispatch(userSliceActions.setEditedWorkInfo(data));
   };

   const addHomeInfo = (data) => {
      dispatch(userSliceActions.setEditedHomeInfo(data));
   };

   const nameSplit = (name) => {
      const splitName = name.split(" ");
      const firstName = splitName[0];
      const lastName = splitName[1];
      return { firstName, lastName };
   };

   return {
      getUserInformation,
      firstName,
      updateUserInformation,
      addWorkInfo,
      addHomeInfo,
      getInterestInfo,
   };
};

export default useSetUserInformation;
