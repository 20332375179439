import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useAutoGeneratedFeedback from "../../../hooks/useAutoGeneratedFeedback";
import AutoGeneratedFeedbackSelect from "../../components/autoGeneratedFeedbackSelect/AutoGeneratedFeedbackSelect";
import AutoGeneratedFeedbackThanks from "../../components/autogeneratedFeedbackThanks/AutoGeneratedFeedbackThanks";
import DirectionLoader from "../../components/DirectionLoader/DirectionLoader";
import FeedbackOnRoute from "../../components/FeedbackOnRoute/FeedbackOnRoute";
import GenericButton from "../../components/genericButton/GenericButton";
import NewModeDesign from "../../components/NewModalDesign/NewModeDesign";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./AutoGeneratedFeedbackTask.module.css";

const AutoGeneratedFeedbackTask = () => {
   const {
      directions: { stopByLga, loading },
      autoGeneratedFeedback: {
         selectedLgas,
         isAutoGeneratedFeedback: {
            showThanks,
            showFeedbackTextArea,
            showFeedbackMode,
         },
      },
   } = useSelector((state) => state);

   const { getLgaStopsForAutoGeneratedFeedback, postSelectedLga } =
      useAutoGeneratedFeedback();

   useEffect(() => {
      stopByLga.length === 0 && getLgaStopsForAutoGeneratedFeedback();
   }, []);

   const switchBetweenComponents = () => {
      switch (true) {
         case showFeedbackMode:
            return <NewModeDesign />;

         case showThanks:
            return <AutoGeneratedFeedbackThanks />;
         case showFeedbackTextArea:
            return <FeedbackOnRoute />;
         default:
            return (
               <>
                  <DirectionsContainerWrapper>
                     <div className={classes.container}>
                        <h2>Let’s pick out places you know.</h2>
                        <p>
                           {" "}
                           Please Select 3 LGAs you are most familiar with.{" "}
                        </p>
                        <AutoGeneratedFeedbackSelect
                           ulClassName={classes.ul}
                           places={stopByLga}
                        />

                        <div className={classes.seeMore}>
                           <button
                              onClick={getLgaStopsForAutoGeneratedFeedback}
                           >
                              See more Areas
                           </button>
                        </div>
                     </div>
                  </DirectionsContainerWrapper>
                  {loading && <DirectionLoader />}

                  <div className={classes.reviewContainer}>
                     {selectedLgas.length >= 3 ? (
                        <div className={classes.continueContainer}>
                           <button
                              onClick={postSelectedLga}
                              className={`${classes.continue} ${classes.active}`}
                           >
                              Continue
                           </button>
                        </div>
                     ) : (
                        <GenericButton ButtonText="Skip to Directions" />
                     )}
                  </div>
               </>
            );
      }
   };

   return <>{switchBetweenComponents()} </>;
};

export default AutoGeneratedFeedbackTask;
