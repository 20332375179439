import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconImages, levelBadgesImages } from "../../../../assets";
import { utilitiesActions } from "../../../../redux/utilitiesSlice";
import classes from "./CompletedTaskNotification.module.css";

const CompletedTaskNotification = () => {
   const {
      utilities: {
         taskCompletion: { points, headerText, subText },
      },
   } = useSelector((state) => state);
   const dispatch = useDispatch();

   const onDone = useCallback(() => {
      dispatch(utilitiesActions.setShowModal(false));
      dispatch(
         utilitiesActions.setTaskCompletion({
            isCompleted: false,
         })
      );
   }, [dispatch]);

   return (
      <>
         <div className={classes.modalHeader}>
            <img
               className={classes.checkImg}
               src={iconImages.taskCompleteActionImg}
               alt=""
            />
            <p className={classes.modalText}>Task Completed</p>
         </div>
         <div className={classes.modalBody}>
            <p>{headerText}</p>
            <p>{subText}</p>

            <div className={classes.pointsContainer}>
               <img src={levelBadgesImages.level1BadgeImg} alt="" />
               <p>{points}XP GAINED</p>
            </div>
            <button onClick={onDone} className={classes.buttonDone}>
               DONE
            </button>
         </div>
      </>
   );
};

export default CompletedTaskNotification;
