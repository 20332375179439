import React from "react";
import Locatedmap from "../locatedmap/locatedmap";
import classes from "./invitelogic.module.css";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";
import { INVITE_ANSWER, INVITE_QUESTION } from "../../../../routes/Path";
import Invitequestion from "./invitequestion/invitequestion";
import Inviteanswer from "./inviteanswer/inviteanswer";

const Invitelogic = () => {
   const { path } = useRouteMatch();
   return (
      <div className={classes.inappInvitationContainer}>
         <Locatedmap />

         <h2>Invite a Friend to Lara Beta City expansion</h2>
         <Switch>
            <Route
               path={`${path}/${INVITE_QUESTION}`}
               component={Invitequestion}
               exact
            />
            <Route
               path={`${path}/${INVITE_ANSWER}`}
               component={Inviteanswer}
               exact
            />
            <Route path="*">
               <Redirect to={`${path}/${INVITE_QUESTION}`} />
            </Route>
         </Switch>
      </div>
   );
};

export default Invitelogic;
