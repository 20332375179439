import React from "react";
import { useSelector } from "react-redux";
import DirectionLoader from "../../components/DirectionLoader/DirectionLoader";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import FeedbackOnApp from "./FeedbackOnApp";
import LeaveFeedbackTask from "./LeaveFeedbackTask";
import classes from "./LeaveFeedbackTask.module.css";

const LeaveFeedbackTaskContainer = () => {
   const {
      directions: { loading },
      tasks: {
         appropriateTask: { showFeedbackTask },
      },
   } = useSelector((state) => state);
   return (
      <>
         <DirectionsContainerWrapper className={classes.feedbackContainer}>
            {showFeedbackTask ? <LeaveFeedbackTask /> : <FeedbackOnApp />}
         </DirectionsContainerWrapper>
         {loading && <DirectionLoader />}
         <GenericButton ButtonText="Back to directions" />
      </>
   );
};

export default LeaveFeedbackTaskContainer;
