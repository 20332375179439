import React from "react";
import { useHistory } from "react-router";
import { iconImages } from "../../../assets";
import classes from "./BackFromMaps.module.css";

export const BackFromMaps = () => {
   const history = useHistory();
   return (
      <button onClick={() => history.goBack()} className={classes.button}>
         <img src={iconImages.leftArrowIcon} />
      </button>
   );
};
