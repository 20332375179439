import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { userSliceActions } from "../redux/userSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import { getit, setit } from "../Utilities/helper";
import useHttps from "./useHttps";
import useSetUserInformation from "./useSetUserInformation";

const usePushNotifcation = () => {
   const { sendRequest } = useHttps();
   const { addToast } = useToasts();
   const isPushShown = getit("isPushShown");
   const {
      user: { userInformation },
   } = useSelector((state) => state);
   const [loading, setLoading] = useState(false);
   const { updateUserInformation } = useSetUserInformation();

   const dispatch = useDispatch();

   const subscribeToPush = async (is_active) => {
      if ("serviceWorker" in navigator) {
         try {
            const sw = await navigator.serviceWorker.ready;
            let push = await sw.pushManager.subscribe({
               userVisibleOnly: true,
               // eslint-disable-next-line no-undef
               applicationServerKey: process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY,
            });

            const response = JSON.stringify(push);
            const subscription_info = JSON.parse(response);
            const body = { subscription_info, is_active };
            updatePushSubscription(body);
         } catch (error) {
            addToast(
               "Kindly give your permission to receive push notifications from your browser",
               { appearance: "error", autoDismiss: true }
            );
         }
      }
   };

   const updatePushSubscription = (body) => {
      const config = {
         method: "post",
         url: "push/notifications/subscribe",
         body,
      };
      const successCallback = ({ data }) => {
         const userInfo = { ...userInformation, ...data };
         dispatch(userSliceActions.setUserInformation(userInfo));
         toggleModal(false);
      };

      sendRequest(config, successCallback, setLoading);
   };

   const toggleModal = (value) => {
      dispatch(utilitiesActions.setShowModal(value));
      dispatch(utilitiesActions.setShowPushModal(value));
      setit("isPushShown", true, 360);
   };

   const handleEmailToggle = (value) => {
      const successCallback = ({ data }) => {
         const userInfo = { ...userInformation, ...data };
         dispatch(userSliceActions.setUserInformation(userInfo));
      };

      sendRequest(
         {
            url: `email/notifications/subscribe`,
            method: "post",
            body: {
               sub: value ? 1 : 2,
            },
         },
         successCallback,
         setLoading
      );
   };

   const updateSmsInformation = (body) => {
      const config = {
         body,
         url: "user/profile/edit",
      };

      const updateSuccess = () => {
         dispatch(utilitiesActions.setShowModal(true));
         dispatch(
            utilitiesActions.setSuccess({
               successText: "Contact Updated Successfully",
               isSuccessFull: true,
            })
         );
      };

      updateUserInformation(config, updateSuccess);
   };

   const showPushNotification = () => {
      if (
         "serviceWorker" in navigator &&
         !isPushShown &&
         userInformation.city
      ) {
         toggleModal(true);
      }
   };

   return {
      subscribeToPush,
      showPushNotification,
      toggleModal,
      loading,
      handleEmailToggle,
      updateSmsInformation,
   };
};

export default usePushNotifcation;
