import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useSetRouteLogic from "../../../../hooks/useSetRouteLogic";
import { REGISTER } from "../../../../routes/Path";
import useGoogleSocialLogin from "../../../../socialLogins/GoogleSocialLogin";
import Loginwithemail from "../../../components/LoginWithEmail/Loginwithemail";
import SocialLogin from "../../../components/Sociallogins/SocialLogin";
import Container from "../../../components/Wrappers/container/Container";
import OnboardingWrapperContainer from "../../../components/Wrappers/OnboardingWrapperContainer/OnboardingWrapperContainer";
import ContinueWith from "../../../sharedcomponents/ContinueWith/ContinueWith";
import LoginHeader from "../../../utilities/LoginHeader/LoginHeader";
import classes from "./OnboardingLogin.module.css";

const OnboardingLogin = () => {
   const { signIn } = useGoogleSocialLogin();
   const { redirectToDashboard } = useSetRouteLogic();

   useEffect(() => {
      redirectToDashboard();
   }, [redirectToDashboard]);

   return (
      <OnboardingWrapperContainer>
         <Container className={classes.loginContainer}>
            <>
               <LoginHeader />
               <div className={classes.widgets}>
                  <SocialLogin googleClickHandler={signIn} login={true} />
               </div>
               <ContinueWith />
               <div className={classes.formContainer}>
                  <Loginwithemail />
               </div>
               <motion.p className={classes.signup}>
                  Not registered? <Link to={`/${REGISTER}`}>Signup now</Link>{" "}
               </motion.p>
               <p className={classes.terms}>
                  Use of this app is subject to out{" "}
                  <a
                     href="https://beta.lara.ng/assets/End%20User%20Beta%20Testing%20Agreement.docx.pdf"
                     target="_blank"
                     rel="noreferrer"
                  >
                     Terms
                  </a>{" "}
                  &{" "}
                  <a
                     href="https://beta.lara.ng/assets/Lara.ng%20Privacy%20Policy%20170521.docx.pdf"
                     target="_blank"
                     rel="noreferrer"
                  >
                     {" "}
                     Privacy policy
                  </a>
               </p>
            </>
         </Container>
      </OnboardingWrapperContainer>
   );
};

export default OnboardingLogin;
