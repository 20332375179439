import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMaps from "../../../../hooks/useMaps";
import usePoi from "../../../../hooks/usePoi";
import { directionActions } from "../../../../redux/directionsSlice";
import { getit } from "../../../../Utilities/helper";
import { TO_DIRECTION } from "../../../../Utilities/mapTags";
import { ADD_TO_DATA } from "../../../../Utilities/types";
import SearchInput from "../../../components/SearchInput/SearchInput";
import PoiData from "../../../utilities/Preferences/PoiData";
import classes from "./FunPlaces.module.css";

const FunPlaces = () => {
   const dispatch = useDispatch();
   const token = getit("lara_token");
   const { getPoiDataForFunPlaces } = usePoi();
   const {
      directions: {
         poiData,
         routeInformation: { toText },
      },
      tasks: { maximize },
   } = useSelector((state) => state);
   const { setMapTag } = useMaps();

   const onSubmitFunction = (selectedList) => {
      const { lat, lng, stop_name } = selectedList;
      dispatch(
         directionActions.updateRouteInformation({
            toText: stop_name,
            toLat: lat,
            toLong: lng,
         })
      );
   };

   useEffect(() => {
      const identifier = setTimeout(() => {
         token && !poiData[0]?.name && getPoiDataForFunPlaces();
      }, 1);

      return () => {
         clearTimeout(identifier);
      };
   }, []);

   return (
      <div className={classes.container}>
         <h2>Visit a fun place closest to you</h2>
         <p>Find an exciting place to vibe</p>
         {!toText && (
            <div className={classes.funPlaces}>
               <PoiData text="Here are a few" poiData={poiData} />
            </div>
         )}

         {maximize && (
            <SearchInput
               className={classes.input}
               placeholder={"Try your own searches"}
               onSubmitFunction={onSubmitFunction}
               type={ADD_TO_DATA}
               onFocus={() => setMapTag(TO_DIRECTION)}
            />
         )}
      </div>
   );
};

export default FunPlaces;
