import React from "react";
import PropTypes from "prop-types";
import classes from "./InviteContainer.module.css";

const InviteContainer = (props) => {
   const { children } = props;
   return <div className={classes.container}>{children}</div>;
};

InviteContainer.propTypes = {
   children: PropTypes.element,
};

export default InviteContainer;
