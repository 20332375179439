import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTask from "../../../hooks/useTask";
import { directionActions } from "../../../redux/directionsSlice";
import { utilitiesActions } from "../../../redux/utilitiesSlice";
import classes from "./LaraWidgets.module.css";

const LaraWidgets = () => {
   const {
      directions: { routeInformation },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const { choseAppropriateTask } = useTask();

   const getReturnDirections = useCallback(() => {
      const { toText, toLat, toLong, fromLat, fromLong, fromText } =
         routeInformation;

      const returnData = {
         fromLat: toLat,
         fromLong: toLong,
         fromText: toText,
         toLat: fromLat,
         toLong: fromLong,
         toText: fromText,
         mode: null,
      };

      dispatch(directionActions.updateRouteInformation(returnData));
      dispatch(directionActions.setShowModal(false));
      dispatch(directionActions.setDirectionsData({}));
   }, [routeInformation]);

   const getNewDirections = useCallback(() => {
      dispatch(directionActions.setShowModal(false));
      dispatch(directionActions.setShowAskUserLocation(false));
      dispatch(directionActions.clearRouteInformation());
      dispatch(directionActions.setDirectionsData({}));
      dispatch(directionActions.setIsFoundLocation(false));
   }, [dispatch]);

   const setApporioateWidget = (identifier) => {
      choseAppropriateTask(identifier);
      dispatch(utilitiesActions.setFromWidget(true));
   };

   const onShare = () => {
      dispatch(directionActions.setShareDirection(true));
   };

   return (
      <div className={classes.container}>
         <div>
            <p>What would you like to do next?</p>
            <div className={classes.LaraWidgets}>
               <button onClick={() => setApporioateWidget(2)}>
                  <span>Rate trip</span>
               </button>
               <button onClick={onShare}>
                  <span>Share direction</span>
               </button>
               <button onClick={getReturnDirections}>
                  <span>Get return directions</span>
               </button>
               <button onClick={getNewDirections}>
                  <span>Get new directions</span>
               </button>
               <button onClick={() => setApporioateWidget(7)}>
                  <span>Leave Feedback</span>
               </button>
               <button onClick={() => setApporioateWidget(3)}>
                  <span>Download Lara</span>
               </button>
            </div>
         </div>
      </div>
   );
};

export default LaraWidgets;
