import { useDispatch } from "react-redux";
import { saveTripActions } from "../redux/saveTripSlice";
import { createUserid } from "../Utilities/helper";
import useHttps from "./useHttps";
import useLoader from "./useLoader";
import useTask from "./useTask";

const useSaveTrip = () => {
   const { sendRequest } = useHttps();
   const dispatch = useDispatch();
   const { submitTask } = useTask();

   const { directionLoadingCallback } = useLoader();

   const convertTripsData = (data) =>
      data.map((trip) => {
         let path = [];

         const splittedDirection = trip.direction.split(">>");
         splittedDirection.forEach((element) => {
            const parsedData = JSON.parse(element);
            parsedData.state = parsedData.result[0].state;
            path.push(parsedData);
         });

         const mode = path[0].result[0].mode;
         const state = path[0].state;
         const search_id = path[0].search_id;

         const newTrip = {
            ...trip,
            path,
            mode,
            state,
            uid: createUserid(),
            search_id,
         };

         return newTrip;
      });

   const getUserTrips = () => {
      const config = {
         url: "user/search/result/success",
         method: "get",
      };

      const successCallback = (data) => {
         const newData = convertTripsData(data.data);
         dispatch(saveTripActions.showUserTrips(newData));
         showSelectedtrip("showTripSelection");
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const getSavedTrips = () => {
      const config = {
         url: "saved/route",
         method: "get",
      };

      const successCallback = (data) => {
         const newData = convertTripsData(data.data);
         dispatch(saveTripActions.setSavedTrips(newData));
         showSelectedtrip("showSaveTripsList");
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const selectTrip = (trip) => {
      dispatch(saveTripActions.setSingleTrip(trip));
   };

   const showSelectedtrip = (identifier) => {
      dispatch(saveTripActions.selectSaveTripOptions(identifier));
   };

   const saveTrip = (body) => {
      const config = {
         url: "search/saves",
         method: "post",
         body,
      };

      const successCallback = () => {
         showSelectedtrip("tripSaved");
         submitTask();
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const onStartSaveTrip = () => {
      dispatch(saveTripActions.setStartSaveTrip(true));
   };

   return {
      getUserTrips,
      selectTrip,
      showSelectedtrip,
      saveTrip,
      getSavedTrips,
      onStartSaveTrip,
   };
};

export default useSaveTrip;
