import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   loading: false,
   rewards: [],
   rewardPoint: 0,
   gainedPoint: 200,
   reward: {},
};

const rewardsSlice = createSlice({
   name: "rewards",
   initialState,
   reducers: {
      setLoading(state, { payload }) {
         state.loading = payload;
      },
      setRewards(state, { payload }) {
         state.rewards = payload;
      },
      setRewardPoint(state, { payload }) {
         state.rewardPoint = payload;
      },
      setGainedPoint(state, { payload }) {
         state.gainedPoint = payload;
      },

      setReward(state, { payload }) {
         state.reward = payload;
      },
   },
});

export const rewardsActions = rewardsSlice.actions;
export const rewardsReducer = rewardsSlice.reducer;

export default rewardsSlice;
