import React from "react";
import { useSelector } from "react-redux";
import DirectionLoader from "../../components/DirectionLoader/DirectionLoader";
import GenericButton from "../../components/genericButton/GenericButton";
import DirectionsContainerWrapper from "../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import Newfeature from "../taskpreviews/newFeature/Newfeature";
import classes from "./RecommendANewfeatureTask.module.css";

const RecommendANewFeatureTask = () => {
   const {
      directions: { loading },
   } = useSelector((state) => state);
   return (
      <>
         <DirectionsContainerWrapper className={classes.container}>
            <Newfeature />
         </DirectionsContainerWrapper>
         {loading && <DirectionLoader />}
         <GenericButton ButtonText="Get directions" />
      </>
   );
};

export default RecommendANewFeatureTask;
