import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { userNotificationActions } from "../../../redux/userNotificationSlice";
import ModalContainer from "../Wrappers/ModalContainer/ModalContainer";
import Notificationaction from "./notificationaction/notificationaction";
import classes from "./Notificationtab.module.css";
import Remindmelater from "./remindmelater/Remindmelater";

const Notificationtab = () => {
   const {
      notification: { remindMe },
   } = useSelector((state) => state);

   const dispatch = useDispatch();
   const closeNotfication = () => {
      dispatch(userNotificationActions.setShowNotification(false));
      dispatch(userNotificationActions.setShowRemindmeLater(false));
   };
   return (
      <ModalContainer className={classes.container}>
         <div className={classes.notificationDropdown}>
            <div className={classes.slider} onClick={closeNotfication}>
               <div></div>
            </div>

            {!remindMe ? <Notificationaction /> : <Remindmelater />}
         </div>
      </ModalContainer>
   );
};

export default Notificationtab;
