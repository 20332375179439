import React, { useState } from "react";
import classes from "../FlagDirection.module.css";
import PropTypes from "prop-types";
import useAutoGeneratedFeedback from "../../../../../../hooks/useAutoGeneratedFeedback";

const DirectionCard = ({ result }) => {
   const [toggle, setToggle] = useState(false);
   const { selectBadDirection } = useAutoGeneratedFeedback();

   const handleToggle = (result) => {
      setToggle(!toggle);
      selectBadDirection(result);
   };

   return (
      <div
         className={classes.directionCard}
         onClick={() => handleToggle(result)}
      >
         <div className={classes.location_price}>
            <p>From: {result.going_from}</p>
            <p>Heading Towards: {result.heading_towards} </p>
            <p>Get off at: {result.drop_at}</p>
            <p className={classes.white}>
               Price: N{result.min_fare} - N{result.max_fare}
            </p>
         </div>
         <div className={classes.checkMark}>
            <p
               className={classes.toggle}
               style={toggle ? { background: "#69af8a" } : {}}
            ></p>
         </div>
      </div>
   );
};

DirectionCard.propTypes = {
   result: PropTypes.object,
};

export default DirectionCard;
