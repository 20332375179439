import { POIs } from "../assets";

export const PoiTaskLabel = [
   {
      tag: "PLACE",
      title: "Add a missing Place",
      nameLabel: "Name",
      cityLabel: "City",
      lgaLabel: "Area/LGA",
      fullAddressLabel: "Full Address",
      icon: POIs.addMissingPlaceIcon,
   },
   {
      tag: "STREET",
      title: "Add a missing Street/road",
      nameLabel: "Street Name",
      cityLabel: "City",
      lgaLabel: "Area/LGA",
      fullAddressLabel: "Major Street nearby ",
      icon: POIs.addMissingRoadIcon,
   },
];
