import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { iconImages } from "../../../../assets";
import useSetUserInformation from "../../../../hooks/useSetUserInformation";
import { INVITE, INVITE_LOGIC, PLAY_AREA } from "../../../../routes/Path";
import classes from "./invitehome.module.css";

const Invitehome = () => {
   const {
      user: {
         userInformation: { firstName },
      },
   } = useSelector((state) => state);
   const { getUserInformation } = useSetUserInformation();
   const handleTimeOfDay = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 0 && currentHour < 12) {
         return "Good Morning";
      } else if (currentHour >= 12 && currentHour < 17) {
         return "Good Afternoon";
      } else {
         return "Good Evening";
      }
   };
   useEffect(() => {
      getUserInformation();
   }, []);

   return (
      <>
         <div className={classes.home}>
            <div className={classes.name}>
               <h3>
                  {handleTimeOfDay()} {firstName}, <b></b>
               </h3>
            </div>

            <div className={classes.imgContainer}>
               <video
                  src={iconImages.movingcity}
                  autoPlay
                  playsInline
                  crossOrigin="anonymous"
                  muted
                  loop
               ></video>
            </div>

            <div className={classes.text}>
               <h2>Lara needs your help</h2>
               <p>As she expands into other key Nigerian States.</p>
               <p>Can you help her grow by inviting your friends?</p>
            </div>

            <div className={classes.backToPlayArea}>
               <Link to={`/${PLAY_AREA}`}>
                  <span>Not now, continue to directions</span>
               </Link>
            </div>
         </div>
         <Link to={`/${INVITE}/${INVITE_LOGIC}`} className={classes.share}>
            <p>
               COUNT ME IN <img src={iconImages.countmeInvite} alt="" />
            </p>
         </Link>
      </>
   );
};

export default Invitehome;
