import React from 'react';
import Aboutbetatest from '../../../components/Aboutbetatest/Aboutbetatest';
import Startyourwaka from '../../../components/Startyourwaka/Startyourwaka';
import classes from './OnboardingAbout.module.css';
import { motion } from 'framer-motion';

const OnboardingAbout = () => {
	return (
		<motion.div className={classes.container}>
			<Aboutbetatest />
			<Startyourwaka />
		</motion.div>
	);
};

export default OnboardingAbout;
