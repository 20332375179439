import { AnimatePresence, motion } from "framer-motion";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import ModalContainer from "../../components/Wrappers/ModalContainer/ModalContainer";
import { modalVariant } from "../../components/Wrappers/ModalContainer/modalContainerVariants";
import CompletedTaskNotification from "./CompletedTaskNotification/CompletedTaskNotification";
import NewbadgeNotification from "./NewbadgeNotfication/NewbadgeNotification";
import classes from "./NotificationModal.module.css";
import SubscribeToPush from "./SubscribeToPush/SubscribeToPush";
import SuccessNotification from "./SuccessNotification/SuccessNotification";

const NotificationModal = () => {
   const {
      utilities: {
         levelUpdate: { islevelChanged },
         success: { isSuccessFull },
         taskCompletion: { isCompleted },
         showPushModal,
      },
   } = useSelector((state) => state);

   return (
      <ModalContainer>
         <AnimatePresence>
            <motion.div variants={modalVariant} className={classes.container}>
               {islevelChanged && <NewbadgeNotification />}
               {isSuccessFull && <SuccessNotification />}

               {isCompleted && <CompletedTaskNotification />}
               {showPushModal && <SubscribeToPush />}
            </motion.div>
         </AnimatePresence>
      </ModalContainer>
   );
};

export default memo(NotificationModal);
