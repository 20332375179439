/**
 * This function is used to render the modal which is used to select the mode of transportation.
 * @returns The mode preference modal.
 */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from "react";
import { createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDirection from "../../../hooks/useDirection";
import useTask from "../../../hooks/useTask";
import { directionActions } from "../../../redux/directionsSlice";
import { capitalize, getAvailableModes } from "../../../Utilities/helper";
import Container from "../Wrappers/container/Container";
import classes from "./ModePreference.module.css";

const ModePreference = () => {
   const {
      directions: {
         availableModesData,
         routeInformation: { mode: modeStore },
      },
   } = useSelector((state) => state);
   const { submitTask } = useTask();
   const [value, setValue] = useState(modeStore ?? "");
   const dispatch = useDispatch();
   const { modes, state } = getAvailableModes(availableModesData);
   const getDirectionFromServer = useDirection();
   const ref = createRef();

   const getDirectionData = ({ data }) => {
      dispatch(directionActions.setDirectionsData(data));
      submitTask();
   };

   useEffect(() => {
      ref.current.scrollIntoView({
         behavior: "smooth",
         block: "start",
         inline: "start",
      });
      value !== "" &&
         dispatch(
            directionActions.updateRouteInformation({
               mode: value,
            })
         );
   }, [value]);

   useEffect(() => {
      const identifier = setTimeout(() => {
         modeStore && getDirectionFromServer(getDirectionData);
      }, 1);
      return () => {
         clearTimeout(identifier);
      };
   }, [modeStore]);

   const mapModesToDom = () =>
      modes &&
      modes.length > 0 &&
      modes.map((mode, index) => (
         <div className={classes.modalIcons} key={index}>
            <img
               src={`../../../assets/images/modes/${capitalize(
                  state
               )}/${mode[0].toUpperCase()}.svg`}
               alt={`${mode[0]} icon`}
            />
            <input
               type="radio"
               name="smallModal"
               onChange={onChangeHandler}
               defaultValue={mode[0]}
               checked={mode[0] === modeStore}
            />
            <p className={modeStore === mode[0] ? classes.active : ""}>
               {capitalize(mode[0])}
            </p>
         </div>
      ));

   const onChangeHandler = (event) => {
      setValue(event.target.value);
   };

   return (
      <Container className={classes.container}>
         <div className={classes.modalOverlay} ref={ref}>
            <div className={classes.modalContainer}>
               <p>What's your preference?</p>
               <div className={classes.modal}>{mapModesToDom()}</div>
            </div>
         </div>
      </Container>
   );
};

export default ModePreference;
