import { createSlice } from '@reduxjs/toolkit';

const initialState = { loading: false, error: '' };

const httpSlice = createSlice({
	name: 'http',
	initialState,
	reducers: {
		setLoading(state, action) {
			state.loading = action.payload;
		},

		setError(state, action) {
			state.error = action.payload;
		},
	},
});

export const httpActions = httpSlice.actions;
export const httpReducer = httpSlice.reducer;

export default httpSlice;
