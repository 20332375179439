export const levelNames = {
   ABUJA: {
      1: "Wadaad of Abuja",
      2: "Wazir of Abuja",
      3: "Emir of Abuja",
      4: "Sheikh of Abuja",
   },
   BENIN: {
      1: "Young Okoro",
      2: "Ogieva",
      3: "Ozua of the Lara Kingdom",
      4: "Royal Emperor",
   },
   OGUN: {
      1: "Omo Egba",
      2: "Eleniyan",
      3: "Balogun",
      4: "Alake 1",
   },
   "PORT HARCOURT": {
      1: "Abobi",
      2: "Eze ego",
      3: "Odogwu of every",
      4: "Ogbuefi International",
   },
   IBADAN: {
      1: "Omoluabi",
      2: "Agba Oye",
      3: "Bashorun",
      4: "Alaafin 1",
   },
   ANAMBRA: {
      1: "Nwanne",
      2: "Eze ego",
      3: "Odogwu of every",
      4: "Ogbuefi International",
   },
   ENUGU: {
      1: "Nwanne",
      2: "Eze ego",
      3: "Odogwu of every",
      4: "Ogbuefi International",
   },
};
