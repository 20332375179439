import { useDispatch } from "react-redux";
import { directionActions } from "../redux/directionsSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";

const useLoader = () => {
   const dispatch = useDispatch();
   const directionLoadingCallback = (value) =>
      dispatch(directionActions.setLoading(value));

   const normalLoadingCallback = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   return {
      directionLoadingCallback,
      normalLoadingCallback,
   };
};

export default useLoader;
