import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { iconImages } from "../../../assets";
import useFeedback from "../../../hooks/useFeedback";
import { PLAY_AREA } from "../../../routes/Path";
import FeedbackTextArea from "../../components/FeedbackTextArea/FeedbackTextArea";
import Feedbackthanks from "../../components/Feedbackthanks/Feedbackthanks";
import classes from "./LeaveFeedbackTask.module.css";

const FeedbackOnApp = () => {
   const containerRef = useRef();
   const {
      feedback: { feedbackInfo },
   } = useSelector((state) => state);

   const { showFeedbackThanks, onFeedbackOnApp } = useFeedback();
   const match = useRouteMatch();
   const history = useHistory();
   const [focus, setFocus] = useState(false);

   useEffect(() => {
      containerRef.current.scrollIntoView({
         behavior: "smooth",
         block: "start",
         inline: "center",
      });
   }, []);

   useEffect(() => {
      if (match.path.includes("task") && focus) {
         history.push(`/${PLAY_AREA}`);
      }
   }, [focus]);

   return (
      <>
         <div className={classes.container} ref={containerRef}>
            {!showFeedbackThanks ? (
               <>
                  {" "}
                  <h2>Leave Feedback</h2>
                  <div className={classes.btnContainer}>
                     <button className={classes.active}>Other feedback</button>
                  </div>
                  <FeedbackTextArea setOnFocus={setFocus} />
                  {feedbackInfo.typed_text && (
                     <div className={classes.sendFeedbackContainer}>
                        <button onClick={() => onFeedbackOnApp()}>
                           Send FeedBack{" "}
                           <img src={iconImages.sendFeedbackImg} alt="" />
                        </button>
                     </div>
                  )}{" "}
               </>
            ) : (
               <Feedbackthanks />
            )}
         </div>
      </>
   );
};

export default FeedbackOnApp;
