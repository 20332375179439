import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import useHttps from "../../../hooks/useHttps";
import useInput from "../../../hooks/useInput";
import { FORGET_PASSWORD } from "../../../routes/Path";
import validators from "../../../Utilities/inputValidator";
import classes from "./Loginwithemail.module.css";

const Loginwithemail = () => {
   const {
      value: emailValue,
      inputBlurHandler: emailBlurHandler,
      valueInputChange: emailValueInputChange,
      hasError: emailHasError,
      valueIsValid: emailIsValid,
   } = useInput(validators.checkEmailIsValid);
   const { sendRequest } = useHttps();

   const { loginUser } = useAuth(sendRequest);

   const {
      value: passwordValue,
      inputBlurHandler: passwordBlurHandler,
      valueInputChange: passwordInputChange,
      hasError: passwordHasError,
      valueIsValid: passwordIsValid,
      showPassword,
      onSetShowPassword,
   } = useInput(validators.checkPasswordIsValid);

   const formIsvalid = emailIsValid && passwordIsValid;

   const LoginForm = useCallback(
      (event) => {
         event.preventDefault();
         if (!formIsvalid) {
            return;
         }
         loginUser({ email: emailValue, password: passwordValue });
      },
      [emailValue, passwordValue]
   );

   return (
      <form onSubmit={LoginForm}>
         <div
            className={`${classes.formGroup} ${
               emailHasError && classes.error
            } ${emailIsValid && classes.success}`}
         >
            <label htmlFor="">Email</label>
            <input
               type="email"
               value={emailValue}
               onChange={emailValueInputChange}
               className={classes.formControl}
               onBlur={emailBlurHandler}
            />
         </div>
         <div
            className={`${classes.formGroup} ${
               passwordHasError && classes.error
            } ${passwordIsValid && classes.success}`}
         >
            <label htmlFor="">Password:</label>
            <Link to={`/${FORGET_PASSWORD}`}>Forgot password?</Link>
            <input
               type={showPassword ? "text" : "password"}
               className={classes.formControl}
               onBlur={passwordBlurHandler}
               onChange={passwordInputChange}
               value={passwordValue}
            />
            <i
               className={!showPassword ? `far fa-eye` : "far fa-eye-slash"}
               onClick={onSetShowPassword}
            ></i>
         </div>
         <div className={classes.formGroup}>
            <button
               disabled={!formIsvalid}
               className={`${classes.formControl} ${classes.btn} ${
                  !formIsvalid ? classes.disabled : ""
               }`}
            >
               Login
            </button>
         </div>
      </form>
   );
};

export default Loginwithemail;
