import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useToasts } from "react-toast-notifications";
import { onboardingActions } from "../redux/onboardingSlice";
import { utilitiesActions } from "../redux/utilitiesSlice";
import { ENTER_PASSWORD, LOGIN } from "../routes/Path";
import { setit } from "../Utilities/helper";
import useHttps from "./useHttps";

const useOnboardingVerification = () => {
   const { search } = useLocation();
   const dispatch = useDispatch();
   const history = useHistory();
   const { addToast } = useToasts();
   let emailValue = "";

   const [verificationStatus, setVerificationStatus] = useState(false);
   const [emailExists, setEmailExists] = useState(false);

   const { sendRequest } = useHttps();

   const successCallback = (data) => {
      const {
         data: { token },
         status,
      } = data;
      setTokenToCookie(token, status);
   };

   const setTokenToCookie = (token, status) => {
      setit("lara_token", token, 360);
      setVerificationStatus(status);
   };

   const loadingCallback = (value) =>
      dispatch(utilitiesActions.setNormalLoading(value));

   const errorCallback = () => {
      setEmailExists(true);
   };

   const checkIsEmailExists = (data) => {
      const { status } = data;
      status ? isEmailExists() : setEmailExists(true);
   };

   const onEmailExists = (value) => {
      const config = {
         url: "account/email",
         method: "post",
         body: {
            email: value,
         },
         type: "EMAIL",
      };

      emailValue = value;

      sendRequest(
         config,
         checkIsEmailExists,
         loadingCallback,
         null,
         errorCallback
      );
   };

   const isEmailExists = () => {
      dispatch(onboardingActions.addToUserData({ email: emailValue }));
      history.push(`/${ENTER_PASSWORD}`);
   };

   const checkVerificationStatus = useCallback(() => {
      const verification_token = new URLSearchParams(search).get(
         "verificationtoken"
      );
      const config = {
         url: `account?signup_token=${verification_token}`,
      };
      sendRequest(
         config,
         successCallback,
         loadingCallback,
         null,
         errorCallback,
         null
      );
   }, [sendRequest]);

   const updatePassword = (password) => {
      const token = new URLSearchParams(search).get("verificationtoken");

      const config = {
         url: "password/reset",
         method: "patch",
         body: {
            token,
            password,
         },
      };

      const successCallback = () => {
         addToast("Your password has been updated", {
            appearance: "success",
            autoDismiss: true,
         });
         history.push(`/${LOGIN}`);
      };

      sendRequest(config, successCallback, loadingCallback, null);
   };
   return {
      verificationStatus,
      checkVerificationStatus,
      onEmailExists,
      emailExists,
      updatePassword,
   };
};

export default useOnboardingVerification;
