import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	userData: {},
	isShared: false,
	isOtherInput: false,
	inviteeData: {},
};

const onboardingSlice = createSlice({
	name: 'onboarding',
	initialState,
	reducers: {
		addToUserData(state, action) {
			const payload = action.payload;
			state.userData = { ...state.userData, ...payload };
		},
		checkIsShared(state, action) {
			state.isShared = action.payload;
		},
		setInviteeData(state, action) {
			state.inviteeData = { ...action.payload };
		},
		checkIsOtherInput(state, action) {
			state.isOtherInput = action.payload;
		},
	},
});

export const onboardingActions = onboardingSlice.actions;
export const onboardingReducer = onboardingSlice.reducer;

export default onboardingSlice;
