import React from 'react';
import classes from './InterestButton.module.css';
import PropTypes from 'prop-types';

const InterestButton = (props) => {
	const { children, className, confirmSelection, isValid } = props;
	return (
		<button
			onClick={confirmSelection}
			disabled={!isValid}
			className={`${classes.btn} ${className} ${
				!isValid && classes.disabled
			}`}
		>
			{children}
		</button>
	);
};

InterestButton.propTypes = {
	className: PropTypes.string,
	children: PropTypes.any,
	confirmSelection: PropTypes.func.isRequired,
	isValid: PropTypes.bool,
};

export default InterestButton;
