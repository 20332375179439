import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   feedbackInfo: {},
   ratingInformation: {},
   missingPoiFeedback: {},
   showMissingFeedbackForm: false,
   autoGeneratedFeedback: {},
   poiFormData: {},
   showFeedbackThanks: false,
};

const feedbackSlice = createSlice({
   name: "feedback",
   initialState,
   reducers: {
      setFeedbackInfo(state, action) {
         state.feedbackInfo = { ...state.feedbackInfo, ...action.payload };
      },
      setRatingInfo(state, action) {
         state.ratingInformation = { ...action.payload };
      },
      setMissingPoiFeedback(state, action) {
         state.missingPoiFeedback = {
            ...state.missingPoiFeedback,
            ...action.payload,
         };
      },
      setShowMissingFeedbackForm(state, action) {
         state.showMissingFeedbackForm = action.payload;
      },
      setPoiFormData(state, { payload }) {
         state.poiFormData = payload;
      },
      setShowFeedbackThanks(state, action) {
         state.showFeedbackThanks = action.payload;
      },
      clearFeedbackInfo() {
         return initialState;
      },
   },
});

export const feedbackActions = feedbackSlice.actions;
export const feedbackReducer = feedbackSlice.reducer;

export default feedbackSlice;
