import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router";
import { iconImages } from "../../../../assets";
import useFeedback from "../../../../hooks/useFeedback";
import { PLAY_AREA } from "../../../../routes/Path";
import FeedbackTextArea from "../../../components/FeedbackTextArea/FeedbackTextArea";
import classes from "./Newfeature.module.css";

const Newfeature = () => {
   const {
      feedback: { feedbackInfo },
   } = useSelector((state) => state);

   const match = useRouteMatch();
   const history = useHistory();

   const [focus, setFocus] = useState(false);
   const { onFeedbackOnApp } = useFeedback();

   useEffect(() => {
      if (match.path.includes("task") && focus) {
         history.push(`/${PLAY_AREA}`);
      }
   }, [focus]);

   return (
      <div className={classes.container}>
         <h4>Recommend a new feature on Lara</h4>
         <FeedbackTextArea
            setOnFocus={setFocus}
            placeholder="A feature I’d like to see on Lara"
         />

         {feedbackInfo.typed_text && (
            <div className={classes.sendFeedbackContainer}>
               <button onClick={() => onFeedbackOnApp("New feature")}>
                  Send FeedBack <img src={iconImages.sendFeedbackImg} alt="" />
               </button>
            </div>
         )}
      </div>
   );
};

export default Newfeature;
