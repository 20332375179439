import React from "react";
import { useSelector } from "react-redux";
import GetDirectionTask from "../../tasks/GetDirectionTask/GetDirectionTask";
import FunPlaces from "../../tasks/taskpreviews/funPlacesNearMe/FunPlaces";
import InterState from "../../tasks/taskpreviews/interState/InterState";
import AskForLocation from "../AskforLocation/AskForLocation";
import LocationInput from "../LocationInput/LocationInput";
import UserInput from "../UserInput/UserInput";
import classes from "./DirectionContainerHeader.module.css";

const DirectionContainerHeader = () => {
   const {
      directions: {
         routeInformation: { toText },
         showAskUserLocation,
      },
      tasks: {
         appropriateTask: {
            showDirectionTask,
            showFunPlacesTask,
            showInterStateTask,
         },
      },
   } = useSelector((state) => state);

   return (
      <div className={classes.container}>
         {showDirectionTask && <GetDirectionTask />}
         {showFunPlacesTask && <FunPlaces />}

         {showInterStateTask && <InterState />}

         {toText && <UserInput text={toText} />}
         {toText && <AskForLocation />}
         {showAskUserLocation && <LocationInput />}
      </div>
   );
};

export default DirectionContainerHeader;
