import gsap from "gsap";
import React, { useEffect, useRef } from "react";
import { widgets } from "../../../../../data/widgets";
import useInvite from "../../../../../hooks/useInvite";
import classes from "./inviteanswer.module.css";

const animation = gsap.timeline();

const Inviteanswer = () => {
   const share = useRef(null);
   const shareWidgetsEl = useRef(null);
   const { inviteNewUser, shareToSocialMedia, inviteData } = useInvite();
   useEffect(() => {
      const identifier = setTimeout(() => inviteNewUser(), 1);

      return () => {
         clearTimeout(identifier);
      };
   }, []);

   const showShareWidgets = () => {
      animation
         .fromTo(
            share.current,
            0.5,
            { x: "5%", opacity: 1, pointerEvents: "all" },
            { x: 0, opacity: 0, pointerEvents: "none" }
         )
         .fromTo(
            shareWidgetsEl.current,
            0.5,
            { x: "5%", opacity: 0, pointerEvents: "none" },
            { x: 0, opacity: 1, pointerEvents: "all" },
            "-=0.4"
         );
   };

   const hideShareWidget = () => {
      animation
         .fromTo(
            shareWidgetsEl.current,
            0.5,
            { x: "5%", opacity: 1, pointerEvents: "all" },
            { x: 0, opacity: 0, pointerEvents: "none" }
         )
         .fromTo(
            share.current,
            0.5,
            { x: "5%", opacity: 0, pointerEvents: "none" },
            { x: 0, opacity: 1, pointerEvents: "all" },
            "-=0.4"
         );
   };

   return (
      <div className={classes.container}>
         <h2>
            Here’s what your message to <b></b> would look like:
         </h2>
         <div className={classes.finalmessageContainer}>
            <p>{inviteData.display_text}</p>
            <p>
               Lara is an automated direction assistant that helps people
               navigate their way via public transport by providing detailed
               directions, estimated fares and travel time.
            </p>
         </div>

         <div className={classes.finalmessageShare}>
            <p>I’m sharing to:</p>
            <button
               ref={share}
               className={classes.shareInapp}
               onClick={showShareWidgets}
               disabled={!inviteData.display_text}
            >
               <i className="fas fa-share-alt"></i>
               SHARE
            </button>

            <div ref={shareWidgetsEl} className={classes.shareWidgets}>
               <div className={classes.ellipses} onClick={hideShareWidget}>
                  <i className="fas fa-ellipsis-h"></i>
               </div>
               {widgets.map((data) => (
                  <a
                     className={classes.google}
                     onClick={() => shareToSocialMedia(data.name.toUpperCase())}
                     key={data.id}
                  >
                     <i className={data.className}></i>
                  </a>
               ))}
            </div>
         </div>
      </div>
   );
};

export default Inviteanswer;
