import React from "react";
import { useSelector } from "react-redux";
import useSaveTrip from "../../../../../hooks/useSaveTrip";
import DirectionsContainerWrapper from "../../../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import DirectionsLoader from "../../../../components/DirectionLoader/DirectionLoader";
import classes from "./SaveTrip.module.css";
import GenericButton from "../../../../components/genericButton/GenericButton";
import { useEffect } from "react";

const SaveTrip = () => {
   const {
      directions: { loading },
      saveTrip: { startSaveTrip },
   } = useSelector((state) => state);
   const { getUserTrips, onStartSaveTrip } = useSaveTrip();

   useEffect(() => {
      getUserTrips();
   }, [startSaveTrip]);
   return (
      <div className={classes.SaveTrip}>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <h2>Save a trip</h2>
               <p>Bookmark directions for future trips.</p>
               <span>Let’s check out your trip history</span>

               <button onClick={onStartSaveTrip} className={classes.addBtn}>
                  <span>+</span>
                  <div>
                     <p>Add a recent trip</p>
                  </div>
               </button>
            </div>
         </DirectionsContainerWrapper>
         {loading && <DirectionsLoader />}

         <GenericButton ButtonText="skip to directions" />
      </div>
   );
};

export default SaveTrip;
