import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useLocation from "../../../hooks/useLocation";
import { directionActions } from "../../../redux/directionsSlice";
import LaraMessageContainer from "../Wrappers/LaraMessageContainer/LaraMessageContainer";
import classes from "./AskForLocation.module.css";

const {
   setShowAskUserLocation,
   setIsFoundLocation,
   setLoading,
   updateRouteInformation,
} = directionActions;

const AskForLocation = () => {
   const dispatch = useDispatch();
   const {
      directions: { isFoundLocation, showAskUserLocation, routeInformation },
   } = useSelector((state) => state);

   const getLocationFromServer = (data, coords) => {
      const { data: responseData, status } = data;

      status && mapSuccessDataToRouteInfo(responseData, coords);
   };

   const mapSuccessDataToRouteInfo = ({ formatted_address }, coords) => {
      const updatedRouteInformation = {
         ...routeInformation,
         ...coords,
         fromText: formatted_address,
      };

      dispatch(updateRouteInformation(updatedRouteInformation));
      dispatch(setShowAskUserLocation(true));
      dispatch(setIsFoundLocation(true));
   };

   const isGetLocationClass =
      showAskUserLocation && isFoundLocation ? classes.active : "";
   const isTypedLocationClass =
      showAskUserLocation && !isFoundLocation ? classes.active : "";

   const setLoadingState = (value) => dispatch(setLoading(value));

   const { locator } = useLocation();

   const onGetLocation = (event) => {
      event.preventDefault();
      locator(getLocationFromServer, setLoadingState);
   };

   const onTypeLocation = () => {
      dispatch(setShowAskUserLocation(true));
      dispatch(setIsFoundLocation(false));
   };
   return (
      <LaraMessageContainer>
         <div className={classes.container}>
            <p> Do you want to start from your current location? </p>
            <div className={classes.btnIcons}>
               <button className={isGetLocationClass} onClick={onGetLocation}>
                  Yes
               </button>{" "}
               <button
                  className={isTypedLocationClass}
                  onClick={onTypeLocation}
               >
                  No
               </button>
            </div>
         </div>
      </LaraMessageContainer>
   );
};

export default AskForLocation;
