import React from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../../../assets";
import useInput from "../../../../../hooks/useInput";
import useSaveTrip from "../../../../../hooks/useSaveTrip";
import { capitalize, dateFunc } from "../../../../../Utilities/helper";
import validators from "../../../../../Utilities/inputValidator";
import DirectionsContainerWrapper from "../../../../components/Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import DirectionsLoader from "../../../../components/DirectionLoader/DirectionLoader";
import classes from "./GiveTripName.module.css";

const GiveTripName = () => {
   const {
      saveTrip: { singleTrip: trip },
      directions: { loading },
   } = useSelector((state) => state);

   const { value, valueInputChange, valueIsValid, inputBlurHandler } = useInput(
      validators.checkValueIsEmpty
   );

   const { saveTrip } = useSaveTrip();

   const onSubmit = (e) => {
      e.preventDefault();
      if (!valueIsValid) {
         return;
      }

      const body = {
         trip_name: value,
         search_id: trip.search_id,
      };

      saveTrip(body);
   };

   return (
      <div className={classes.GiveTripName}>
         <DirectionsContainerWrapper>
            <div className={classes.container}>
               <h2>
                  Give your <br /> trip a name
               </h2>
               <p>Save your trip with a tag</p>
               <form onSubmit={onSubmit} className={classes.tripForm}>
                  <div className={classes.singleTripBox}>
                     <div
                        style={{ background: "#bee4df" }}
                        className={classes.roundPointer}
                     ></div>
                     <div className={classes.singleTrip}>
                        <p>
                           {trip.search_from} - {trip.search_to}{" "}
                        </p>
                        <span>
                           {capitalize(trip.mode)}, {capitalize(trip.state)} -{" "}
                           {dateFunc(trip.date)}
                        </span>
                     </div>
                  </div>

                  <div className={classes.formControl}>
                     <input
                        type="text"
                        className={classes.tripName}
                        name="text"
                        value={value}
                        onChange={valueInputChange}
                        onBlur={inputBlurHandler}
                        placeholder="Save Trip As"
                     />
                     {valueIsValid && (
                        <button type="submit">
                           {" "}
                           <img
                              src={iconImages.setLocationOnMapImg}
                              alt=""
                           />{" "}
                        </button>
                     )}
                  </div>
               </form>
            </div>
         </DirectionsContainerWrapper>
         {loading && <DirectionsLoader />}
      </div>
   );
};

export default GiveTripName;
