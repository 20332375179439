import PropTypes from "prop-types";
import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import useSocialSignUp from "../hooks/useSocialSignUp";
// eslint-disable-next-line no-undef
const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

const FacebookSocialLogin = (props) => {
   const { facebookElement } = props;
   const getSocialLoginData = useSocialSignUp();

   const faceBookResponse = ({ name, userID, email }) => {
      const userdata = {
         provider: "facebook",
         token: userID,
         name,
         email,
      };
      getSocialLoginData(userdata);
   };

   return (
      <FacebookLogin
         appId={FACEBOOK_ID}
         callback={faceBookResponse}
         fields="name,email"
         autoLoad={false}
         render={(renderProps) => facebookElement(renderProps.onClick)}
      />
   );
};

FacebookSocialLogin.propTypes = {
   facebookElement: PropTypes.func.isRequired,
};

export default FacebookSocialLogin;
