import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import useFeedback from "../../../hooks/useFeedback";
import Feedbackthanks from "../../components/Feedbackthanks/Feedbackthanks";
import RatingFeedback from "../../components/RatingFeedback/RatingFeedback";
import classes from "./LeaveFeedbackTask.module.css";

const LeaveFeedbackTask = () => {
   const containerRef = useRef();
   const {
      feedback: { feedbackInfo },
   } = useSelector((state) => state);

   const { showFeedbackThanks, onFeedbackSubmit } = useFeedback();

   useEffect(() => {
      containerRef.current.scrollIntoView({
         behavior: "smooth",
         block: "start",
         inline: "center",
      });
   }, []);

   return (
      <>
         <div className={classes.container} ref={containerRef}>
            {!showFeedbackThanks ? (
               <>
                  {" "}
                  <h2>Leave Feedback</h2>
                  <div className={classes.btnContainer}>
                     <button className={classes.active}>Rate your trip!</button>
                  </div>
                  <RatingFeedback />
                  {(feedbackInfo.text ?? feedbackInfo.typed_text) && (
                     <div className={classes.sendFeedbackContainer}>
                        <button onClick={onFeedbackSubmit}>
                           Send FeedBack{" "}
                           <img src={iconImages.sendFeedbackImg} alt="" />
                        </button>
                     </div>
                  )}{" "}
               </>
            ) : (
               <Feedbackthanks />
            )}
         </div>
      </>
   );
};

export default LeaveFeedbackTask;
