import PropTypes from "prop-types";
import React from "react";
import { iconImages } from "../../../assets";
import { formattedDistance, formattedTime } from "../../../Utilities/helper";
import DirectionsContainerWrapper from "../Wrappers/DirectionsContainerWraper/DirectionsContainerWrapper";
import classes from "./MinimizedDirection.module.css";

const MinimizedDirection = ({ directionsData }) => {
   return (
      <DirectionsContainerWrapper>
         <div className={classes.container}>
            <div>
               <h2>
                  Directions from {directionsData?.directions_from} to{" "}
                  {directionsData?.directions_to}
               </h2>
               <img height="30px" src={iconImages.destinationIconImg} alt="" />
            </div>
            <p className={classes.directionInfo}>
               -{formattedTime(directionsData?.path[0]?.duration_total)}
               {`(${formattedDistance(
                  directionsData?.path[0]?.distance_total
               )})`}
            </p>
            <p className={classes.price}>
               N{directionsData?.path[0]?.min_fare_sum} -{" "}
               {directionsData?.path[0]?.max_fare_sum}
            </p>
         </div>
      </DirectionsContainerWrapper>
   );
};

MinimizedDirection.propTypes = {
   directionsData: PropTypes.object,
};

export default MinimizedDirection;
