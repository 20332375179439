export const loginSignupContainerVariants = {
	initial: { y: '10vh', opacity: 0 },
	animate: {
		y: 0,
		opacity: 1,
		transition: { when: 'beforeChildren', delay: 1 },
	},
	exit: {
		y: '10vh',
		opacity: 0,
	},
};
