import React from "react";
import { useState } from "react";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { iconImages } from "../../../assets";
import { feedbackActions } from "../../../redux/feedbackSlice";
import classes from "./FeedbackTextArea.module.css";
import PropTypes from "prop-types";

const FeedbackTextArea = ({ placeholder, setOnFocus }) => {
   const ref = useRef(null);
   const [value, setValue] = useState("");
   const dispatch = useDispatch();
   const {
      feedback: { feedbackInfo },
   } = useSelector((state) => state);

   useEffect(() => {
      ref.current && textAreaAdjust(ref.current);
      const identifier = setTimeout(
         () =>
            dispatch(
               feedbackActions.setFeedbackInfo({
                  ...feedbackInfo,
                  typed_text: value,
               })
            ),
         200
      );

      return () => {
         clearTimeout(identifier);
      };
   }, [ref, value]);

   const textAreaAdjust = (element) => {
      element.style.height = "1px";
      element.style.height = `${15 + element.scrollHeight}px`;
   };
   const onClearText = () => {
      setValue("");
   };
   return (
      <div className={classes.container}>
         <textarea
            onChange={(e) => setValue(e.target.value)}
            ref={ref}
            value={value}
            placeholder={placeholder ?? ""}
            onFocus={() => setOnFocus && setOnFocus(true)}
         ></textarea>
         <button onClick={onClearText}>
            <img src={iconImages.cancelIconImg} alt="" />
         </button>
      </div>
   );
};

FeedbackTextArea.propTypes = {
   placeholder: PropTypes.string,
   setOnFocus: PropTypes.func,
};

export default FeedbackTextArea;
