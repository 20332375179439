import React, { createRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useSetRouteLogic from "../../../../hooks/useSetRouteLogic";
import useSetUserInformation from "../../../../hooks/useSetUserInformation";
import { tasksAction } from "../../../../redux/tasksSlice";
import { utilitiesActions } from "../../../../redux/utilitiesSlice";
import { getit } from "../../../../Utilities/helper";
import DirectionLogic from "../../../components/DirectionLogic/directionLogic";
import DirectionNav from "../../../components/DirectionNav/DirectionNav";
import PlayContainerHeader from "../../../components/PlayContainerHeader/PlayContainerHeader";
import SaveTripContainer from "../../../components/SaveTripContainer/SaveTripContainer";
import Container from "../../../components/Wrappers/container/Container";
import PlayAreaContainer from "../../../components/Wrappers/PlayAreaContainer/PlayAreaContainer";
import AutoGeneratedFeedbackTask from "../../../tasks/AutoGeneratedFeedbackTask/AutoGeneratedFeedbackTask";
import DownloadLaraTaskContainer from "../../../tasks/DownloadLaraTask/DownloadLaraTaskContainer";
import { FollowOnSocialsTask } from "../../../tasks/followOnSocialsTask/FollowOnSocialsTask";
import InviteTask from "../../../tasks/InviteTask/InviteTask";
import LeaveFeedbackTaskContainer from "../../../tasks/LeaveFeedbackTask/LeaveFeedbackTaskContainer";
import MissingPoiTask from "../../../tasks/missingPoiTask/MissingPoiTask";
import RecommendANewFeatureTask from "../../../tasks/RecommendANewFeature/RecommendANewFeatureTask";
import classes from "./PlayArea.module.css";

const PlayArea = () => {
   const {
      tasks: {
         appropriateTask: {
            showFeedbackTask,
            showDirectionTask,
            showDownloadLaraTask,
            showShareTask,
            showFollowOnSocials,
            showRecommendaNewFeatureTask,
            showAutoGeneratedFeedbackTask,
            showMissingPoiTask,
            showSavedTripTask,
            showFeedbackOnApp,
         },
      },
      utilities: { isLoggedIn, cancelUseffect },
   } = useSelector((state) => state);

   const { getUserInformation } = useSetUserInformation();
   const dispatch = useDispatch();
   const token = getit("lara_token");
   const { getParams } = useSetRouteLogic();

   const ref = createRef();

   useEffect(async () => {
      getParams();
      !cancelUseffect && !isLoggedIn && token && (await getUserInformation());
      dispatch(tasksAction.setMaximize(true));
      return () => {
         dispatch(utilitiesActions.setCancelUseEffect(true));
      };
   }, [dispatch]);

   const switchTasks = () => {
      switch (true) {
         case showFeedbackTask:
            return <LeaveFeedbackTaskContainer />;
         case showFeedbackOnApp:
            return <LeaveFeedbackTaskContainer />;

         case showDirectionTask:
            return <DirectionLogic />;
         case showDownloadLaraTask:
            return <DownloadLaraTaskContainer />;
         case showShareTask:
            return <InviteTask />;
         case showFollowOnSocials:
            return <FollowOnSocialsTask />;
         case showRecommendaNewFeatureTask:
            return <RecommendANewFeatureTask />;
         case showAutoGeneratedFeedbackTask:
            return <AutoGeneratedFeedbackTask />;
         case showMissingPoiTask:
            return <MissingPoiTask />;
         case showSavedTripTask:
            return <SaveTripContainer />;
         default:
            return <DirectionLogic />;
      }
   };

   return (
      <PlayAreaContainer containerRef={ref}>
         <>
            <Container className={classes.container}>
               <>
                  {isLoggedIn && <DirectionNav />}
                  <PlayContainerHeader />
                  {switchTasks()}
               </>
            </Container>
         </>
      </PlayAreaContainer>
   );
};

export default PlayArea;
