import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   appropriateTask: {
      showFeedbackTask: false,
      showDirectionTask: true,
      showDownloadLaraTask: false,
      showShareTask: false,
      showFollowOnSocials: false,
      showRecommendaNewFeatureTask: false,
      showAutoGeneratedFeedbackTask: false,
      showMissingPoiTask: false,
      showSavedTripTask: false,
      showFunPlacesTask: false,
      showInviteTask: false,
      showFeedbackOnApp: false,
      showInterStateTask: false,
   },
   singleTask: {},
   subTaskId: null,
   maximize: false,
   invite: {
      invitee_name: "",
      job_status: "",
      inviter_name: "",
   },
   task_id: null,
   currentUrl: "",
   currentBacklogTask: "",
};

const tasksSlice = createSlice({
   name: "tasks",
   initialState,
   reducers: {
      setShowFeedback(state, action) {
         state.showFeedbackTask = action.payload;
      },
      setShowDirectionTask(state, action) {
         state.showDirectionTask = action.payload;
      },
      setShowDownloadLaraTask(state, action) {
         state.showDownloadLaraTask = action.payload;
      },
      setSingleTaskInformation(state, action) {
         state.singleTask = action.payload;
      },
      setSubTaskId(state, action) {
         state.subTaskId = action.payload;
      },
      setMaximize(state, action) {
         state.maximize = action.payload;
      },
      setShowShareTask(state, action) {
         state.showShareTask = action.payload;
      },

      setInviteData(state, action) {
         state.invite = { ...state.invite, ...action.payload };
      },
      setTask_id(state, action) {
         state.task_id = action.payload;
      },
      setAppropriateTask(state, action) {
         for (let key in state.appropriateTask) {
            state.appropriateTask[key] = false;
         }
         state.appropriateTask[action.payload] = true;
      },
      setCurrentUrl(state, action) {
         state.currentUrl = action.payload;
      },
      setCurrentBacklogTask(state, action) {
         state.currentBacklogTask = action.payload;
      },
   },
});

export const tasksAction = tasksSlice.actions;
export const tasksReducer = tasksSlice.reducer;

export default tasksSlice;
