import classes from "./PlayContainerHeader.module.css";
import React from "react";
import Logo from "../../utilities/Logo/Logo";
import Container from "../Wrappers/container/Container";
import { checkTime } from "../../../Utilities/helper";
import { iconImages } from "../../../assets";
import { useSelector } from "react-redux";

const greeting = checkTime();
const PlayContainerHeader = () => {
   const {
      user: {
         userInformation: { firstName },
      },
      utilities: { isLoggedIn },
   } = useSelector((state) => state);

   const handleGreetingImage = () => {
      if (greeting.split(" ")[1].toLowerCase().trim() === "morning") {
         return {
            icon: iconImages.goodMorningImg,
            alt: "Good Morning",
         };
      }
      if (greeting.split(" ")[1].toLowerCase().trim() === "afternoon") {
         return {
            icon: iconImages.goodAfternoonImg,
            alt: "Good Afternoon",
         };
      }

      return {
         icon: iconImages.goodEveningImg,
         alt: "Good Evening",
      };
   };

   const initials = firstName ? firstName : "I'm Lara";

   const subText = isLoggedIn ? (
      <>
         <div className={classes.isLoggedTextContainer}>
            <div>
               <h2>Hello {initials}</h2>
               <p>{greeting}</p>
            </div>
            <div className={classes.greetingImageContainer}>
               <img
                  src={handleGreetingImage().icon}
                  alt={handleGreetingImage().alt}
               />
            </div>
         </div>
      </>
   ) : (
      <div className={classes.textContainer}>
         {" "}
         <p className={classes.name}>Hello {initials}</p>
         <p>Your personal directions guide.</p>
         <p>Please see details of your directions below:</p>
      </div>
   );

   return (
      <Container className={classes.mainContainer}>
         <div
            className={`${classes.container}  ${
               !isLoggedIn ? classes.notLoggedIn : ""
            }`}
         >
            <div className={classes.imgContainer}>
               <Logo />
            </div>
            {subText}
         </div>
      </Container>
   );
};

export default PlayContainerHeader;
