import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useTask from "../../../hooks/useTask";
import { tasksAction } from "../../../redux/tasksSlice";
import { DASHBOARD, INVITE, USER } from "../../../routes/Path";
import NavContainer from "../Wrappers/NavContainer/NavContainer";
import classes from "./DirectionNav.module.css";
import ShareIcon from "@mui/icons-material/Share";
import HomeIcon from "@mui/icons-material/Home";

const DirectionNav = () => {
   const history = useHistory();
   const dispatch = useDispatch();
   const [value, setValue] = useState(0);
   const {
      tasks: { singleTask },
   } = useSelector((state) => state);

   const showShare = () => {
      history.push(`/${INVITE}`);
   };
   const { choseAppropriateTask, getShownTasks } = useTask();

   const selectTask = (value) => {
      dispatch(tasksAction.setSubTaskId(value));
      choseAppropriateTask(value);
   };
   const onHomeClick = () => {
      if (getShownTasks()?.length === 3) {
         setValue(() => 0);
         selectTask(singleTask.user_sub_task[value].sub_task_id);
      }
   };
   return (
      <NavContainer>
         <>
            <li className={classes.share}>
               <button onClick={showShare}>
                  <ShareIcon />
               </button>
            </li>
            <li className={classes.home} onClick={onHomeClick}>
               <Link to={`/${USER}/${DASHBOARD}`}>
                  <HomeIcon />
               </Link>
            </li>
         </>
      </NavContainer>
   );
};

export default DirectionNav;
