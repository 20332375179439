import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { autoGeneratedFeedbackActions } from "../redux/autoGeneratedFeedbackSlice";
import { FLAGDIRECTION, PLAY_AREA } from "../routes/Path";
import useHttps from "./useHttps";
import useLoader from "./useLoader";
import usePoi from "./usePoi";
import useTask from "./useTask";

const useAutoGeneratedFeedback = () => {
   const {
      autoGeneratedFeedback: {
         selectedLgas,
         autoGeneratedModePreCheck,
         autoGeneratedFeedbackDirectionData,
         badDirection,
      },
      tasks: { maximize },
      directions: { stopByLgaMeta, stopByLga },
      user: {
         userInformation: { city },
      },
      feedback: { feedbackInfo },
   } = useSelector((state) => state);
   const dispatch = useDispatch();
   const history = useHistory();
   const { getLgaPercity } = usePoi();
   const { sendRequest } = useHttps();
   const { directionLoadingCallback } = useLoader();
   const [eMakeSense, setEmakeSense] = useState(false);
   const [problemDey, setProblemDay] = useState(false);
   const { submitTask } = useTask();

   const isChecked = (place) =>
      selectedLgas.findIndex((lga) => lga?.stop_id === place) > -1;

   const toggleSelecteedAUtoGeneratedFeedback = (lga) => {
      let newSelectedLgas = [];
      !maximize && history.push(`/${PLAY_AREA}`);
      if (isChecked(lga)) {
         newSelectedLgas = selectedLgas.filter(
            (place) => place?.stop_id !== lga
         );
      } else {
         const newPlace = stopByLga.find((place) => place?.stop_id === lga);
         newSelectedLgas = [...selectedLgas, newPlace];
      }

      dispatch(autoGeneratedFeedbackActions.setSelectedLgas(newSelectedLgas));
   };

   const getAutoGeneratedDirection = ({
      directions_from,
      directions_from_lat,
      directions_from_lng,
      directions_to,
      directions_to_lat,
      directions_to_lng,
      mode,
   }) => {
      const url = `search/auto/location/route?directions_from=${directions_from}&directions_from_lat=${directions_from_lat}&directions_from_lng=${directions_from_lng}&directions_to=${directions_to}&directions_to_lat=${directions_to_lat}&directions_to_lng=${directions_to_lng}&mode=${mode}`;

      const config = {
         url,
         method: "get",
      };

      const successCallback = (data) => {
         dispatch(
            autoGeneratedFeedbackActions.setAutoGeneratedFeedbacData(data.data)
         );

         history.push(`/${FLAGDIRECTION}`);
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const selectMode = (mode, state) => {
      const feedbackData = autoGeneratedModePreCheck?.data;
      const data = {
         mode,
         state,
         ...feedbackData,
      };
      dispatch(
         autoGeneratedFeedbackActions.setAutogeneratedFeedbackInformation(data)
      );
      getAutoGeneratedDirection(data);
   };

   const selectBadDirection = (selectedDirection) => {
      let directions = [...badDirection];
      const index = directions.findIndex(
         (direction) => direction?.coord === selectedDirection.coord
      );

      if (index > -1) {
         directions = directions.filter(
            (direction) => direction.coord !== selectedDirection.coord
         );
      } else {
         directions = [...directions, selectedDirection];
      }

      dispatch(autoGeneratedFeedbackActions.setBadDirection(directions));
   };

   const getLgaStopsForAutoGeneratedFeedback = () => {
      const currentPage = stopByLgaMeta?.page;
      const totalPages = stopByLgaMeta?.pages;
      const handlePage = () => {
         if (currentPage === totalPages) {
            return 1;
         }
         if (currentPage >= 1 && currentPage < totalPages) {
            return currentPage + 1;
         }
         return 1;
      };
      getLgaPercity(city, handlePage());
   };

   const postSelectedLga = () => {
      const successCallback = (data) => {
         dispatch(
            autoGeneratedFeedbackActions.setAutogeneratedModePrecheck(data)
         );
         showAutogeneratedComponent("showFeedbackMode");
      };

      const body = {
         city_lga: selectedLgas,
      };

      const config = {
         url: `search/auto/location/route`,
         method: "post",
         body,
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const onSelectProblemDey = () => {
      const data = autoGeneratedFeedbackDirectionData.path[0];
      dispatch(autoGeneratedFeedbackActions.setAutoGeneratedFeedback(data));
      setProblemDay(() => true);
   };

   const onSelectEmakeSense = () => {
      setEmakeSense(() => true);
   };

   const submitFinalFeedback = () => {
      const directionResult =
         autoGeneratedFeedbackDirectionData?.path[0]?.result;

      const search_id = autoGeneratedFeedbackDirectionData?.path[0]?.search_id;
      const origin_id = directionResult[0].origin_id;
      const dest_id = directionResult[directionResult?.length - 1].dest_id;
      const feedback = feedbackInfo.typed_text ?? "GOOD DIRECTIONS";
      const direction_from = autoGeneratedFeedbackDirectionData.directions_from;
      const direction_to = autoGeneratedFeedbackDirectionData.directions_to;

      const body = {
         search_id,
         origin_id,
         dest_id,
         feedback,
         direction_from,
         direction_to,
      };

      const config = {
         url: "search/auto/location/route/feedback",
         body,
         method: "post",
      };

      const successCallback = () => {
         showThanksComponent();
         submitTask();
      };

      sendRequest(config, successCallback, directionLoadingCallback, null);
   };

   const showAutogeneratedComponent = (identifer) => {
      dispatch(
         autoGeneratedFeedbackActions.setIsAutogeneratedFeedback(identifer)
      );
   };

   const showFeedbackComponent = () => {
      showAutogeneratedComponent("showFeedbackTextArea");
      history.push(`/${PLAY_AREA}`);
   };

   const showThanksComponent = () => {
      showAutogeneratedComponent("showThanks");
      history.push(`/${PLAY_AREA}`);
   };

   return {
      toggleSelecteedAUtoGeneratedFeedback,
      isChecked,
      getLgaStopsForAutoGeneratedFeedback,
      postSelectedLga,
      selectMode,
      getAutoGeneratedDirection,
      eMakeSense,
      problemDey,
      onSelectProblemDey,
      onSelectEmakeSense,
      selectBadDirection,
      submitFinalFeedback,
      showAutogeneratedComponent,
      showFeedbackComponent,
   };
};

export default useAutoGeneratedFeedback;
